import React from 'react'
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";

const DropDownLists = ({ LinkTarget,  copy }) => {
  return (

  <Link href={LinkTarget}>
    {copy}
    <FontAwesomeIcon icon={faExternalLinkAlt}/>
  </Link>


  )
}

export default DropDownLists
import React from "react"
import { Link } from "gatsby"

import DownloadArrow from "../images/icons/icon-download-neongreen.svg"
import GreenArrowRight from "../images/green-arrow-right.svg"
import TealArrowRight from "../images/teal-arrow-right.svg"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons"

const Button = ({
  className,
  type = false,
  icon,
  iconAlt,
  gray,
  copy,
  url,
  file,
  large,
  tealArrow,
  textCenter,
  target,
  color,
}) => {
  return (
    <>
      {type === "download" && (
        <a
          href={file}
          target="_blank"
          className={`${large ? "global-button--lg" : "global-button"} ${
            gray ? "color-gray" : "color-teal"
          } row align-middle`}
        >
          {icon && <img src={icon} />}
          <div className="column text-left ml-1 md_ml-1">{copy}</div>
          <div className="column shrink">
            <img src={DownloadArrow} />
          </div>
        </a>
      )}
      {type === "external" && (
        <a
          href={url}
          target="_blank"
          className={`${large ? "global-button--lg" : "global-button"} ${
            gray ? "color-gray" : "color-teal"
          } row align-middle`}
        >
          {icon && <img src={icon} />}
          <div className="column text-left">{copy}</div>
          <div className="column shrink">
            <FontAwesomeIcon
              className="color-neon-green"
              icon={faExternalLinkAlt}
            />
          </div>
        </a>
      )}
      {!type && (
        <Link
          to={url}
          className={`${icon ? "hasIcon" : ""} ${
            large ? "global-button--lg" : "global-button"
          } ${
            gray ? "color-gray" : "color-teal"
          } row align-middle ${className}`}
        >
          {icon && <img src={icon} alt={iconAlt} />}
          <div
            className={
              textCenter
                ? "column text-center"
                : "column text-left ml-1 md_ml-1"
            }
          >
            {copy}
          </div>
          <div className="column shrink">
            <img src={tealArrow ? TealArrowRight : GreenArrowRight} />
          </div>
        </Link>
      )}
      {type === "global-link" && (
        <Link
          to={url}
          className={`global-link row justify-between align-middle ${
            color ? color : ""
          } ${icon ? "hasIcon" : ""} ${className}`}
        >
          {icon && <img src={icon} alt={iconAlt} />}
          <div
            className={textCenter ? "column text-center" : "column text-left"}
          >
            {copy}
          </div>
          <div className="column shrink">
            <img src={tealArrow ? TealArrowRight : GreenArrowRight} />
          </div>
        </Link>
      )}
    </>
  )
}

export default Button

import React from 'react'


const HowToInjectTranscript = (props) => {

  return (
    <>
    
      <p>Hi! I’m Ethan, and today I’m going to demonstrate how to inject IMCIVREE. Whether you’re injecting yourself or a person you care for, this video can help support what you’ve learned from your doctor.</p>

      <p>I hope this video is helpful, but if you have any questions about your treatment, always ask your doctor.</p>

      <p>And don’t forget: your first IMCIVREE shipment came with an injection training guide that explains how to take your medicine. It includes detailed step-by-step instructions, so make sure to keep it handy.</p>

      <p>A support program offered by Rhythm Pharmaceuticals, is also here to help point you in the right direction.</p>

      <p>When I first started taking IMCIVREE, my nurse taught me how to inject. In the beginning, I still needed some help from my mom, but now I inject myself and it’s just part of my daily morning routine.</p>

      <p>Alright, let’s get started.</p>

      <p>When you’re ready to inject, remove the vial from the box and allow it to come to room temperature. Usually this takes about 15 minutes, but if you are in a rush, you can also warm the vial by rolling it gently between the palms of your hands for about a minute. Mine is just about ready.</p>

      <p>Just don’t use the microwave or hot water, and be careful not to shake the vial.</p>

      <p>When you’re waiting for your medicine to come to room temperature, you can use that time to gather your supplies and set them out on a clean, flat work surface. You’ll need:</p>

      <ul>
        <li>your IMCIVREE vial,</li>
        <li>a 1 milliliter syringe with a 28- or 29-gauge needle,</li>
        <li>2 alcohol wipes,</li>
        <li>a gauze pad, and</li>
        <li>a sharps disposal container.</li>
      </ul>

      <br/>
      
      <p>If you’re not sure if you have everything you need, be sure to check out the injection training guide that came in the box with your first IMCIVREE shipment. You can also download a copy at IMCIVREE.com.</p>

      <p>Remember to inspect all your supplies before you start, including the expiration date on the vial label. Your vial will have both air and medicine in it, but most of the vial will be filled with air. The liquid in your vial should look clear or almost clear, but may have a slightly yellow color. There shouldn’t be any particles. Don’t use it if the expiration date has passed, the liquid is cloudy, there are particles floating in the vial, or the plastic cap on a new vial is broken or missing.</p>

      <p>Only use the syringes with needles that your doctor or pharmacist provided to you, and always use a new syringe with needle for each injection to prevent contamination. Never reuse or share your needles with other people.</p>

      <p>OK, I’ve checked my supplies and the expiration date on my vial, and everything looks good!</p>

      <p>Of course, before I start, I need to wash my hands with soap and warm water.</p>

      <p>Now I’m ready to begin. First, I’m going to remove the plastic cap and throw it away. Don’t put the plastic cap back on the vial.</p>

      <p>Then I’ll clean the top of the rubber stopper with one of the alcohol wipes and throw the wipe away. Don’t remove the rubber stopper from the vial.</p>

      <p>Remember, your doctor will tell you the right dose to use. Each vial contains enough medicine for more than one dose. The number of doses in each vial will depend on your specific dose.</p>

      <p>Next, I’m going to show you how to prepare the syringe. This may seem new at first, but you’ll get much more comfortable once you’ve done it a few times.</p>

      <p>When measuring your dose, be sure to read the markings starting from the end closest to the black rubber stopper.</p>

      <p>You’ll need to fill the syringe with air. Keeping the protective needle cap on, pull back the plunger and fill the syringe with air equal to the amount of medicine you need. Take your time, and make sure you are pulling back to the same amount as your prescribed dose.</p>

      <p>Once that’s done, uncap the needle by pulling the protective needle cap straight off and away from your body. Throw the cap in the trash.</p>

      <p>Next, you’ll need to insert the needle into the vial. Place the vial on the clean, flat surface that you set up with your injection supplies earlier. Then, place the syringe directly over the vial and insert the needle straight down into the center of the vial stopper.</p>

      <p>Finally, push the plunger down to push all the air from the syringe into the vial.</p>

      <p>Next, I’m going to fill the syringe with IMCIVREE. To do this, you need to slowly turn the vial upside down, keeping the needle inside the vial. It’s important to make sure that the tip of the needle is always fully covered by the medicine.</p>

      <p>Then, slowly pull back the plunger to fill the syringe with the amount of IMCIVREE needed for your prescribed dose. Be careful not to pull the plunger out of the syringe.</p> 

      <p>If your vial doesn’t have enough medicine for your dose, discard it, and open a new vial. Don’t use medicine from two different vials to make one dose.</p>

      <p>Before we keep going, I’ll need to check for air bubbles. Keeping the needle in the vial, take a look at the syringe and see if you notice any large air bubbles.</p>

      <p>If you see any, just gently tap the side of the syringe with your fingers – like this – until the air bubbles rise to the top. Then, move the tip of the needle above the medicine and slowly push the plunger up to push the large bubbles back into the vial. After the large air bubbles are gone, pull back on the plunger again to fill the syringe with your prescribed dose of medicine. If you pull it back more slowly this time, it can help reduce the chance of bubbles.</p>

      <p>OK, that did it!</p>

      <p>Now it’s time to take the syringe out of the vial. To do this, I’m going to put the vial back down on the clean, flat work surface again, holding the vial in one hand and the barrel of the syringe between the fingertips of the other. Then, I can just pull the needle straight out of the vial and place the syringe gently on its side.</p>

      <p>Be careful not to let the needle touch the work surface, and do not put the needle cap back on.</p>

      <p>Now it’s time to prepare your injection site. Today I’m going to inject IMCIVREE into my belly. You can decide the exact spot, but it should be at least 2 inches from your belly button.</p>

      <p>You want to avoid your belly button, ribs, and hip bones, as well as any scars or moles. And, of course, never inject into an area that is red, swollen, or irritated.</p>

      <p>You can also inject into the front of your thigh, or into the back of your upper arm.</p>

      <p>Although I’ve chosen my stomach today, it’s important to use a different spot each day – at least 1 inch away from the one you used previously.</p>

      <p>Before you inject your medicine, you’ll need to clean the injection site. Gently rub the area with your second alcohol wipe using a circular motion and wait about 10 seconds for the skin to dry. Don’t touch, fan, or blow on it.</p>

      <p>Now I’m ready to inject IMCIVREE. I’m going to grab and pinch about 2 inches of skin at the injection site between my thumb and pointer finger. This helps make sure the medicine will be injected into the tissue just below my skin, as you never want to inject IMCIVREE into a vein or muscle.</p>

      <p>With my other hand, I’m going to hold the syringe with my thumb and pointer finger.</p>

      <p>Now you’re going to hold the middle of the syringe at a 90-degree angle to your body and push the needle straight into the injection site, making sure the needle is all the way into the skin.</p>
      <p>It’s important to be sure you do not hold or push on the plunger while inserting the needle.</p>

      <p>Then, only when the needle is in your skin, you will slowly push the plunger down to inject the medicine. Count to 5 to be sure all the medicine is injected.</p>

      <p>Once this is done, let go of your pinched skin and pull out the needle, throwing it away in a sharps container. Do not recap the needle before throwing it away.</p>

      <p>Finally, you can use your gauze pad to gently apply pressure to the injection site.</p>

      <p>OK, that’s it – the injection is complete! Great job.</p>

      <p>Even though I can inject myself now that I’ve been trained by my nurse, my mom needed to help me when I was younger. She asked me to pass along these tips to people who are helping somebody else inject IMCIVREE.</p>

      <p>If you’re a caregiver and need to inject a child, here are a few suggestions to help it feel less scary. Have the child squeeze something soft, like their favorite stuffed animal. You can also have the child concentrate on something else, like breathing slowly. Finally, it can help to offer distractions by having the child sing a song, hum, count, or recite their favorite colors or animals.</p>

      <p>Alright. This time tomorrow, I’ll inject again. To help me remember which spot I used, I make a note in my phone. Some people like to write it down on a calendar, and that works too. I also note the date, time, and vial lot information in case I need it for my doctor at some point in the future.</p>

      <p>OK, the last step is to store your medicine for tomorrow’s dose. Remember, each vial contains enough medicine for more than one dose.</p>

      <p>Once you’ve opened a vial, don’t put the plastic cap back on. Place the vial back in the original carton to protect it from light and store it in the refrigerator.</p>

      <p>If needed, you can remove your vials from the refrigerator and store them at room temperature for up to 30 days (but never past the expiration date). You can also briefly store them at up to 86 degrees. If you do have any vials at room temperature, it’s okay to put them back in the refrigerator.</p>

      <p>It’s a good idea to write the date you first opened the vial on the carton so you can be sure it has not been more than 30 days. Once you’ve opened the vial, you must throw it away after 30 days, even if some medicine is left in the vial.</p>

      <p>You should also throw away any vials that are past their expiration date, any that have been stored at temperatures above 86 degrees Fahrenheit, or any that have been out of the refrigerator for more than 30 days, even if it’s before their expiration date.</p>

      <p>A few final tips. Always throw away used syringes with needles immediately after use in a puncture-resistant container, such as your sharps container, but other supplies like your alcohol wipes, used gauze, and vials can go right in the trash. Just be sure to never put used needles in the trash, or liquids in the sharps container.</p>

      <p>Once your sharps container is full, you’ll need to throw it away. The FDA’s website can be helpful, as the rules for where and how to do this can vary based on where you live.</p>

      <p>I know that a new routine can sometimes take a little bit of time to get used to, but keep it up and refer back to this video anytime you need a refresher.</p>

      <p>Remember, if you have any questions, always talk to your doctor.</p>

      <p><b>Text on screen:</b><br/>Please see full Prescribing Information and Patient Information, including how to inject IMCIVREE, at:</p>

      <p>IMCIVREE.com<br/> 1-855-206-0815</p>

      {/* ISI */}

      <p><b>What is IMCIVREE?</b><br/>
      IMCIVREE is a prescription medicine used in adults and children 6 years of age and older with obesity due to Bardet-Biedl syndrome (BBS) to help them lose weight and keep the weight off.</p>

      <p>IMCIVREE is not for use in people with the following conditions because it may not work:</p>

      <ul>
        <li>Other types of obesity not related to BBS or other FDA-approved uses of IMCIVREE, including obesity associated with other genetic conditions and general obesity</li>
      </ul>
      <br/>

      <p>It is not known if IMCIVREE is safe and effective in children under 6 years of age.</p>

      <p><b>Do not use IMCIVREE if you have had a serious allergic reaction to it or any of its ingredients. Serious allergic reactions, including anaphylaxis, can happen.</b></p>

      <p><b>Important Safety Information</b></p>

      <p><b>Before you use IMCIVREE, tell your healthcare provider about all your medical conditions, including if you:</b></p>

      <ul>
        <li>Have or have had areas of darkened skin, including skin discoloration (hyperpigmentation)</li>
        <li>Have or have had depression, or suicidal thoughts or behavior</li>
        <li>Have kidney problems</li>
        <li>Are pregnant or planning to become pregnant. Losing weight while pregnant may harm your unborn baby. Your healthcare provider may stop your treatment with IMCIVREE if you become pregnant. Tell your healthcare provider if you become pregnant or think you might be pregnant during treatment with IMCIVREE</li>
        <li>Are breastfeeding or plan to breastfeed. It is not known if IMCIVREE passes into your breast milk. You should not breastfeed during treatment with IMCIVREE</li>
      </ul>
      <br/>

      <p><b>Tell your healthcare provider about all the medicines you take,</b> including prescription and over-the-counter medicines, vitamins, and herbal supplements.</p>

      <p><b>See the detailed Instructions for Use</b> that come with your IMCIVREE to learn how to prepare and inject IMCIVREE, and how to properly throw away (dispose of) used syringes and needles.</p>

      <p><b>What are the possible side effects of IMCIVREE?</b><br/>
      <b>IMCIVREE may cause serious side effects, including:</b></p>

      <ul>
        <li><b>Male and female sexual function problems.</b> IMCIVREE can cause an erection that happens without any sexual activity in males (spontaneous penile erection) and unwanted sexual reactions (changes in sexual arousal that happen without any sexual activity) in females. If you have an erection lasting longer than 4 hours, get emergency medical help right away</li>
        <li><b>Depression and suicidal thoughts or actions.</b> You or a caregiver should call your healthcare provider right away if you have any new or worsening symptoms of depression, suicidal thoughts or behaviors, or any unusual changes in mood or behavior</li>
        <li><b>Serious allergic reactions.</b> Stop taking IMCIVREE and get medical help right away if you have any symptoms of a serious allergic reaction including: swelling of your face, lips, tongue, or throat; problems breathing or swallowing; severe rash or itching; fainting or feeling dizzy; rapid heartbeat</li>
        <li><b>Increased skin pigmentation and darkening of skin lesions (moles or nevi) you already have.</b> These changes happen because of how IMCIVREE works in the body and will go away when you stop using IMCIVREE. You should have a full body skin exam before starting and during treatment with IMCIVREE to check for skin changes</li>
        <li><b>Benzyl alcohol toxicity.</b> Benzyl alcohol is a preservative in IMCIVREE. Benzyl alcohol can cause serious side effects, including death, in premature and low-birth weight infants who have received medicines that contain benzyl alcohol. IMCIVREE should not be used in premature and low-birth weight infants</li>
      </ul>
      <br/>

      <p><b>The most common side effects of IMCIVREE include</b> darkening of the skin, injection site reactions, nausea, headache, diarrhea, stomach pain, vomiting, depression, and an erection that happens without any sexual activity in males.</p>

      <p>These are not all the possible side effects of IMCIVREE. Call your doctor for medical advice about side effects. You may report side effects to FDA at 1-800-FDA-1088 or to Rhythm Pharmaceuticals at 1-833-789-6337.</p>

      <p><b>Please see the full Prescribing Information at IMCIVREE.com.</b></p>

      <p><b>Text on screen:</b><br/>&copy; 2024, Rhythm Pharmaceuticals, Inc. All rights reserved.<br/>
      Rhythm, IMCIVREE, and their logos are trademarks of Rhythm Pharmaceuticals, Inc.<br/>US-SET-2000028 03/2024</p>

    </>
  )
}

export default HowToInjectTranscript

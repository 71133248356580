import React from "react"

import Layout from "../../../components/hcp-ppl-layout"
import Seo from "../../../components/seo"
import Button from "../../../components/button"
import Callout from "../../../components/callout"
import Quote from "../../../components/quote"

import HcpPplOvereatingDisordersChart from "../../../components/hcp-ppl-overeating-disorders-chart"

const IdentifyingHyperphagia = () => {
  return (
    <Layout>
      <Seo
        title="Identifying Hyperphagia | IMCIVREE® (setmelanotide) injection | For HCPs"
        description="Learn how to identify hyperphagia, a common feature in patients with POMC, PCSK1, or LEPR deficiency. Please see full Prescribing Information and Important Safety Information."
        ogTitle="Identifying Hyperphagia | IMCIVREE® (setmelanotide) injection"
        ogDesc="Learn about a common feature in patients. Please see full Prescribing Information for Important Safety Information."
      />

      <div id="hcp-ppl-identifying-hyperphagia" className="content-block">
        <h1 className="h1 h1--sm-mobile lg_mt-1">
          Identifying hyperphagia in your patients
        </h1>
        <h2 className="h2">
          Hyperphagia is a common feature in people living with POMC, PCSK1, or LEPR
          deficiency<sup>1</sup>
        </h2>
        <p>
          Hyperphagia and obesity due to POMC, PCSK1, or LEPR deficiency are
          caused by impairment in the MC4R pathway. The MC4R pathway is a key
          signaling pathway that regulates hunger, satiety, and energy
          expenditure. If left untreated or unmanaged, hyperphagia and obesity
          can intensify physical and mental challenges for people with POMC,
          PCSK1, or LEPR deficiency and caregivers.<sup>1,2</sup>
        </p>

        <div className="row mt-2 lg_mt-3">
          <div className="column small-12">
            <h2 className="h2">
              Hyperphagia is a pathological, insatiable hunger that is
              differentiated from other overeating behaviors and disorders by
              its severity and persistence<sup>3</sup>
            </h2>
          </div>
        </div>

        <div className="large">
          <HcpPplOvereatingDisordersChart />
        </div>
      </div>

      <Callout right className="light-teal right text-right">
        <p>
          According to 2023 AAP and 2023 OMA guidelines, managing hyperphagia
          can be challenging and may require the use of pharmacotherapy<sup>7,8</sup>
        </p>
      </Callout>

      <div className="content-block">
        
        {/* <div className="row">
          <div className="column small-12">
            <h2 className="h1 large-10 small-12">
              Real experiences with hyperphagia
            </h2>
            <p className="h2">
              Learn about its daily impact and how physicians and families are
              managing it together
            </p>
          </div>
        </div>

        <HyperphagiaVideo/>
        <VideoTranscript>
          <HyperphagiaVideoTranscript />
        </VideoTranscript> */}

        <div className="row mt-2 lg_mt-2">
          <div className="columns small-12 medium-offset-5 medium-7 mb-3">
            <Quote
              copy={
                <>
                  They took me away when I was 4 years old, claiming that my
                  mother was feeding me to make me fat, and they took me to some
                  sort of psychosomatic facility for over a year or a year and a
                  half, where I was supposed to lose weight. That did not work
                  either.
                </>
              }
              signoff={<>— Person who is living with POMC deficiency</>}
            ></Quote>
          </div>
          <div className="columns small-12 medium-7 lg_mt-3 mb-3">
            <Quote
              copy={
                <>
                  It sometimes felt as if I were an animal that was super hungry
                  and could only think about food, and when I wanted to eat
                  something, I searched until I found it.
                </>
              }
              signoff={<>— Person who is living with POMC deficiency</>}
            ></Quote>
          </div>
          <div className="columns small-12 medium-offset-5 medium-7 lg_mt-3">
            <Quote
              copy={
                <>
                  When I would start eating something, I’d eat the whole thing.
                  No matter what sort of package it was, or whatever, I’d always
                  eat the whole thing. I also ate it at an enormous tempo.
                  It was really terrible.
                </>
              }
              signoff={<>— Person who is living with LEPR deficiency</>}
            ></Quote>
          </div>
        </div>
        <div className="text-center mt-3 md_mt-2">
          <Button
            textCenter
            copy="See how IMCIVREE works"
            url="/hcp/ppl/moa/"
          />
        </div>
        <div className="row">
          <div className="references mt-2 lg_mt-3">
            AAP=American Academy of Pediatrics; LEPR=leptin receptor;
            MC4R=melanocortin-4 receptor; OMA=Obesity Medicine Association;
            PCSK1=proprotein convertase subtilisin/kexin type 1;
            POMC=proopiomelanocortin.
          </div>
        </div>
        <div className="row">
          <div className="references mt-1 lg_mt-1">
            <strong>References: 1.</strong> Eneli I et al. <em>Appl Clin Genet</em>. 2019;12:87-93.
            <strong> 2.</strong> Styne DM et al. <em>J Clin Endocrinol Metab</em>. 2017;102(3):709-757.
            <strong> 3.</strong> Heymsfield SB et al. <em>Obesity (Silver Spring)</em>. 2014;22(suppl 1):S1-S17. doi:10.1002/oby.20646.
            <strong> 4.</strong> Haqq AM et al. <em>Child Obes</em>. 2021;17(4):229-240.
            <strong> 5.</strong> Espel-Huynh HM et al. <em>Obes Sci Pract</em>. 2018;4(3):238-249. doi:10.1002/osp4.161.
            <strong> 6.</strong> Hayes JF et al. <em>Curr Obes Rep</em>. 2018;7(3):235-246. Hampl SE et al. Pediatrics. 2023;151(2):e202206064. doi:10.1542/peds.2022-060640.
            <strong> 7.</strong> Tondt J et al. <em>Obesity Algorithm<sup style={{fontStyle: "normal" }}>®</sup> 2023</em>. Obesity Medicine Association; 2023. Accessed August 14, 2023. https://obesitymedicine.org/obesity-algorithm.
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IdentifyingHyperphagia

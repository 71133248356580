import * as React from "react"
import Helmet from "react-helmet"

import Layout from "../../components/bbs-layout"
import Seo from "../../components/seo"
import Button from "../../components/button"
import DropDownLists from "../../components/dropdown-lists"

import ResourcesHero from "../../images/patient-bbs-imcivree-programs-hero.png"
import ResourcesHeroMobile from "../../images/patient-bbs-imcivree-programs-hero-mobile.png"
import MentorProgram from "../../images/bbs-mentor-program.png"

const Programs = () => {
  return (
    <Layout>
      <Helmet bodyAttributes={{ class: "patient-bbs bbs-programs" }} />
      <Seo
        title="IMCIVREE Programs | IMCIVREE® (setmelanotide) injection"
        description="Explore the programs that we offer to support you along your treatment journey. Please see full Prescribing Information and Important Safety Information."
        ogTitle="IMCIVREE Programs | IMCIVREE® (setmelanotide) injection"
        ogDesc="Explore the programs that we offer to support you along your treatment journey. Please see full Prescribing Information and Important Safety Information."
      />

      <div>
        <img src={ResourcesHero} className="hide-for-small show-for-medium" alt="Enroll in one of our IMCIVREE programs today"/>
        <img src={ResourcesHeroMobile} className="show-for-small hide-for-medium w-100" style={{marginTop:'-10px'}} alt="Enroll in one of our IMCIVREE programs today"/>
      </div>

      <div className="content-block">
        <div className="row">
          <div className="column small-12">
            <h1 className="h1">Explore the IMCIVREE programs we offer to support you along your treatment journey</h1>
          </div>
          <div className="column small-12 medium-8 medium-offset-2">

            <div className="bbs-program-card mt-2 md_mt-2">
              <div className="row align-middle">
                <div className="column small-12 medium-9">
                  <strong>Attend one of our upcoming webinars!</strong>
                </div>
                <div className="column small-12 medium-3 text-center mt-1">
                  <img src={MentorProgram} alt="Upcoming webinar icon"/>
                </div>
              </div>
              <div className="row">
                <div className="column small-12 mt-1 md_mt-1">
                  <p>Don’t miss the chance to learn more about IMCIVREE from patient ambassadors, healthcare providers, and other featured speakers.</p>
                  <p>Sign up for any of the dates and times listed below to join us.</p> 
                  <p>We would love to see you there!</p>
                </div>
              </div>

              <div className="row">
                <div className="column small-12 large-4 mb-1 mr-0" style={{ marginRight: "20px" }}>
                  <div className="program-dropdowns dropdown">
                    <Button textCenter={true} large copy="Register for a webinar" />
                    <div className="dropdown-content">
                    <ul>
                        <li><DropDownLists LinkTarget={'https://bit.ly/4a2rjkJ'} copy={<>03/19/2024, 8:00 <span className="small-cap">PM, ET</span></>} target="_blank"  /></li>
                      </ul> 
                    </div>
                  </div>
                </div>

                <div className="column small-12 large-5">
                  <div className="program-dropdowns dropdown">
                    <Button textCenter={true} large copy="Registro en seminario web" />
                    <div className="dropdown-content">
                      <ul>
                        <li><DropDownLists LinkTarget={'https://bit.ly/4c6VtVJ'} copy={<>03/25/2023, 7:00 <span className="small-cap">PM</span></>} target="_blank"  /></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>

    <div className="text-center">
      <Button copy="See available helpful resources" url="/bbs/resources/" />
    </div>

    </Layout>
  )
}

export default Programs

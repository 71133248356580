import * as React from "react"
import { Link } from "gatsby"

import Layout from "../../components/bbs-layout"
import Seo from "../../components/seo"

const Sitemap = () => (
  <Layout noNav>
    <Seo
      title="Sitemap | IMCIVREE® (setmelanotide) injection"
      description="Explore the IMCIVREE® (setmelanotide) patient sitemap."
      ogTitle="Sitemap | IMCIVREE® (setmelanotide) injection"
      ogDesc="Explore the IMCIVREE® (setmelanotide) patient sitemap."
    />
    <div id="patient-bbs-sitemap" className="sitemap content-block">
      <h1 className="h1">Sitemap</h1>
      <ul style={{ marginLeft: '0.3rem' }}>
        <li><Link to="/bbs/">Home</Link></li>
        <li><Link to="/bbs/overview/">BBS Overview</Link></li>
        <li className="lg_mt-1 mt-1"><span>About IMCIVREE</span>
          <ul>
            <li><Link to="/bbs/how-imcivree-works/">How does IMCIVREE work?</Link></li>
            <li><Link to="/bbs/studied/">How was IMCIVREE studied?</Link></li>
            <li><Link to="/bbs/effect-of-imcivree/">Weight &amp; hunger reduction</Link></li>
            <li><Link to="/bbs/quality-of-life-results/">IMCIVREE and quality of life</Link></li>
            <li><Link to="/bbs/side-effects/">Side effects</Link></li>
          </ul>
        </li>
        <li className="lg_mt-1 mt-1"><span>Starting IMCIVREE</span>
          <ul>
            <li><Link to="/bbs/how-imcivree-given/">How is IMCIVREE given?</Link></li>
            <li><Link to="/bbs/getting-started/">Getting started on IMCIVREE</Link></li>
            <li><Link to="/bbs/understanding-your-dose/">Understanding your dose</Link></li>
          </ul>
        </li>
        <li className="lg_mt-1 mt-1"><span>Support &amp; Resources</span>
          <ul>
            <li><Link to="/bbs/support-resources/">Rhythm InTune</Link></li>
            <li><Link to="/ppl/patient-access/">Patient access</Link></li>
            <li><Link to="/bbs/programs/">IMCIVREE programs</Link></li>
            <li><Link to="/bbs/resources/">Helpful resources</Link></li>
            <li><Link to="/bbs/faq/">Frequently asked questions</Link></li>
          </ul>
        </li>
      </ul>
    </div>
  </Layout>
)

export default Sitemap

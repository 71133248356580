import React from "react"

import Layout from "../../../components/hcp-bbs-layout"
import Seo from "../../../components/seo"
import Button from "../../../components/button"
import Callout from "../../../components/callout"

import OvereatingDisordersChart from "../../../components/overeating-disorders-chart"
import PatientCaregiverQuote from "../../../components/patient-caregiver-quote"

import ImgAnnSabrina from "../../../images/ann-and-sabrina.jpg"
import ImgRachelFamily from "../../../images/rachel-and-family-2.png"
import ImgSabrina from "../../../images/sabrina-2.png"

import HyperphagiaVideo from "../../../components/hyperphagia-video"
import VideoTranscript from "../../../components/video-transcript"
import HyperphagiaVideoTranscript from "../../../components/template-partials/hcp/bbs/hyperphagia-bbs-video-transcript"

const Hyperphagia = () => {

  return (
    <Layout>
      <Seo
        title="Hyperphagia in BBS | IMCIVREE® (setmelanotide) injection | For HCPs"
        description="HCPs: Learn how to identify hyperphagia in your patients. Please see full Prescribing Information for Important Safety Information."
        ogTitle="Hyperphagia in BBS | IMCIVREE® (setmelanotide) injection"
        ogDesc="Learn about a common feature in patients. Please see full Prescribing Information for Important Safety Information."
      />
      <div className="content-block" style={{ paddingTop: "4rem" }}>

        <div className="row">
          <div className="column">
            <h1 className="h1">Identifying hyperphagia in your patients</h1>
            <h2 className="h2">
              Hyperphagia is a common feature in people living with BBS<sup>1</sup>
            </h2>
            <p style={{ marginTop: `-1.5rem` }}>
              Hyperphagia and obesity due to BBS are caused by impairment in the
              MC4R pathway. The MC4R pathway is a key signaling pathway that
              regulates hunger, satiety, and energy expenditure. If left
              untreated or unmanaged, hyperphagia and obesity can intensify
              physical and mental challenges for patients and caregivers.<sup>1-4</sup>
            </p>
          </div>
        </div>

        <div>&nbsp;</div>
        <h2 className="h2">
          Hyperphagia is a pathological, insatiable hunger that is
          differentiated from other overeating behaviors and disorders by its
          severity and persistence<sup>4</sup>
        </h2>

        <div className="large">
          <OvereatingDisordersChart />
        </div>
      </div>


      <Callout
        right
        className="light-teal right text-right"
      >
        <p>
          According to 2023 AAP and 2023 OMA guidelines, managing hyperphagia can be challenging and may require the use of pharmacotherapy<sup>2,8</sup>
        </p>
      </Callout>
          
      <div className="content-block">
        <div className="row">
          <div className="column">
            <h2 className="h1">Real experiences with hyperphagia</h2>
            <h3 className="h2">Learn about its daily impact on families and how physicians differentiate hyperphagia from other overeating behaviors to diagnose and manage it</h3>
          </div>
        </div>

        <HyperphagiaVideo/>
        <VideoTranscript>
          <HyperphagiaVideoTranscript />
        </VideoTranscript>

      </div>

      <div className="content-block" style={{ paddingTop: '0' }}>
        <div id="patient-caregiver-quotes">
          <div className="flex-wrapper">
            <PatientCaregiverQuote
              imgSrc={ImgAnnSabrina}
              alt="Caregiver of a person living with BBS"
              copy="After her first birthday, we noticed a rapid weight gain, and she threw tantrums all the time and was very difficult to manage. Looking back, I see this was most likely due to how hungry she felt."
              signoff="&#8212; Ann, caregiver of a person living with BBS"
            />
            <PatientCaregiverQuote
              order="reverse"
              imgSrc={ImgRachelFamily}
              alt="Caregiver of a child living with BBS"
              copy={
                <>
                  While Wyatt and Carson have the same variants of the{' '}
                  <em>BBS10</em> gene, only Wyatt has hyperphagia and obesity. 
                  I couldn't take the kids out by myself because Wyatt needed 
                  constant supervision to make sure he wasn't stealing food. I
                  have to lock our fridge, trash, and pantry.
                </>
              }
              signoff="&#8212; Rachel, caregiver of a child living with BBS"
            />

            <PatientCaregiverQuote
              imgSrc={ImgSabrina}
              alt="Person living with BBS"
              copy="Since starting IMCIVREE I am not always thinking about eating. Now, I can actually focus on my friends and family, and on doing activities besides eating. I feel like I'm able to do the fun things I've always wanted to do without my hunger interrupting."
              signoff="&#8212; Sabrina, who is living with BBS"
            />
          </div>
        </div>
      </div>

      <div className="content-block" style={{ padding: `0 2rem` }}>
        <div className="text-center mt-2" style={{ marginBottom: `60px` }}>
          <Button copy="See how IMCIVREE works" url="/hcp/bbs/moa/" />
        </div>

        <p className="footnote">
          AAP=American Academy of Pediatrics; MC4R=melanocortin-4 receptor;
          OMA=Obesity Medicine Association.
        </p>

        <div className="references footnote mt-1 md_mt-1">
          <strong>References: 1.</strong> Eneli I et al. <em>Appl Clin Genet.</em> 2019;12:87-93. <strong>2.</strong> Hampl SE et al. <em>Pediatrics.</em> 2023;151(2):e202206064. doi:10.1542/peds.2022-060640. <strong>3.</strong> Cuda SE et al. <em>Obesity Pillars.</em> 2022;1:100010. doi:10.1016/j.obpill.2022.100010. <strong>4.</strong> Heymsfield SB et al. <em>Obesity (Silver Spring).</em> 2014;22(suppl 1):S1-S17. doi:10.1002/oby.20646. <strong>5.</strong> Haqq AM et al. <em>Child Obes.</em> 2021;17(4):229-240. <strong>6.</strong> Espel-Huynh HM et al. <em>Obes Sci Pract.</em> 2018;4(3):238-249. doi:10.1002/osp4.161. <strong>7.</strong> Hayes JF et al. <em>Curr Obes Rep.</em> 2018;7(3):235-246. <strong>8.</strong> Tondt J et al. <em>Obesity Algorithm</em><sup>&reg;</sup> <em>2023</em>. Obesity Medicine Association; 2023. Accessed August 11, 2023. https://obesitymedicine.org/obesity-algorithm.
        </div>
      </div>
    </Layout>
  )
}

export default Hyperphagia

import * as React from "react"

import Layout from "../../../components/hcp-bbs-layout"
import Seo from "../../../components/seo"
import Button from "../../../components/button"
import Modal from "../../../components/modal"

import "../../../components/scss-pages/hcp/bbs/_dosing.scss"

import ImageDoseChart from "../../../images/dosing-chart-bbs.png"
import ImageDoseBox from "../../../images/dosing-box.png"

const Dosing = () => (
  <Layout>
    <Seo
      title="Dosing and Administration | IMCIVREE® (setmelanotide) injection | For HCPs"
      description="IMCIVREE® (setmelanotide) is a once-daily, subcutaneous injection that can be administered at home. See dosing information. Please see full Prescribing Information for Important Safety Information."
      ogTitle="Dosing and Administration | IMCIVREE® (setmelanotide) injection"
      ogDesc="IMCIVREE® (setmelanotide) is a once-daily, subcutaneous injection that can be administered at home. See dosing information. Please see full Prescribing Information for Important Safety Information."
    />
    <div id="hcp-bbs-dosing" className="content-block">
      <h1 className="h1">
        Once-daily, subcutaneous injection that can be administered at home
        <sup>1</sup>
      </h1>
      <h2 className="h2">
        Titrate adult and pediatric patients to the target dose to optimize
        tolerability and response
      </h2>
      <div className="row">
        <div className="columns small-12 large-8">
          <img
            src={ImageDoseChart}
            alt="IMICIVREE recommended dosing chart for BBS"
            style={{ width: "100%" }}
          />
        </div>
        <div className="columns small-12 large-4 text-center">
          <img
            src={ImageDoseBox}
            alt="IMCIVREE multiple-dose vial"
            className="width-small-50"
          />
          <h5 className="h5 md_ml-2">
            10-mg/1-mL
            <br />
            multiple-dose vial
          </h5>
        </div>
      </div>
      <div>
        <ul className="neon-green-bullets">
          <li>
            IMCIVREE should be administered once daily, at the beginning of the
            day, without regard to meals
            <ul>
              <li className="is-dash">
                There is no food requirement for administration
              </li>
            </ul>
          </li>
          <li>
            No dose adjustments are needed for patients with mild to moderate
            renal impairment
          </li>
        </ul>
        <div className="row">
          <div className="columns small-12 medium-6 large-3 md_mr-1 md_mt-2 mt-1">
            <Modal id="recommended-monitoring-hcp-bbs" />
          </div>
          <div className="columns small-12 medium-6 large-4 md_mt-2  mt-1">
            <Modal id="dosing-for-severe-renal-impairment-hcp-bbs" />
          </div>
        </div>
      </div>
    </div>

    <div className="content-block mt-1 md_pt-0">
      <div className="text-center">
        <Button
          copy="Start your patient on IMCIVREE"
          url="/hcp/bbs/prescribing-imcivree/"
        />
      </div>
      <div>&nbsp;</div>
      <div>&nbsp;</div>
      <div className="references">
        <strong>Reference: 1.</strong> IMCIVREE [prescribing information].
        Boston, MA. Rhythm Pharmaceuticals, Inc.
      </div>
    </div>
  </Layout>
)

export default Dosing

import React from "react"
import Img from "gatsby-image"
import { graphql, useStaticQuery } from 'gatsby'
import PropTypes from "prop-types"

const GrowthChart = ({ alt }) => {
  const { mobileImage, desktopImage } = useStaticQuery(
    graphql`
      query {
        mobileImage: file(relativePath: { eq: "growth-chart-mobile.png" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        desktopImage: file(relativePath: { eq: "growth-chart-desktop.png" }) {
          childImageSharp {
            fluid(maxWidth: 715, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

 // Set ImageData.
  const sources = [
    mobileImage.childImageSharp.fluid,
    {
      ...desktopImage.childImageSharp.fluid,
      media: `(min-width: 641px)`,
    },
  ]

  return (
    <>
      <Img height="auto" fluid={sources} alt={alt} />
    </>
  )
}

export default GrowthChart


GrowthChart.defaultProps = {
  alt: 'This is where you have alt text @todo add me',
};

GrowthChart.propTypes = {
  alt: PropTypes.string.isRequired
}

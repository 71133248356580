import * as React from "react"

import Layout from "../../../components/hcp-bbs-layout"
import Seo from "../../../components/seo"

import DiagnosisVideo from "../../../components/diagnosis-video"
import VideoTranscript from "../../../components/video-transcript"
import DiagnosisVideoTranscript from "../../../components/template-partials/hcp/bbs/diagnosis-video-transcript"


const DiagnosingBbsInYourPracticeHcpBbs = () => (
  <Layout noNav>

    <Seo title="" />

    <div className="content-block">

      <div className="row og">
        <div className="columns og">
          <h1 className="h1">Diagnosing BBS in your practice</h1>
          <h2 className="h2">Recognize the various clinical manifestations of BBS to accelerate a diagnosis.</h2>
        </div>
      </div>

      <DiagnosisVideo />
        <VideoTranscript>
          <DiagnosisVideoTranscript />
        </VideoTranscript>

    </div>

  </Layout>
)

export default DiagnosingBbsInYourPracticeHcpBbs
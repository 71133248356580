import * as React from "react"

import Layout from "../../components/ppl-layout"
import Seo from "../../components/seo"
import Button from "../../components/button"
import { Link } from "gatsby"

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import SideEffect1Img from "../../images/side-effect-ppl-1.png"
import SideEffect2Img from "../../images/side-effect-ppl-2.png"
import SideEffect3Img from "../../images/side-effect-ppl-3.png"
import SideEffect4Img from "../../images/side-effect-ppl-4.png"

import SideEffect1ImgMobile from "../../images/side-effect-ppl-1-mobile.png"
import SideEffect2ImgMobile from "../../images/side-effect-ppl-2-mobile.png"
import SideEffect3ImgMobile from "../../images/side-effect-ppl-3-mobile.png"
import SideEffect4ImgMobile from "../../images/side-effect-ppl-4-mobile.png"

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1
};

const ClinicalStudyResultsPage = () => (
  <Layout>
    <Seo
      title="Side Effects | IMCIVREE® (setmelanotide) injection"
      description="Review possible side effects of IMCIVREE® (setmelanotide). Please see full Prescribing Information for Important Safety Information."
      ogTitle="Side Effects | IMCIVREE® (setmelanotide) injection"
      ogDesc="Review possible side effects of IMCIVREE® (setmelanotide). Please see full Prescribing Information for Important Safety Information."
    />
    <div id="side-effects" className="content-block md_pb-0">
      <h1 className="h1">What are the possible side effects of IMCIVREE?</h1>
      <h2 className="h2">IMCIVREE was well studied and most side effects were generally mild and improved over time</h2>
      <p>The safety of IMCIVREE has been evaluated in &gt;700 patients over ~10 years of clinical trials.</p>
        <p>The most common side effects occurring in &gt;20% of people with obesity and a clinical diagnosis of POMC, PCSK1, or LEPR deficiency included:</p>
      <div className="row text-center">
        <div className="column small-12 medium-6">
          <ul className="neon-green-bullets text-left">
              <li>Injection site reactions (96%)</li>
            <li>Darkening of the skin (hyperpigmentation) (78%)</li>
            <li>Nausea (56%)</li>
            <li>Headache (41%)</li>
            <li>Diarrhea (37%) </li>
            <li>Stomach pain (33%)</li>
          </ul>
        </div>
        <div className="column small-12 medium-6">
          <ul className="neon-green-bullets text-left">
            <li>Back pain (33%)</li>
            <li>Fatigue (30%)</li>
            <li>Vomiting (30%)</li>
            <li>Depression (26%)</li>
            <li>Upper respiratory tract infection (26%)</li>
              <li>Erection that happens without sexual activity in males (23%)</li>
          </ul>
        </div>
      </div>
      <p className="lg_mt-2 mt-2">Most nausea and vomiting events were mild, and none were severe. Nausea and vomiting primarily occurred within the first month of treatment and then sharply
declined. These symptoms typically lasted no more than a few days.</p>
      <p>Contact your doctor if you are experiencing side effects.</p>
      <h2 className="h2 lg_mt-2 mt-2">A general darkening of the skin was common but rarely led to a stop in treatment</h2>

      <div className="row the-carousel">
        <div className="column" >
          <Slider {...settings}>
              <div className="carousel-items">
                <p className="text-center fw-bold mb-0 md_mb-2">Examples of hyperpigmentation</p>
                <img src={SideEffect1Img} className="hide-for-small-only" alt="Examples of hyperpigmentation (before and during
treatment with IMCIVREE)" />
                <img src={SideEffect1ImgMobile} className="show-for-small-only" alt="Examples of hyperpigmentation (before and during
treatment with IMCIVREE)" />
              </div>
              <div className="carousel-items">
                <p className="text-center fw-bold mb-0 md_mb-2">Examples of hyperpigmentation</p>
                <img src={SideEffect2Img} className="hide-for-small-only" alt="Examples of hyperpigmentation (before and during
treatment with IMCIVREE)" />
                <img src={SideEffect2ImgMobile} className="show-for-small-only" alt="Examples of hyperpigmentation (before and during
treatment with IMCIVREE)" />
              </div>
              <div className="carousel-items">
                <p className="text-center fw-bold mb-0 md_mb-2">Examples of hyperpigmentation</p>
                <img src={SideEffect3Img} className="hide-for-small-only" alt="Examples of hyperpigmentation (before and during
treatment with IMCIVREE)" />
                <img src={SideEffect3ImgMobile} className="show-for-small-only" alt="Examples of hyperpigmentation (before and during
treatment with IMCIVREE)" />
              </div>
              <div className="carousel-items">
                <p className="text-center fw-bold mb-0 md_mb-2">Examples of hyperpigmentation</p>
                <img src={SideEffect4Img} className="hide-for-small-only" alt="Examples of hyperpigmentation (before and during
treatment with IMCIVREE)" />
                <img src={SideEffect4ImgMobile} className="show-for-small-only" alt="Examples of hyperpigmentation (before and during
treatment with IMCIVREE)" />
              </div>
          </Slider>
        </div>
      </div>

      <p className="md_mt-3 lg_mt-3 mt-2">
        <ul className="neon-green-bullets text-left">
          <li>IMCIVREE may also lead to increased production of melanin (which gives color to your skin)
            <ul className="neon-green-bullets text-left">
              <li className="is-dash">This usually occurs within the first 2 to 3 weeks of starting IMCIVREE</li>
              <li className="is-dash">Darkening of the skin may vary</li>
            </ul>
          </li>
          <li>IMCIVREE can cause darkening of existing skin growths, such as moles</li>
          <li>You should have a full body skin exam before starting and during treatment with IMCIVREE to check for skin changes</li>
          <li>Changes in skin color went away when treatment with IMCIVREE was stopped</li>
        </ul>
      </p>
      <p>These are not all the possible side effects of IMCIVREE. Please review all the possible side effects of IMCIVREE included in the <Link to="https://rhythm-vault-digital-publishing-production.s3.amazonaws.com/IMCIVREEPatientPrescribingInformation.pdf" target="_blank"><u>Patient Information</u></Link> and talk to your doctor about any questions you may have.</p>
      <div className="text-center pt-1">
        <Button large copy={<>Learn how IMCIVREE is given</>} url="/ppl/how-imcivree-is-given/" />
      </div>
    </div>
  </Layout>
)

export default ClinicalStudyResultsPage

import React from "react"
import { Link } from "gatsby"

import Layout from "../../../components/hcp-ppl-layout"
import Seo from "../../../components/seo"
import Accordion from "../../../components/template-partials/global-components/accordion/accordion"

const Faq = () => {
  return (
    <Layout pageId="faq">
      <Seo
        title="Frequently Asked Questions | IMCIVREE® (setmelanotide) injection"
        description="View frequently asked questions about IMCIVREE® (setmelanotide). Please see full Prescribing Information for Important Safety Information."
        ogTitle="Frequently Asked Questions | IMCIVREE® (setmelanotide) injection"
        ogDesc="View frequently asked questions about IMCIVREE® (setmelanotide). Please see full Prescribing Information for Important Safety Information."
      />
      <div id="faq-accordian-container" className="content-block">
        <h1 className="h1 lg_mt-1">
          Frequently asked questions about IMCIVREE
        </h1>

        <Accordion className="mb-2 md_mb-3">
          <Accordion.Item isOpen="true">
            <Accordion.Drawer>Indications and usage</Accordion.Drawer>
            <Accordion.Expanded>
              <div className="columns">
                <h5 className="h5">What is IMCIVREE indicated for?</h5>
                <p>
                  IMCIVREE is indicated for chronic weight management in adult
                  and pediatric patients 6 years of age and older with monogenic
                  or syndromic obesity due to<sup>1</sup>:
                </p>
                <ul className="neon-green-bullets mb-1 md_mb-1">
                  <li className="mb-1">
                    Pro-opiomelanocortin (POMC), proprotein convertase
                    subtilisin/kexin type 1 (PCSK1), or leptin receptor (LEPR)
                    deficiency as determined by an FDA-approved test
                    demonstrating variants in <em>POMC</em>, <em>PCSK1</em>, or{" "}
                    <em>LEPR</em> genes that are interpreted as pathogenic,
                    likely pathogenic, or of uncertain significance (VUS)
                  </li>
                  <li className="mb-1">Bardet-Biedl syndrome (BBS)</li>
                </ul>
                <p>
                  IMCIVREE is <span className="textUnderlined">not</span>{" "}
                  indicated for the treatment of patients with the following
                  conditions as IMCIVREE would not be expected to be effective:
                </p>
                <ul className="neon-green-bullets mb-1 md_mb-1">
                  <li className="mb-1">
                    Obesity due to suspected POMC, PCSK1, or LEPR deficiency
                    with <em>POMC</em>, <em>PCSK1</em>, or <em>LEPR</em>{" "}
                    variants classified as benign or likely benign
                  </li>
                  <li className="mb-1">
                    Other types of obesity not related to POMC, PCSK1, or LEPR
                    deficiency or BBS, including obesity associated with other
                    genetic syndromes and general (polygenic) obesity
                  </li>
                </ul>
                <p className="mb-0">
                  The FDA-approved test, or companion diagnostic device (CDx),
                  is approved by the FDA to select adult and pediatric patients
                  6 years of age and older who have obesity and certain variants
                  in <em>POMC</em>, <em>PCSK1</em>, or <em>LEPR</em> genes for
                  treatment with IMCIVREE in accordance with the approved
                  therapeutic product labeling.{" "}
                  <a
                    href="https://www.preventiongenetics.com/sponsoredTesting/Rhythm/CDX"
                    target="_blank"
                  >
                    <u>Find more information on this FDA-approved test.</u>
                  </a>
                </p>
              </div>
            </Accordion.Expanded>
          </Accordion.Item>
          <Accordion.Item>
            <Accordion.Drawer>Contraindications</Accordion.Drawer>
            <Accordion.Expanded>
              <div className="columns">
                <h5 className="h5">
                  Are there any contraindications to IMCIVREE?
                </h5>
                <p className="mb-0">
                  No. There are no contraindications to IMCIVREE.<sup>1</sup>
                </p>
              </div>
            </Accordion.Expanded>
          </Accordion.Item>
          <Accordion.Item>
            <Accordion.Drawer>Genetic testing for IMCIVREE</Accordion.Drawer>
            <Accordion.Expanded>
              <div className="columns">
                <h5 className="h5">
                  How do I acquire the FDA-approved test for my patients?
                </h5>
                <p>
                  IMCIVREE is indicated for chronic weight management in
                  patients 6 years of age and older with obesity due to POMC,
                  PCSK1, or LEPR deficiency confirmed by an FDA-approved test.
                  These diseases are biallelic, signifying that variants are
                  present in both copies of the gene, and therefore include both
                  homozygous and compound heterozygous forms.
                </p>
                <p>
                  The FDA-approved test, or companion diagnostic device (CDx),
                  is approved by the FDA to select adult and pediatric patients
                  6 years of age and older who have obesity and certain variants
                  in <em>POMC</em>, <em>PCSK1</em>, or <em>LEPR</em> genes for
                  treatment with IMCIVREE in accordance with the approved
                  therapeutic product labeling.{" "}
                  <a
                    href="https://www.preventiongenetics.com/sponsoredTesting/Rhythm/CDX"
                    target="_blank"
                  >
                    <u>Find more information on this FDA-approved test.</u>
                  </a>
                </p>
                <h5 className="h5">
                  Do I have to submit the results of this FDA-approved test with
                  the IMCIVREE Prescription Start Form?
                </h5>
                <p className="mb-0">
                  No, you do not have to submit the results of the test with the{" "}
                  <a href="/Start_Form.pdf" target="_blank">
                    <u>IMCIVREE Prescription Start Form</u>
                  </a>
                  . However, a positive result on the FDA-approved test is
                  required for patients to be eligible for financial support.
                  Your patient's insurance plan may also require the result on
                  the FDA-approved test.
                </p>
              </div>
            </Accordion.Expanded>
          </Accordion.Item>
          <Accordion.Item>
            <Accordion.Drawer>Dosing and administration</Accordion.Drawer>
            <Accordion.Expanded>
              <div className="columns">
                <h5 className="h5">How is IMCIVREE administered?</h5>
                <p>
                  IMCIVREE is administered by subcutaneous injection once daily,
                  at the beginning of each day, without regard to meals.
                  <sup>1</sup> The process for subcutaneous injection is
                  described in the{" "}
                  <a
                    href="https://rhythm-vault-digital-publishing-production.s3.amazonaws.com/IMCIVREEPrescribingInformation_IFU.pdf#page=2"
                    target="_blank"
                  >
                    <u>Instructions for Use</u>
                  </a>
                  , which contains complete administration instructions with
                  illustrations.
                </p>
                <h5 className="h5">
                  What part of the body should patients inject IMCIVREE into?
                </h5>
                <p>
                  IMCIVREE should be injected subcutaneously in the abdomen,
                  thigh, or arm, rotating to a different site each day. (Refer
                  to Step 5 of the{" "}
                  <a
                    href="https://rhythm-vault-digital-publishing-production.s3.amazonaws.com/IMCIVREEPrescribingInformation_IFU.pdf#page=2"
                    target="_blank"
                  >
                    <u>Instructions for Use</u>
                  </a>
                  .) Do not administer IMCIVREE intravenously or
                  intramuscularly.<sup>1</sup>
                </p>
                <h5 className="h5">
                  What type of syringe should the patient use to administer
                  IMCIVREE?
                </h5>
                <p>
                  Prior to initiation of IMCIVREE, train patients or their
                  caregivers on proper injection technique. Instruct patients to
                  use a 1-mL syringe with a 28- or 29-gauge needle appropriate
                  for subcutaneous injection.<sup>1</sup>
                </p>
                <h5 className="h5">
                  My patient missed a dose. Does he/she need to retitrate their
                  dose?
                </h5>
                <p>
                  If a dose is missed, the once-daily regimen should be resumed
                  as prescribed with the next scheduled dose.<sup>1</sup>
                </p>
                <h5 className="h5">
                  My patient discontinued treatment for an extended period of
                  time. Do I need to retitrate the dose?
                </h5>
                <p>
                  No. Dose titration is only recommended to initially assess
                  tolerability to the medication. In clinical studies, patients
                  were placed on placebo for a 4-week period, then reinitiated
                  therapy at their therapeutic dose for the remainder of the
                  study.<sup>1</sup>
                </p>
                <h5 className="h5">
                  My patient accidentally administered an overdose of IMCIVREE.
                  What adverse events should we be most concerned about?
                </h5>
                <p className="mb-0">
                  In the event of an overdose, initiate appropriate supportive
                  treatment according to the patient’s clinical signs and
                  symptoms.<sup>1</sup>
                </p>
              </div>
            </Accordion.Expanded>
          </Accordion.Item>
          <Accordion.Item>
            <Accordion.Drawer>Safety</Accordion.Drawer>
            <Accordion.Expanded>
              <div className="columns">
                <h5 className="h5">
                  What were the most common adverse events observed in the
                  clinical trials?
                </h5>
                <p>
                  The most common adverse reactions (incidence ≥20%) included
                  skin hyperpigmentation, injection site reactions, nausea,
                  headache, diarrhea, abdominal pain, vomiting, depression, and
                  spontaneous penile erection.<sup>1</sup>
                </p>
                <p>
                  Injection site reactions, nausea, and vomiting tended to occur
                  within the first month after starting treatment, and then
                  diminished over time.<sup>2</sup>
                </p>
                <p>
                  Changes in skin pigmentation/hair color are primarily reported
                  within the first month of treatment, then become more gradual
                  or stabilize afterward.<sup>2</sup>
                </p>
                <h5 className="h5">
                  Were any serious treatment-related adverse reactions observed
                  in clinical studies?
                </h5>
                <p>
                  No serious treatment-related adverse reactions were observed
                  in the clinical trial. However, because there is benzyl
                  alcohol preservative in IMCIVREE, there is a risk of serious
                  adverse reactions in neonates and low birth weight infants.
                  IMCIVREE is not approved for use in neonates or infants.
                  Serious and fatal adverse reactions including “gasping
                  syndrome” can occur in neonates and low birth weight infants
                  treated with benzyl alcohol-preserved drugs.<sup>1</sup>
                </p>
                <h5 className="h5">
                  What is hyperpigmentation? Is it just skin darkening?
                </h5>
                <p>
                  Hyperpigmentation can involve the darkening of skin and
                  pre-existing nevi in the absence of ultraviolet (UV) exposure.
                  <sup>1</sup>
                </p>
                <h5 className="h5">Is skin darkening reversible?</h5>
                <p>
                  Generalized increased skin pigmentation occurred in the
                  majority of patients (69%) treated with IMCIVREE in clinical
                  trials. IMCIVREE may also cause darkening of pre-existing nevi
                  due to its pharmacologic effect. This effect is reversible
                  upon discontinuation of the drug.<sup>1</sup>
                </p>
                <h5 className="h5">
                  Why does my patient need a skin examination before starting
                  IMCIVREE?
                </h5>
                <p>
                  IMCIVREE is an MC4 receptor agonist with some activity at the
                  melanocortin-1 (MC1) receptors; activation of MC1 leads to
                  accumulation of melanin and increased skin pigmentation.
                  <sup>1</sup>
                </p>
                <p>
                  Hyperpigmentation was reversible upon discontinuation of
                  IMCIVREE in clinical trials.<sup>1</sup>
                </p>
                <p>
                  Perform a full body skin examination prior to initiation and
                  periodically during treatment with IMCIVREE to monitor
                  pre-existing and new skin pigmentary lesions.<sup>1</sup>
                </p>
                <h5 className="h5">
                  Does IMCIVREE cause or worsen depression?
                </h5>
                <p>
                  Patients with a history of severe depression were excluded
                  from the clinical trials.<sup>3</sup> Some drugs that target
                  the central nervous system, such as IMCIVREE, may cause
                  depression or suicidal ideation. Depression (26%) and suicidal
                  ideation (11%) occurred in adults and pediatric patients in
                  IMCIVREE clinical studies. Patients with a history of
                  depression or suicidal ideation may be at increased risk for
                  recurrent episodes while taking IMCIVREE.<sup>1</sup>
                </p>
                <p>
                  Monitor patients for new onset or worsening of depression,
                  suicidal thoughts or behavior, or any unusual changes in mood
                  or behavior. Consider discontinuing IMCIVREE if patients
                  experience suicidal thoughts or behaviors or if clinically
                  significant or persistent depression symptoms occur.
                  <sup>1</sup>
                </p>
                <h5 className="h5">
                  Can IMCIVREE be used with antidepressants?
                </h5>
                <p>
                  No clinical studies evaluating the drug-drug interaction
                  potential of IMCIVREE have been conducted.<sup>1</sup>
                </p>
                <h5 className="h5">Can IMCIVREE cause prolonged erections?</h5>
                <p>
                  Spontaneous penile erections in males (24%) occurred in
                  clinical trials with IMCIVREE. Inform patients that these
                  events may occur and instruct patients who have an erection
                  lasting longer than 4 hours to seek emergency medical
                  attention.<sup>1</sup>
                </p>
                <h5 className="h5">
                  What sexual disturbances were reported in females in the
                  clinical trials?
                </h5>
                <p>
                  Sexual adverse reactions in females (7% in IMCIVREE-treated
                  patients and 0% in placebo-treated patients from an unapproved
                  population) occurred in clinical studies with IMCIVREE.
                  <sup>1</sup>
                </p>
                <h5 className="h5">
                  What should a patient do if they think they are or may become
                  pregnant?
                </h5>
                <p>
                  Discontinue IMCIVREE when pregnancy is recognized unless the
                  benefits of therapy outweigh the potential risks to the fetus.
                  <sup>1</sup>
                </p>
                <h5 className="h5">
                  Can patients take IMCIVREE while breastfeeding? Does IMCIVREE
                  enter the breast milk?
                </h5>
                <p>
                  There is no information on the presence of IMCIVREE or its
                  metabolites in human milk, the effects on the breastfed
                  infant, or the effects on milk production. Treatment with
                  IMCIVREE is not recommended while breastfeeding.<sup>1</sup>
                </p>
                <p className="mb-0">
                  IMCIVREE from multiple-dose vials contains the preservative
                  benzyl alcohol. Because benzyl alcohol is rapidly metabolized
                  by a lactating woman, benzyl alcohol exposure in the breastfed
                  infant is unlikely. However, adverse reactions have occurred
                  in premature neonates and low birth weight infants who
                  received intravenously administered benzyl alcohol-containing
                  drugs.<sup>1</sup>
                </p>
              </div>
            </Accordion.Expanded>
          </Accordion.Item>
          <Accordion.Item>
            <Accordion.Drawer>Mechanism of action</Accordion.Drawer>
            <Accordion.Expanded>
              <div className="columns">
                <h5 className="h5">What is the mechanism of action?</h5>
                <p className="mb-0">
                  IMCIVREE, an MC4R agonist, acts in place of
                  alpha-melanocyte-stimulating hormone to activate the MC4
                  receptor to reestablish MC4R pathway activity.<sup>1,4,5</sup>
                </p>
                <p className="mb-0 md_mt-1 mt-1">
                  Activation of the MC4R pathway can help to increase satiety
                  signals and energy expenditure, therefore reducing hunger, and
                  consequently, food intake and weight.<sup>1</sup>
                </p>
              </div>
            </Accordion.Expanded>
          </Accordion.Item>
          <Accordion.Item>
            <Accordion.Drawer>Clinical studies</Accordion.Drawer>
            <Accordion.Expanded>
              <div className="columns">
                <h5 className="h5">
                  Have the pivotal studies on IMCIVREE been published?
                </h5>
                <p>
                  Yes. The results of these studies have been published in{" "}
                  <em>The Lancet Diabetes & Endocrinology</em>. To see the
                  publication,{" "}
                  <a
                    href="https://www.thelancet.com/journals/landia/article/PIIS2213-8587(20)30364-8/fulltext"
                    target="_blank"
                  >
                    <u>click here</u>
                  </a>
                  .
                </p>
                <h5 className="h5">
                  How were the safety and effectiveness of IMCIVREE evaluated?
                </h5>
                <p>
                  The safety and efficacy of IMCIVREE for the treatment of POMC,
                  PCSK1, or LEPR deficiency obesity were established in 2
                  identically designed, 1-year, open-label studies, each with an
                  8-week, double-blind withdrawal period.<sup>1</sup>
                </p>
                <p>
                  To see the full results of the IMCIVREE clinical trials,{" "}
                  <Link to="/hcp/ppl/moa/">
                    <u>click here</u>
                  </Link>
                  .
                </p>
                <h5 className="h5">
                  Were the patients who did not achieve at least a 5-kg weight
                  loss (or at least 5% weight loss if baseline body weight was
                  &lt;100 kg) at the end of the open-label treatment period
                  included in any analyses? If so, which ones?
                </h5>
                <p>
                  Yes. These patients were included in the primary endpoint
                  analysis and safety analyses.<sup>1,4</sup>
                </p>
                <h5 className="h5">
                  Did patients regain weight after stopping IMCIVREE?
                </h5>
                <p>
                  Yes. When treatment with IMCIVREE was withdrawn in patients
                  who had lost weight during the 10-week open-label period,
                  these patients gained an average of 5.5 kg in Study 1 and 5.0
                  kg in Study 2 over 4 weeks. Reinitiation of treatment with
                  IMCIVREE resulted in subsequent weight loss.<sup>1</sup>
                </p>
                <p className="mb-0">
                  To see the full results of the IMCIVREE clinical trials,{" "}
                  <Link to="/hcp/ppl/moa/">
                    <u>click here</u>
                  </Link>
                  .
                </p>
              </div>
            </Accordion.Expanded>
          </Accordion.Item>
          <Accordion.Item>
            <Accordion.Drawer>Accessing IMCIVREE</Accordion.Drawer>
            <Accordion.Expanded>
              <div className="columns">
                <h5 className="h5">
                  How do I prescribe IMCIVREE for my patient(s)?
                </h5>
                <p>
                  Rhythm’s companion diagnostic device (or CDx) is approved by
                  the FDA and is used to identify people who are appropriate for
                  treatment with IMCIVREE based on a genetic confirmation of
                  POMC, PCSK1, or LEPR deficiency. For more information about
                  acquiring this FDA-approved genetic test, please visit{" "}
                  <a
                    href="https://www.preventiongenetics.com/sponsoredTesting/Rhythm/CDX"
                    target="_blank"
                  >
                    <u>rhythm.preventiongenetics.com</u>
                  </a>
                  .
                </p>
                <p class="mb-0 md_mb-0">
                  To prescribe IMCIVREE, a completed IMCIVREE Prescription Start
                  Form can be faxed to 1-877-805-0130 or emailed to{" "}
                  <a href="mailto:patientsupport@rhythmtx.com">
                    <u>patientsupport@rhythmtx.com</u>
                  </a>
                </p>
                <p>
                  Note: The preferred method of starting IMCIVREE is via our
                  Prescription Start Form. If you would prefer to e-prescribe,{" "}
                  <strong>please be sure to select PANTHERx Rare Pharmacy</strong>.
                </p>

                <h5 className="h5 lg_mt-1 mt-1">
                  Is there a copay program for IMCIVREE?
                </h5>
                <p>
                  Yes, there are financial assistance programs available for
                  eligible patients. The patient support program, Rhythm InTune,
                  can answer any questions your patients may have about their
                  insurance coverage for IMCIVREE. They can also facilitate the
                  prior authorization process and register eligible,
                  commercially insured patients with a qualifying diagnosis for
                  a copay support program. Rhythm InTune can be reached at{" "}
                  <a
                    href="tel:+1-855-206-0815"
                    style={{ textDecoration: "underline" }}
                  >
                    1-855-206-0815
                  </a>
                  , Monday–Friday, 8 <span style={{ fontSize: "65%" }}>AM</span>{" "}
                  to 8 <span style={{ fontSize: "65%" }}>PM</span> ET.
                </p>
              </div>
            </Accordion.Expanded>
          </Accordion.Item>
        </Accordion>

        <p className="small fw-normal">
          <b>References:</b> <b>1.</b> IMCIVREE [prescribing information]. Boston, MA. Rhythm Pharmaceuticals, Inc. <b>2.</b> Data on file. Rhythm Pharmaceuticals, Inc. Boston, MA. <b>3.</b> Clément K et al. <i>Lancet Diabetes Endocrinol</i>. [Supplementary appendix] 2020;8(12):960-970. <b>4.</b> Trapp CM et al. <i>Curr Opin Endocrinol Diabetes Obes</i>. 2023;30(2):136-140. <b>5.</b> Haws R et al. <i>Diabetes Obes Metab</i>. 2020;22(11):2133-2140. doi:10.1111/dom.14133. <b>6.</b> Clément K et al. <i>Lancet Diabetes Endocrinol</i>. 2020;8(12):960-970.
        </p>
      </div>
    </Layout>
  )
}

export default Faq

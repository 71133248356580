import * as React from "react"
import Helmet from "react-helmet"

import Layout from "../../components/ppl-layout"
import Seo from "../../components/seo"
import Button from "../../components/button"

import PplHomeHero from "../../images/hero-image-ppl.png"
import PplHomeHeroMobile from "../../images/hero-image-ppl-mobile.png"

import IconBook from "../../images/icons/icon-book.svg"
import IconPhone from "../../images/icons/icon-phone.svg"
import IconDiamond from "../../images/hcp/ppl/icon-diamond.svg"


const IndexPage = () => (
  <Layout pageId="ppl-home">
    <Helmet bodyAttributes={{ class: "patient-ppl ppl-home" }} />
    <Seo
      title="POMC, PCSK1, LEPR | IMCIVREE® (setmelanotide) injection"
      description="Discover IMCIVREE® (setmelanotide) injection, the first and only FDA-approved treatment designed to target an impaired MC4R pathway in the brain, a root cause of hunger and obesity in people living with POMC, PCSK1, or LEPR deficiency. Please see full Prescribing Information for Important Safety Information."
      ogTitle="IMCIVREE® (setmelanotide) injection"
      ogDesc="Discover IMCIVREE® (setmelanotide), an FDA-approved treatment, and learn about the diseases it treats. Please see full Prescribing Information and Important Safety Information."
    />

    <div id="patient-hero">
      <div className="hero position-relative">
        <img src={PplHomeHero} className="position-relative z1 hide-for-small show-for-medium" alt="For POMC, PCSK1, or LEPR deficiency: Discover a World Beyond Obesity"/>
        <img src={PplHomeHeroMobile} className="position-relative z1 show-for-small hide-for-medium" alt="For POMC, PCSK1, or LEPR deficiency: Discover a World Beyond Obesity"/>
        <div className="hero-text-container position-absolute z2 invisibile show-for-sr">
          <div className="hero-text">
            <strong>For POMC, PCSK1, or LEPR deficiency</strong>
            <h1>Discover a World Beyond Obesity</h1>
            <p>Actor portrayals.</p>
          </div>
        </div>
      </div>
    </div>

      <div className="inner-container lg_mt-2 mt-2 ">
        <div className="row show-for-small hide-for-medium">
          <div className="column og">
            <p className="lead">
              <span className="color-teal">
                The first and only FDA-approved
              </span>{" "}
              treatment to target an impaired MC4R pathway in the brain, a root
              cause of hunger and obesity in people living with POMC, PCSK1, or
              LEPR deficiency
            </p>
          </div>
        </div>
        <div className="row text-center medium-text-left lg_mt-2 mt-2">
          <div className="columns og small-12 large-4">
            <Button
              className="lg-bg full-width color-teal"
              icon={IconBook}
              iconAlt="See IMCIVREE results"
              copy={<>See how IMCIVREE can help</>}
              url="/ppl/how-it-works/"
            />
          </div>
          <div className="columns og small-12 large-4 mt-1">
            <Button
              className="lg-bg full-width color-teal"
              icon={IconPhone}
              iconAlt="Rhythm InTune Phone"
              copy={
                <>
                  Connect with a Patient Education Manager for one-on-one
                  personalized support
                </>
              }
              url="/ppl/sign-up/"
            />
          </div>
          <div className="columns og small-12 large-4 mt-1">
            <Button
              className="lg-bg full-width color-teal h-100"
              icon={IconDiamond}
              iconAlt="How IMCIVREE is given"
              copy={<>Learn more about how IMCIVREE is given</>}
              url="/ppl/how-imcivree-is-given/"
            />
          </div>
        </div>
        <div className="row lg_mt-2 mt-2">
          <div className="columns og large-12">
            <p className="small mb-2 md_mb-2">
              LEPR=leptin receptor; MC4R=melanocortin-4 receptor;
              PCSK1=proprotein convertase subtilisin/kexin type 1;
              POMC=proopiomelanocortin.
            </p>
          </div>
        </div>
      </div>

  </Layout>
)

export default IndexPage

import * as React from "react"

import Layout from "../../../components/hcp-bbs-layout"
import Seo from "../../../components/seo"

import ManagingAdverseEventsVideo from "../../../components/managing-adverse-events-video"
import VideoTranscript from "../../../components/video-transcript"
import ManagingAdverseEventsVideoTranscript from "../../../components/template-partials/hcp/bbs/managing-adverse-events-video-transcript"


const ManagingAdverseEventsAndInjectionTrainingHcpBbs = () => (
  <Layout noNav>

    <Seo title="" />

    <div className="content-block">

      <div className="row og">
        <div className="columns og">
          <h1 className="h1">Managing adverse events with IMCIVREE</h1>
          <h2 className="h2">Hear from physicians who have prescribed IMCIVREE.</h2>
        </div>
      </div>

      <ManagingAdverseEventsVideo />
        <VideoTranscript>
          <ManagingAdverseEventsVideoTranscript />
        </VideoTranscript>

    </div>

  </Layout>
)

export default ManagingAdverseEventsAndInjectionTrainingHcpBbs
import * as React from "react"
import { Link } from "gatsby"

import Layout from "../../components/bbs-layout"
import Seo from "../../components/seo"
import Button from "../../components/button"

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import SideEffect1ImgBbs from "../../images/side-effect-bbs-1.png"
import SideEffect2ImgBbs from "../../images/side-effect-bbs-2.png"
import SideEffect3ImgBbs from "../../images/side-effect-bbs-3.png"
import SideEffect4ImgBbs from "../../images/side-effect-bbs-4.png"
import SideEffect1ImgBbsMobile from "../../images/side-effect-bbs-1-mobile.png"
import SideEffect2ImgBbsMobile from "../../images/side-effect-bbs-2-mobile.png"
import SideEffect3ImgBbsMobile from "../../images/side-effect-bbs-3-mobile.png"
import SideEffect4ImgBbsMobile from "../../images/side-effect-bbs-4-mobile.png"

import AdverseEventsVideo from "../../components/adverse-events-video"
import VideoTranscript from "../../components/video-transcript"
import AdverseEventsTranscript from "../../components/template-partials/patient/adverse-events-transcript.js"

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1
};

const SideEffects = () => (
  <Layout>
    <Seo
      title="Side Effects | IMCIVREE® (setmelanotide) injection"
      description="Learn about the possible side effects of IMCIVREE® (setmelanotide). Please see full Prescribing Information and Important Safety Information."
      ogTitle="Side Effects | IMCIVREE® (setmelanotide) injection"
      ogDesc="Learn about the possible side effects of IMCIVREE® (setmelanotide). Please see full Prescribing Information and Important Safety Information."
    />
    <div id="bbs-side-effects" className="content-block">
      <h1 className="h1">What are the possible side effects of IMCIVREE?</h1>
      <h2 className="h2">IMCIVREE was well studied and most side effects were generally mild and improved over time</h2>
      <p>The safety of IMCIVREE has been evaluated in &gt;700 patients over ~10 years of clincal trials.</p>
      <p>The most common side effects in people with obesity and a clinical diagnosis of BBS included:</p>
      <div className="row text-center">
        <div className="column small-12 medium-6">
          <ul className="neon-green-bullets text-left">
            <li>Darkening of the skin (hyperpigmentation) (63%) </li>
            <li>Injection site reactions (51%)</li>
            <li>Nausea (26%)</li>
            <li>Erection that happens without any sexual activity in males (25%)</li>
            <li>Vomiting (19%)</li>
          </ul>
        </div>
        <div className="column small-12 medium-6">
          <ul className="neon-green-bullets text-left">
            <li>Diarrhea (14%)</li>
            <li>Headache (7%)</li>
            <li>Stretch marks (7%)</li>
            <li>Aggression (5%)</li>
            <li>Fatigue (5%)</li>
          </ul>
        </div>
      </div>
      <p className="mt-1 md_mt-1">Most nausea and vomiting events were mild, and none were severe. Nausea and vomiting primarily occurred within the first month of treatment and then sharply declined. These symptoms typically lasted no more than a few days.</p>
      <p>No serious side effects related to IMCIVREE were reported in the BBS trial.</p>
      <p>Contact your doctor if you are experiencing side effects.</p>
      <h2 className="h2 mt-2 md_mt-2">A general darkening of the skin was common but rarely led to a stop in treatment</h2>

      <div className="row the-carousel">
        <div className="column" >
          <Slider {...settings}>
              <div className="carousel-items">
                <p className="text-center fw-bold mb-1">Examples of hyperpigmentation</p>
                <img src={SideEffect1ImgBbs} className="hide-for-small show-for-medium" alt="Examples of hyperpigmentation (before and during
treatment with IMCIVREE" />
                <img src={SideEffect1ImgBbsMobile} className="show-for-small hide-for-medium" alt="Examples of hyperpigmentation (before and during
treatment with IMCIVREE" />
              </div>

              <div className="carousel-items">
                <p className="text-center fw-bold mb-1">Examples of hyperpigmentation</p>
                <img src={SideEffect3ImgBbs} className="hide-for-small show-for-medium" alt="Examples of hyperpigmentation (before and during
treatment with IMCIVREE" />
                <img src={SideEffect3ImgBbsMobile} className="show-for-small hide-for-medium" alt="Examples of hyperpigmentation (before and during
treatment with IMCIVREE" />
              </div>
              <div className="carousel-items">
                <p className="text-center fw-bold mb-1">Examples of hyperpigmentation</p>
                <img src={SideEffect2ImgBbs} className="hide-for-small show-for-medium" alt="Examples of hyperpigmentation (before and during
treatment with IMCIVREE" />
                <img src={SideEffect2ImgBbsMobile} className="show-for-small hide-for-medium" alt="Examples of hyperpigmentation (before and during
treatment with IMCIVREE" />
              </div>
              <div className="carousel-items">
                <p className="text-center fw-bold mb-1">Examples of hyperpigmentation</p>
                <img src={SideEffect4ImgBbs} className="hide-for-small show-for-medium" alt="Examples of hyperpigmentation (before and during
treatment with IMCIVREE" />
                <img src={SideEffect4ImgBbsMobile} className="show-for-small hide-for-medium" alt="Examples of hyperpigmentation (before and during
treatment with IMCIVREE" />
              </div>
          </Slider>
        </div>
      </div>

      <p className="md_mt-3 lg_mt-3 mt-2">
        <ul className="neon-green-bullets text-left">
          <li>IMCIVREE may also lead to increased production of melanin (which gives color to your skin)
            <ul className="neon-green-bullets text-left">
              <li className="is-dash">This usually occurs within the first month of starting IMCIVREE</li>
              <li className="is-dash">Darkening of the skin may vary</li>
            </ul>
          </li>
          <li>IMCIVREE can cause darkening of existing skin growths, such as moles</li>
          <li>You should have a full-body skin exam before starting and during treatment with IMCIVREE to check for skin changes</li>
          <li>Changes in skin color went away when treatment with IMCIVREE was stopped</li>
        </ul>
      </p>

      <div className="row og">
        <div className="columns og">
          <p>These are not all the possible side effects of IMCIVREE. Please review all the possible side effects of IMCIVREE included in the <Link to="https://rhythm-vault-digital-publishing-production.s3.amazonaws.com/IMCIVREEPatientPrescribingInformation.pdf" target="_blank"><u>Patient Information</u></Link> and talk to your doctor about any questions you may have.</p>
        </div>
      </div>

      <div className="row og lg_mt-2 mt-2">
        <div className="columns og">
          <h2 className="h1">Managing possible side effects with IMCIVREE</h2>
          <h3 className="h2">Hear from healthcare providers who have experience managing their patients' side effects</h3>
        </div>
      </div>

      <AdverseEventsVideo/>
      <VideoTranscript>
        <AdverseEventsTranscript/>
      </VideoTranscript>

      <div className="row og lg_mt-3 mt-2">
        <div className="columns og">
          <div className="text-center lg_mt-3 mt-2">
            <Button large copy="Learn how IMCIVREE is given" url="/bbs/how-imcivree-given/" />
          </div>
        </div>
      </div>

    </div>
  </Layout>
)

export default SideEffects

/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.scss"

import IsiCombo from "./template-partials/global-components/isi/isi-combo.js"
import GlobalFooter from "./template-partials/global-components/global-footer.js"
import CookieBanner from "./template-partials/global-components/cookie-banner"
import ExitModal from "./template-partials/global-components/modal/ExitModal"

import { Waypoint } from 'react-waypoint'
import GreenTab from "../images/green-tab.svg"
import GreenArrowUp from "../images/green-arrow-up.svg"
import GatewayHeroImage from "./gatsby-images/gateway-hero-image"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp } from "@fortawesome/free-solid-svg-icons";

import PatientPplSd from "./template-partials/patient/ppl/patient-ppl-sd";

const Layout = ({ children, pageId, noNav }) => {

  const [screenShot, setScreenShot] = useState(false);

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const handleWaypointEnter = () => {
    document.getElementById('isi-docked-container').style.display = 'none';
    if(document.getElementById('cookie-banner')){
      document.getElementById('cookie-banner').classList.add('drop');
    }
    document.querySelector('.main-nav').style.setProperty('--isi-height', '0');
  }
  const handleWaypointLeave = () => {
    document.getElementById('isi-docked-container').style.display = 'block';
    if(document.getElementById('cookie-banner')){
      document.getElementById('cookie-banner').classList.remove('drop');
    }
    document.querySelector('.main-nav').style.setProperty('--isi-height', '200px');
  }
  const openCloseIsi = (e) => {
    let isiDock = document.getElementById('isi-docked-container');
    let bodyTag = document.querySelector('body');

    if (!isiDock.classList.contains('isi-expanded')) {
      isiDock.classList.add('isi-expanded');
      bodyTag.classList.add('isi-body-expanded');
      document.getElementById('isi-button-toggle-copy').innerHTML = 'COLLAPSE ';
    } else {
      isiDock.classList.remove('isi-expanded');
      bodyTag.classList.remove('isi-body-expanded');
      document.getElementById('isi-button-toggle-copy').innerHTML = 'EXPAND ';
    };
  }

  const checkScroll =() =>{
    let scrollTop = window.pageYOffset || (document.documentElement || document.body.parentNode || document.body).scrollTop;
    return scrollTop;
  }

  useEffect(()=>{
    let mh = document.querySelector('.main-header');
    window.onscroll = function() {
      if(checkScroll() > 100){
        mh.classList.add('main-header--scrolled');
      }else{
        if(mh.classList.contains('main-header--scrolled')){
          mh.classList.remove('main-header--scrolled');
        }
      }
    };

    let urlParams = new URLSearchParams(window.location.search);
    let screenshotParam = urlParams.get('screenshot');

    if (screenshotParam && screenshotParam.toLowerCase() === 'true') {
      // Set the variable to true
      setScreenShot(true);
    } else {
      // Set the variable to false
      return false;
    }

  });

  return (
    <>
      <ExitModal />
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} noNav={noNav}/>

      <div className="container container--no-pad">
        <div className="row">
        <div className="columns">

          <main>{children}</main>

          <Waypoint onEnter={handleWaypointEnter} onLeave={handleWaypointLeave}>
            <div id="isiContainer" className="lg_mt-5 mt-2">
              <div className="row">
                <div className="columns og small-12 color-teal lg_mb-2 mb-2"><strong className="h3">Important Safety Information</strong></div>
                <div className="columns og small-12">
                  <IsiCombo/>
                </div>
              </div>
            </div>
          </Waypoint>
        </div>
        </div>

        <div id="isi-docked-container" className={(screenShot ? `sshide` : ``)}>
          {!screenShot &&
          <>
            <div id="isi-expand-collapse-bar" className={`color-gray-bg ` + screenShot}>
              <div className="row row align-middle og">
                <div className="columns og color-white"><strong>Important Safety Information</strong></div>
                <div className="columns og shrink text-right"><button id="isi-button-toggle"onClick={openCloseIsi} style={{ fontWeight: 'bold' }}><span id="isi-button-toggle-copy" className="color-white">EXPAND </span><span className="color-neon-green"><FontAwesomeIcon icon={faAngleUp}/></span></button></div>
              </div>
            </div>
            <div className="row og lg_mt-1 mt-1">
              <div className="columns og">
                <IsiCombo/>
              </div>
            </div>
          </>
          }
        </div>

      </div>
      <div className="show-for-medium" style={{ display: 'flex', textAlign: 'center' }}><img src={GreenTab} id="footer-green-tab" /></div>
      <GlobalFooter type="generic" code={"US-SET-2200172 03/2024"}/>
      {!screenShot &&
        <CookieBanner />
      }
      <PatientPplSd/>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

import React from "react"

import Layout from "../../../components/hcp-bbs-layout"
import Seo from "../../../components/seo"
import Button from "../../../components/button"
import Callout from "../../../components/callout"
import TypesOfObesityChart from "../../../components/types-of-obesity-chart"

const TypesOfObesity = () => {

  return (
    <Layout>
      <Seo
        title="Types of Obesity | IMCIVREE® (setmelanotide) injection | For HCPs"
        description="HCPs: Learn about genetic obesity caused by rare MC4R pathway diseases. Please see full Prescribing Information for Important Safety Information."
        ogTitle="Types of Obesity | IMCIVREE® (setmelanotide) injection"
        ogDesc="Learn more about the disease that IMCIVREE® (setmelanotide) treats. Please see full Prescribing Information for Important Safety Information."
      />
      <div className="content-block pt-4 md_pt-4 mb-2">
        <div className="row">
          <div className="column">
            <h1 className="h1">
              Genetic obesity caused by rare MC4R pathway diseases
            </h1>
            <h2 className="h2">
              Obesity is a multifactorial disease with a variety of phenotypes,
              clinical presentations, and treatment responses<sup>1,2</sup>
            </h2>
          </div>
        </div>

        <div className="types-of-obesity-chart-img-container large">
          <TypesOfObesityChart />
        </div>
      </div>

      <Callout
        left
        className="light-teal left text-left"
      >
        <p>
          Per the 2023 AAP and 2023 OMA guidelines, it is important to treat obesity promptly, using the most intensive, comprehensive treatment available, including pharmacotherapy when indicated<sup>3,8</sup>
        </p>
      </Callout>
          
      <div className="content-block">

        <div className="text-center">
          <Button
            copy="Learn more about hyperphagia in BBS"
            url="/hcp/bbs/hyperphagia/"
          />
        </div>

        <p className="footnote mt-2 md_mt-4 md_mb-1 mb-1">
          AAP=American Academy of Pediatrics; LEPR=leptin receptor;
          MC4R=melanocortin-4 receptor; OMA=Obesity
          Medicine Association; PCSK1=proprotein convertase
          subtilisin/kexin type 1; POMC=proopiomelanocortin.
        </p>
        <p className="footnote references">
          <b>References:</b> <b>1.</b> Loos RJF et al. <em>Nat Rev Genet</em>. 2022;23(2):120-133. doi:10.1038/s41576-021-00414-z. <b>2.</b> Huvenne H et al. <i>Obes Facts</i>. 2016;9(3):158-173. <b>3.</b> Hampl SE et al. <i>Pediatrics</i>. 2023;151(2):e202206064. doi:10.1542/peds.2022-060640. <b>4.</b> Eneli I et al. <i>Appl Clin Genet</i>. 2019;12:87-93. <b>5.</b> Manara E et al. <i>Ital J Pediatr</i>. 2019;45(1):72. <b>6.</b> Forsythe E et al. <i>Front Pediatr</i>. 2018;6:23. doi:10.3389/fped.2018.00023. <b>7.</b> Vaisse C et al. <i>Cold Spring Harb Perspect Biol</i>. 2017;9(7):a028217. <b>8.</b> Tondt J et al. <i>Obesity Algorithm</i><sup>&reg;</sup> <i>2023</i>. Obesity Medicine Association; 2023. Accessed August 11, 2023. https://obesitymedicine.org/obesity-algorithm. 
        </p>
      </div>
    </Layout>
  )
}

export default TypesOfObesity

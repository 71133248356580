import React, { useEffect } from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import RightCaret from "../images/right-caret.svg"

import HeroMobile from "../images/gateway/gateway-hero-bg-mobile.png"
import MainLogo from "../images/imcivree-logo.svg"
import GreenTab from "../images/green-tab-gateway.png"

export const query = graphql`
query pdfBySlug($slug: String) {
  markdownRemark(frontmatter: {slug: {eq: $slug}}) {
    frontmatter {
      file
      slug
      title
    }
  }
}
`

const GatewayHero = () => {
  return (
    <div id="gateway-hero">
      <div className="row show-for-small-only">
        <div className="columns small-12">
          <img src={HeroMobile} />
        </div>
      </div>
      <div className="row">
        <div className="content">
          <h2 className="color-teal">For Patients</h2>
          <h3>Choose your condition:</h3>
          <Link to="/bbs">
            <div className="row align-middle">
              <div className="columns small-11">
                <span className="align-self-left">Bardet-Biedl syndrome</span>
              </div>
              <div className="columns small-1">
                <img
                  className="align-self-right"
                  src={RightCaret}
                  alt="Right Caret"
                />
              </div>
            </div>
          </Link>
          <Link
            className="align-middle"
            to="/ppl"
            style={{ marginBottom: "0" }}
          >
            <div className="row align-middle">
              <div className="columns small-11">
                <span>POMC, PCSK1, or LEPR deficiency</span>
              </div>
              <div className="columns small-1">
                <img
                  className="align-self-right"
                  src={RightCaret}
                  alt="Right Caret"
                />
              </div>
            </div>
          </Link>
        </div>
      </div>
      <div className="row">
        <div className="content">
          <h2 className="color-teal">For Healthcare Professionals</h2>
          <h3>Choose a condition:</h3>
          <Link to="/hcp/bbs">
            <div className="row align-middle">
              <div className="columns small-11">
                <span className="align-self-left">Bardet-Biedl syndrome</span>
              </div>
              <div className="columns small-1">
                <img
                  className="align-self-right"
                  src={RightCaret}
                  alt="Right Caret"
                />
              </div>
            </div>
          </Link>
          <Link
            className="align-middle"
            to="/hcp/ppl"
            style={{ marginBottom: "0" }}
          >
            <div className="row align-middle">
              <div className="columns small-11">
                <span>POMC, PCSK1, or LEPR deficiency</span>
              </div>
              <div className="columns small-1">
                <img
                  className="align-self-right"
                  src={RightCaret}
                  alt="Right Caret"
                />
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default function IndexPage({props, data}) {
  let lastKnownScrollPosition = 0
  let ticking = false

  //pdf redirects
  const {slug, file} = data.markdownRemark.frontmatter;
  

  function animate(scrollPos) {
    if (scrollPos > 25) {
      document.querySelector(`.main-logo--header`).classList.add("scrolled")
    } else {
      document.querySelector(`.main-logo--header`).classList.remove("scrolled")
    }
  }

  useEffect(() => {
    if (typeof window !== "undefined") {
      //pdf redirect
      // remove trailing slashes from pathname and slug
      if (window.location.pathname.replace(/\//g,'') === slug.replace(/\//g,'')){
        console.log(window.location.pathname)
        // redirect after 1 sec
        setTimeout(() => {
          window.location.href = file;
        }, "1000");
      }
      
      
      document.addEventListener("scroll", e => {
        lastKnownScrollPosition = window.scrollY

        if (!ticking) {
          window.requestAnimationFrame(() => {
            animate(lastKnownScrollPosition)
            ticking = false
          })
          ticking = true
        }
      })
    }
  }, [])

  return (
    <>
      <Layout>
        <Seo
          title="IMCIVREE® (setmelanotide) injection"
          description="Discover IMCIVREE® (setmelanotide) injection, the first and only FDA-approved treatment to target an impaired MC4R pathway, a root cause of hunger and obesity in people living with Bardet-Biedl syndrome (BBS), or POMC, PCSK1, or LEPR deficiency. Please see full Prescribing Information and Important Safety Information."
          ogTitle="IMCIVREE® (setmelanotide) injection"
          ogDesc="Learn about IMCIVREE® (setmelanotide) injection and the condition that it treats. Please see full Prescribing Information and Important Safety Information."
        />
        <div className="row lg_mt-4 mt-1">
          <img
            src={GreenTab}
            alt=""
            style={{ width: "100%", height: "31px" }}
            className="mb-2 md_mb-2"
          />
          <div className="row og mb-1 md_mb-2">
            <div className="columns small-12 medium-12 large-4 pl-2 md_pl-2 show-for-large">
              <img
                src={MainLogo}
                alt="Imcivree Logo"
                style={{ maxWidth: "310px" }}
              />
            </div>
            <div className="columns small-12 medium-12 large-8 pl-2 pr-2 mt-2 mb-0 md_mt-4 lg_mt-0 md_mb-0 align-self-bottom">
              <h1 className="h2--no-margin-bottom h3--size-only jost-semibold mb-0 md_mb-0 md_pr-2">
                IMCIVREE is the{" "}
                <span className="color-teal">first and only FDA-approved</span>{" "}
                treatment to target an impaired MC4R pathway in the brain, a
                root cause of hunger and obesity in people living with
                Bardet-Biedl syndrome (BBS) or POMC, PCSK1, or LEPR deficiency<sup>1,2</sup>
              </h1>
            </div>
          </div>
        </div>

        <GatewayHero />

        {/*
        <div className="row lg_mt-2 mt-0">
          <div className="columns small-12 large-6 og">
            <div className="gateway-links-container row">
              <div className="columns og small-12">
                <h2>For Patients</h2>
              </div>
              <div className="columns og small-12">
                <p>Choose your condition:</p>
              </div>
              <div className="gateway-links colums og align-justify medium-unstack">
                <div className="row">
                <GatewayLink segment="Bardet-Biedl syndrome" page="bbs" />
                <GatewayLink
                  segment="POMC, PCSK1, or LEPR deficiency"
                  page="ppl"
                />
                </div>
              </div>
            </div>
          </div>
          <div className="columns og small-12 large-6">
            <div className="gateway-links-container row">
              <div className="columns og small-12">
                <h2>For Healthcare Professionals</h2>
              </div>
              <div className="columns og small-12">
                <p>Choose condition:</p>
              </div>
              <div className="gateway-links row align-justify medium-unstack">
                <div className="row">
                  <GatewayLink segment="Bardet-Biedl syndrome" page="hcp/bbs" />
                  <GatewayLink
                    segment="POMC, PCSK1, or LEPR deficiency"
                    page="hcp/ppl"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        */}
        <div className="row lg_mt-2 mt-1">
          <div className="columns og">
            <p className="footnote">
              LEPR=leptin receptor; MC4R=melanocortin-4 receptor;
              PCSK1=proprotein convertase subtilisin/kexin type 1;
              POMC=proopiomelanocortin.
            </p>
            <p className="footnote mt-1 md_mt-1">
              <b>References: 1.</b> IMCIVREE [prescribing information]. Boston, MA. Rhythm Pharmaceuticals, Inc. <b>2.</b> Eneli I et al. <em>Appl Clin Genet.</em> 2019;12:87-93.
            </p>
          </div>
        </div>
      </Layout>
    </>
  )
}

import React from "react"
import Img from "gatsby-image"
import { graphql, useStaticQuery } from 'gatsby'
import PropTypes from "prop-types"

const BbsSymptoms = ({ alt }) => {
  const { mobileImage, desktopImage } = useStaticQuery(
    graphql`
      query {
        mobileImage: file(relativePath: { eq: "bbs-symptoms-mobile.png" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        desktopImage: file(relativePath: { eq: "bbs-symptoms.png" }) {
          childImageSharp {
            fluid(maxWidth: 800, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

 // Set ImageData.
  const sources = [
    mobileImage.childImageSharp.fluid,
    {
      ...desktopImage.childImageSharp.fluid,
      media: `(min-width: 641px)`,
    },
  ]

  return (
    <>
      <div className="oversized-image-overview">
        <Img height="auto" fluid={sources} alt={alt} />
      </div>
    </>
  )
}

export default BbsSymptoms

BbsSymptoms.defaultProps = {
  alt: 'This is where you have alt text @todo add me',
};

BbsSymptoms.propTypes = {
  alt: PropTypes.string.isRequired
}

import * as React from "react"
import Helmet from "react-helmet";

import Layout from "../../components/bbs-layout"
import Seo from "../../components/seo"
import Button from "../../components/button"
import "../../components/scss-pages/patient/bbs/_index.scss"

import BbsHomeHero from "../../images/hero-image-bbs.png"
import BBsHomeHeroMobile from "../../images/hero-image-bbs-mobile.png"

import IconBook from "../../images/icons/icon-book.svg"
import IconPhone from "../../images/icons/icon-phone.svg"
import IconSignup from "../../images/icons/icon-email.svg"


const IndexPage = () => (
  <Layout pageId="bbs-home">
    <Helmet bodyAttributes={{ class: 'patient-bbs bbs-home'}}/>
    <Seo
      title="Bardet-Biedl Syndrome (BBS) | IMCIVREE® (setmelanotide) injection"
      description="Discover IMCIVREE® (setmelanotide), the first and only FDA-approved treatment to target an impaired MC4R pathway, a root cause of hunger and obesity in people living with BBS. Please see full Prescribing Information and Important Safety Information."
      ogTitle="IMCIVREE® (setmelanotide) injection"
      ogDesc="Discover IMCIVREE® (setmelanotide), an FDA-approved treatment, and learn about the disease it treats. Please see full Prescribing Information and Important Safety Information."
    />

    <div id="patient-bbs-home">

      <div id="patient-hero">
        <div className="hero position-relative">
          <img src={BbsHomeHero} className="position-relative z1 hide-for-small show-for-medium" alt="For Bardet-Biedl syndrome (BBS): Discover a World Beyond Obesity"/>
          <img src={BBsHomeHeroMobile} className="position-relative z1 show-for-small hide-for-medium" alt="For Bardet-Biedl syndrome (BBS): Discover a World Beyond Obesity"/>
          <div className="hero-text-container position-absolute z2 invisibile show-for-sr">
            <div className="hero-text">
              <strong>For Bardet-Biedl syndrome (BBS)</strong>
              <h1>Discover a World Beyond Obesity</h1>
              <p>Actor portrayals.</p>
            </div>
          </div>
        </div>
      </div>

      <div>
          <div className="row lg_mt-2 mt-1 show-for-small hide-for-medium">
            <div className="columns og">
              <p className="lead"><strong className="color-teal">The first and only FDA-approved</strong> treatment to target an impaired MC4R pathway in the brain, a root cause of hunger and obesity in people living with BBS</p>
            </div>
          </div>
          <div className="row lg_mt-2 mt-2">
            <div className="columns og small-12 large-4">
              <Button className="lg-bg full-width color-teal h-100" icon={IconBook} iconAlt="See IMCIVREE results" copy={<>See how IMCIVREE can help</>} url="/bbs/how-imcivree-works/" />
            </div>
            <div className="columns og small-12 large-4 mt-1">
              <Button className="lg-bg full-width color-teal h-100" icon={IconPhone} iconAlt="Rhythm InTune Phone" copy={<>Connect with a Patient Education Manager for one-on-one personalized support</>} url="/bbs/sign-up/" />
            </div>
            <div className="columns og small-12 large-4 mt-1">
              <Button className="lg-bg full-width color-teal h-100" icon={IconSignup} iconAlt="Register for IMCIVREE updates" copy={<>Register to receive email updates about IMCIVREE</>} url="/bbs/sign-up/" />
            </div>
          </div>
          <div className="row lg_mt-2 mt-2">
            <div className="columns og large-12">
              <p className="small mb-2 md_mb-2">MC4R=melanocortin-4 receptor.</p>
            </div>
          </div>
        </div>
    </div>
  </Layout>
)
export default IndexPage

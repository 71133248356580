import React from 'react'
import vpl from "../images/video-placeholder.jpg";

const TakingImcivreeVideo = (props) => {
  return (
    <>
    <div className="row og" style={{ marginBottom: `21px` }}>
      <div className={`columns responsive-video-container ` + props.className}>
        <iframe src="https://player.vimeo.com/video/748025239?h=86d134d510&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" width="100%" height="auto" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen title="IMCIVREE Injection Training Video"></iframe>
        <img src={vpl} width="100%" height="auto" alt="How to Inject IMCIVREE video placeholder"/>
      </div>
    </div>
    </>
  )
}

export default TakingImcivreeVideo

import * as React from "react"

import Layout from "../../../components/hcp-bbs-layout"
import Seo from "../../../components/seo"

import LearnMoreAboutPemVideo from "../../../components/learn-more-about-patient-education-managers-video"
import VideoTranscript from "../../../components/video-transcript"
import LearnMoreAboutPemVideoTranscript from "../../../components/template-partials/hcp/bbs/learn-more-about-patient-education-managers-transcript"


const LearnMoreAboutPemVideoHcpBbs = () => (
  <Layout noNav>

    <Seo title="" />

    <div className="content-block">

      <div className="row og">
        <div className="columns og">
          <h1 className="h1">Learn more about Patient Education Managers</h1>
          <h2 className="h2">See how they can provide optimal support for patients in your practice and help them start and stay on therapy.</h2>
        </div>
      </div>

      <LearnMoreAboutPemVideo />
        <VideoTranscript>
          <LearnMoreAboutPemVideoTranscript />
        </VideoTranscript>

    </div>

  </Layout>
)

export default LearnMoreAboutPemVideoHcpBbs
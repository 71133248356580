import * as React from "react"

import Layout from "../../../components/hcp-bbs-layout"
import Seo from "../../../components/seo"

import TheImpactOfImcivreeVideo from "../../../components/impact-imcivree-video"
import VideoTranscript from "../../../components/video-transcript"
import TheImpactOfImcivreeVideoTranscript from "../../../components/template-partials/hcp/bbs/the-impact-of-imcivree-transcript"


const TheImpactOfImcivreeHcpBbs = () => (
  <Layout noNav>

    <Seo title="" />

    <div className="content-block">

      <div className="row og">
        <div className="columns og">
          <h1 className="h1">The impact of IMCIVREE</h1>
          <h2 className="h2">Learn more about what to expect with IMCIVREE and ways to monitor your patient’s response to treatment.</h2>
        </div>
      </div>

      <TheImpactOfImcivreeVideo />
        <VideoTranscript>
          <TheImpactOfImcivreeVideoTranscript />
        </VideoTranscript>

    </div>

  </Layout>
)

export default TheImpactOfImcivreeHcpBbs
import * as React from "react"

import Layout from "../../../components/hcp-ppl-layout"
import Seo from "../../../components/seo"

import InjectionTrainingPatientPplEsVideo from "../../../components/injection-training-video-es"
import VideoTranscript from "../../../components/video-transcript"
import InjectionTrainingPatientPplEsVideoTranscript from "../../../components/template-partials/patient/injection-training-es-transcript"


const InjectionTrainingPatientEsHcpPpl = () => (
  <Layout noNav>

    <Seo title="" />

    <div className="content-block">

      <div className="row og">
        <div className="columns og">
          <h1 className="h1">Cómo inyectar IMCIVREE</h1>
          <h2 className="h2">Este video muestra cómo inyectar y almacenar su medicamento</h2>
        </div>
      </div>

      <InjectionTrainingPatientPplEsVideo />
        <VideoTranscript>
          <InjectionTrainingPatientPplEsVideoTranscript />
        </VideoTranscript>

    </div>

  </Layout>
)

export default InjectionTrainingPatientEsHcpPpl
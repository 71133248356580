import React, { useEffect } from "react"

import Layout from "../../../components/hcp-bbs-layout"
import Seo from "../../../components/seo"
import Button from "../../../components/button"
import Callout from "../../../components/callout"

import DiagnosisTable from "../../../components/diagnosis-table"
import DiagnosisVideo from "../../../components/diagnosis-video"
import VideoTranscript from "../../../components/video-transcript"
import DiagnosisVideoTranscript from "../../../components/template-partials/hcp/bbs/diagnosis-video-transcript"
import KeyIdentifiableFeaturesTable from "../../../components/key-identifiable-features-table"

import IconLightbulb from "../../../images/icon-lightbulb.png"
import IconClinialSupport from "../../../images/icon-clinical-support.png"
import IconLabDoor from "../../../images/icon-lab-door.png"
import IconSwabKit from "../../../images/icon-swab-kit-3x.png"
import IcdIcon from "../../../images/icons/icd-icon.png"

const Diagnosis = () => {

  // Function to handle smooth scrolling to the target element with an offset
  const smoothScrollTo = (target, offset) => {
    const targetElement = document.querySelector(target);
    if (!targetElement) return;

    const startPosition = window.pageYOffset;
    const targetPosition = targetElement.getBoundingClientRect().top + startPosition - offset;
    const distance = targetPosition - startPosition;
    const duration = 800; // Change the duration (in milliseconds) to adjust the scroll speed

    let start = null;
    function animation(currentTime) {
      if (start === null) start = currentTime;
      const timeElapsed = currentTime - start;
      const scrollStep = Math.min(distance, (timeElapsed / duration) * distance);

      window.scrollTo(0, startPosition + scrollStep);

      if (timeElapsed < duration) requestAnimationFrame(animation);
    }

    requestAnimationFrame(animation);
  };

  // Function to handle smooth scroll when the link is clicked
  const handleScrollClick = (event) => {
    event.preventDefault();
    const target = event.currentTarget.getAttribute('href');
    const offset = 200; // Set the desired offset here (200px in this case)
    smoothScrollTo(target, offset);
  };

  useEffect(() => {
    document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
      anchor.addEventListener('click', handleScrollClick);

      // Clean up event listener on unmount
      return () => {
        anchor.removeEventListener('click', handleScrollClick);
      };
    });
  }, []);
  
  const scrollToTop = () => {
    if (typeof window !== 'undefined') {
      window.scrollTo({
        top: 0,
        behavior: 'smooth', // Use 'auto' for immediate scroll without smooth animation
      });
    }
  };

  return (
    <Layout>
      <Seo
        title="BBS Diagnosis | IMCIVREE® (setmelanotide) injection | For HCPs"
        description="Understand the factors to consider when diagnosing BBS, a rare genetic disease of obesity. Please see full Prescribing Information for Important Safety Information."
        ogTitle="BBS Diagnosis | IMCIVREE® (setmelanotide) injection"
        ogDesc="Learn about diagnosing the disease that IMCIVREE® (setmelanotide) treats. Please see full Prescribing Information for Important Safety Information."
      />
      <div className="content-block" style={{ paddingTop: "4rem" }}>
        <div className="row">
          <div className="column">
            <h1 className="h1">Several factors should be considered when clinically diagnosing BBS</h1>
            <h2 className="h2">Look at your patient's complete presentation</h2>
          </div>
        </div>

        <div className="large mb-1 md_mb-2">
          <DiagnosisTable />
        </div>

        <div className="row">
          <div className="columns og small-12 medium-9 d-flex align-middle md_pl-0">
          <img src={IcdIcon} style={{paddingRight: '.5rem', width: '124px', float: 'left'}} alt="Announcing the ICD-10 code for BBS - Q87.83 - effective as of October 1, 2023" />
            <p className="fw-bold color-gray" style={{ margin: "0 0 0 1rem", paddingRight: "1rem", lineHeight: "1.2rem" }} >
              Announcing the ICD-10 code for BBS&mdash;<span className="color-teal fw-bold">Q87.83</span>&mdash;effective as of October 1, 2023
            </p>
          </div>
        </div>
      </div>

        <div className="content-block md_pb-0 md_pt-2" id="variable-phenotype">
          <div className="row">
            <div className="column small-12 text-left">
              <h1 className="h1 mb-4 lg_mb-1">BBS has a highly variable phenotype with key identifiable features<sup>2</sup></h1>
                <h2 className="h2">
                    BBS is clinically and genetically diverse, so not all people
                    with BBS will present the same way or with all of these
                    features<sup>1,3</sup>
                </h2>
                <p className="text-center hide-for-medium">&larr; Swipe left or right to view &rarr;</p>                
            </div>
            <div className="column small-12 text-left bbs-key-id-table-box">
              <KeyIdentifiableFeaturesTable />
            </div>
          </div>

        <div className="row mt-3 md_mt-3">
          <div className="column">
            <h2 className="h1">Diagnosing BBS in your practice</h2>
            <h3 className="h2">Recognize the various clinical manifestations of BBS to accelerate a diagnosis for your pediatric and adult patients</h3>
          </div>
        </div>

        <DiagnosisVideo />
        <VideoTranscript>
          <DiagnosisVideoTranscript />
        </VideoTranscript>

          <div className="row mt-2 md_mt-4" id="extensive-genetics">
              <div className="column small-12 text-left">
                <h1 className="h1 mb-1 lg_mb-1">
                  Uncovering Rare Obesity<sup>&reg;</sup> can help support a BBS diagnosis
                </h1>
                <h2 className="h2" style={{ marginBottom: `0px!important` }}>
                  A no-charge,* extensive genetic testing program for MC4R pathway diseases
                </h2>
              </div>
              <div
                className="column small-12 text-left"
                style={{ margin: `3rem auto` }}
              >
                <div className="row">
                  {/* Left side column */}
                  <div className="columns small-12 large-8">
                    <div className="row" style={{ gap: `30px` }}>
                      <div className="columns small-12">
                        <div
                          className="row"
                          style={{
                            gap: `20px`,
                            minHeight: `210px`,
                            alignItems: `center`,
                          }}
                        >
                          <div className="columns small-12 large-3">
                            <img
                              src={IconLightbulb}
                              alt="Lightbulb icon"
                              style={{ display: `block`, margin: `0 auto` }}
                            />
                          </div>
                          <div className="columns small-12 large-8 d-flex flex-dir-column align-center">
                            <h2
                              className="jost-semibold color-gray"
                              style={{
                                fontSize: `1.5rem`,
                                lineHeight: `2rem`,
                              }}
                            >
                              Extensive panel offers broad insights<sup>1</sup>
                            </h2>
                            <p className="color-gray">
                              More than 20 genes have been found to be
                              associated with BBS. The gene panel includes 79
                              genes and 1 chromosome region, reflective of
                              nearly all of the most frequently tested genes
                              associated with obesity.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="columns small-12 mt-2 lg_mt-0">
                        <div
                          className="row"
                          style={{
                            gap: `20px`,
                            minHeight: `210px`,
                            alignItems: `center`,
                          }}
                        >
                          <div className="columns small-12 large-3">
                            <img
                              src={IconClinialSupport}
                              alt="HCP icon"
                              style={{ display: `block`, margin: `0 auto` }}
                            />
                          </div>
                          <div className="columns small-12 large-8 d-flex flex-dir-column align-center">
                            <h2
                              className="jost-semibold color-gray"
                              style={{
                                fontSize: `1.5rem`,
                                lineHeight: `2rem`,
                              }}
                            >
                              Tailored support for results interpretation
                            </h2>
                            <p className="color-gray">
                              Access to a geneticist to help interpret results
                              and licensed genetic counselors for your patients
                              are available. Services are provided through
                              third-party partners.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="columns small-12 mt-2 lg_mt-0">
                        <div
                          className="row"
                          style={{
                            gap: `20px`,
                            minHeight: `210px`,
                            alignItems: `center`,
                          }}
                        >
                          <div className="columns small-12 large-3">
                            <img
                              src={IconLabDoor}
                              alt="Door icon"
                              style={{ display: `block`, margin: `0 auto` }}
                            />
                          </div>
                          <div className="columns small-12 large-8 d-flex flex-dir-column align-center">
                            <h2
                              className="jost-semibold color-gray"
                              style={{
                                fontSize: `1.5rem`,
                                lineHeight: `2rem`,
                              }}
                            >
                              Testing conducted by a laboratory partner
                            </h2>
                            <p className="color-gray">
                              DNA testing is conducted by PreventionGenetics, a
                              CLIA-accredited clinical laboratory.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Right side content */}
                  <div className="columns small-12 large-4">
                    <div className="row h-100">
                      <div className="columns small-12 mt-4 lg_mt-0">
                        <img
                          src={IconSwabKit}
                          alt="Swab kit icon"
                          style={{
                            display: `block`,
                            margin: `0 auto`,
                            minWidth: `274px`,
                            maxWidth: `274px`,
                          }}
                        />
                        <p
                          className="footnote text-center"
                          style={{ marginTop: `1rem` }}
                        >
                          Blood and OCD-100 buccal swab
                          <br />
                          sample collection kits are available.
                        </p>
                      </div>
                      <div class="columns small-2">&nbsp;</div>
                      <div className="columns small-12 medium-10 mt-4 lg_mt-6">
                        <Callout
                          fullWidth
                          className="light-teal right text-left"
                        >
                          <p style={{ paddingBottom: '5px' }}>
                            For more information about the genetic testing
                            program, visit{" "}
                            <a
                              href="https://www.uncoveringrareobesity.com"
                              target="_blank"
                              className="color-white color-white-important underline"
                            >
                              UncoveringRareObesity.com
                            </a>
                          </p>
                        </Callout>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
      </div>

      <div className="content-block">
        <p className="footnote hanging-ref mb-0 md_mb-0">
          *Rhythm Pharmaceuticals covers the cost of the test and
          provides sample collection kits. Patients are responsible for
          office visit, sample collection, or other costs.
        </p>
      </div>

      <div className="content-block text-center pt-1 md_pt-0">
          <Button copy="See an overview of BBS" url="/hcp/bbs/overview/" />
          <div className="text-left mt-2 md_mt-2">
            <p className="footnote">
              CLIA=Clinical Laboratory Improvement Amendments.
            </p>
          </div>
          <div className="references footnote text-left mt-1 md_mt-1">
            <strong>References: 1.</strong> Forsythe E et al. <em>Front Pediatr.</em> 2018;6:23. doi:10.3389/fped.2018.00023. <strong>2.</strong> Forsythe E et al. <em>Eur J Hum Genet.</em> 2013;21(1):8-13. <strong>3.</strong> Manara E et al. <em>Ital J Pediatr.</em> 2019;45(1):72. <strong>4.</strong> Forsyth R et al. Bardet-Biedl syndrome overview. In: Adam MP et al, eds. <em>GeneReviews<sup>&reg;</sup>.</em> University of Washington; 2003. Updated July 23, 2020. Accessed August 11, 2023. <strong>5.</strong> Khan OA et al. <em>Cureus.</em> 2019;11(2):e4114. <strong>6.</strong> Agrawal H et al. <em>Pediatr Rev.</em> 2018;39(5):e21-e23. <strong>7.</strong> Vlahovic AM et al. <em>Pediatric and Adolescent Plastic Surgery for the Clinician.</em> Springer;2017:89-105. <strong>8.</strong> Putoux A et al. <em>Pediatr Nephrol.</em> 2012;27(1):7-15. <strong>9.</strong> Sherafat-Kazemzadeh R et al. <em>Pediatr Obes.</em> 2013;8(5):e64-e67. doi:10.1111/j.2047-6310.2013.00182.x. <strong>10.</strong> Pomeroy J et al. <i>Pediatr Obes</i>. 2021;16(2):e12703. <strong>11.</strong> Katsanis N et al. <em>Hum Mol Genet.</em> 2001;10(20):2293-2299. <strong>12.</strong> Eneli I et al. <em>Appl Clin Genet.</em> 2019;12:87-93. <strong>13.</strong> Beales PL et al. <i>J Med Genet</i>. 1999;36(6):437-446. <strong>14.</strong> Weihbrecht K et al. <em>Med Res Arch.</em> 2017;5(9):10.18103/mra.v5i9.1526. doi:10.18103/mra.v5i9.1526.
          </div>
      </div>
    </Layout>
  )
}

export default Diagnosis

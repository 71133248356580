import React from "react"

import Layout from "../../../components/hcp-ppl-layout"
import Seo from "../../../components/seo"
import Button from "../../../components/button"
import Tabs from "../../../components/template-partials/global-components/tabs/tabs"
import Callout from "../../../components/callout"

import IconDiabetes from "../../../images/icon-diabetes.png"
import IconHyperphagia from "../../../images/icon-hyperphagia.png"
import IconUpsetStomach from "../../../images/icon-upset-stomach.png"
import IconKidneyDisease from "../../../images/icon-kidney-disease.png"
import IconGenital from "../../../images/icon-genital.png"

import IconLightbulb from "../../../images/icon-lightbulb.png"
import IconClinialSupport from "../../../images/icon-clinical-support.png"
import IconLabDoor from "../../../images/icon-lab-door.png"
import IconSwabKit from "../../../images/icon-swab-kit-3x.png"

const Overview = () => {
  return (
    <Layout>
      <Seo
        title="PPL Diseases Overview | IMCIVREE® (setmelanotide) injection | For HCPs"
        description="POMC, PCSK1, and LEPR deficiency are all rare autosomal recessive disorders that lead to hyperphagia and early-onset, severe obesity. Please see full Prescribing Information and Important Safety Information."
        ogTitle="Disease Overview | IMCIVREE® (setmelanotide) injection"
        ogDesc="Learn about the diseases that IMCIVREE® (setmelanotide) treats. Please see full Prescribing Information for Important Safety Information."
      />

      <div id="hcp-ppl-overview" className="content-block">
        <h1 className="h1 h1--sm-mobile lg_mt-1 md_mb-3 mb-2">
          POMC, PCSK1, and LEPR deficiency are all rare autosomal recessive
          disorders that lead to hyperphagia and early-onset, severe obesity<sup>1,2</sup>
        </h1>

        <div className="reasons row align-spaced">
          <div className="column small-7 medium-3 large-2 text-center">
            <div className="border-0101">
              <div className="uppercase">POMC</div>
              <div>deficiency</div>
            </div>
            <p className="ignore-resize mt-1 mb-2 md_mt-1">
              Proopiomelanocortin deficiency
            </p>
          </div>
          <div className="column small-7 medium-3 large-2 text-center">
            <div className="border-0101">
              <div className="uppercase">PCSK1</div>
              <div>deficiency</div>
            </div>
            <p className="ignore-resize mt-1 mb-2 md_mt-1">
              Proprotein convertase subtilisin/kexin type 1 deficiency
            </p>
          </div>
          <div className="column small-7 medium-3 large-2 text-center">
            <div className="border-0101">
              <div className="uppercase">LEPR</div>
              <div>deficiency</div>
            </div>
            <p className="ignore-resize mt-1 mb-2 md_mt-1">
              Leptin receptor deficiency
            </p>
          </div>
        </div>

        <div className="row lg_mt-1 mb-0">
          <div className="column small-12">
            <h2 className="h2 ignore-resize lg_mb-0">
              POMC, PCSK1, and LEPR deficiency impact various systems throughout
              the body<sup>1,3-9</sup>
            </h2>
          </div>
        </div>

        <div className="row og">
          <div className="columns og">
            <Tabs className=" tabs--hcp">
              <Tabs.Pane name={
                  <>
                  POMC deficiency<sup>1,3-5,9</sup>
                  </>
                }
                key="1"
              >
                <div className="row og md_mb-1 lg_mb-1 mt-2 md_mt-2">
                  <div className="column og small-12 large-3">
                    <div className="card">
                      <figure>
                        <img src={IconHyperphagia} alt="Brain icon" />
                      </figure>
                      <div className="card-body">
                        <p className="color-teal">
                          <strong>Brain</strong>
                        </p>
                        <ul className="neon-green-bullets">
                          <li>Hyperphagia and consequent obesity</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="column og small-12 large-3 pt-2">
                    <div className="card">
                      <figure>
                        <img src={IconDiabetes} className="hcp-ppl-endo" alt="Endocrine icon" />
                      </figure>
                      <div className="card-body">
                        <p className="color-teal">
                          <strong>Endocrine</strong>
                        </p>
                        <ul className="neon-green-bullets">
                          <li>Adrenal insufficiency</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="column og small-12 large-6 pt-1">
                    <p>
                      <b>Additional clinical features of POMC may include:</b>
                    </p>
                    <ul className="neon-green-bullets">
                      <li>
                        <b>Endocrine:</b> Hypothyroidism, adolescent-onset
                        growth hormone deficiency, hypoglycemia
                      </li>
                      <li>
                        <b>Reproductive:</b> Hypogonadotropic hypogonadism
                      </li>
                      <li>
                        <b>Physical:</b> Red hair and light skin pigmentation
                      </li>
                      <li>
                        <b>Hepatic:</b> Liver failure
                      </li>
                    </ul>
                  </div>
                </div>
              </Tabs.Pane>
              <Tabs.Pane
                name={
                  <>
                  PCSK1 deficiency<sup>1,3,6</sup>
                  </>
                }
                key="2"
              >
                <div className="row og md_mb-1 lg_mb-1 space mt-2 md_mt-2">
                  <div className="column og small-12 large-expand">
                    <div className="card">
                      <figure>
                        <img src={IconHyperphagia} alt="Brain icon" />
                      </figure>
                      <div className="card-body">
                        <p className="color-teal">
                          <strong>Brain</strong>
                        </p>
                        <ul className="neon-green-bullets">
                          <li>Hyperphagia and consequent obesity</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="column og small-12 large-expand pt-2">
                    <div className="card">
                      <figure>
                        <img src={IconUpsetStomach} alt="Digestive icon" />
                      </figure>
                      <div className="card-body">
                        <p className="color-teal">
                          <strong>Digestive</strong>
                        </p>
                        <ul className="neon-green-bullets">
                          <li>
                            GI symptoms, including postnatal diarrhea within the
                            first weeks of life
                          </li>
                          <li>Failure to thrive in infancy</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="column og small-12 large-expand pt-2">
                    <div class="card">
                      <figure>
                        <img src={IconKidneyDisease} style={{ width: `132px`, height: `107px` }} alt="Kidney icon" />
                      </figure>
                      <div className="card-body">
                        <p className="color-teal">
                          <strong>Kidneys</strong>
                        </p>
                        <ul className="neon-green-bullets">
                          <li>Metabolic acidosis</li>
                          <li>Polydipsia/polyuria</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="column og small-12 large-expand pt-2 md_px-0">
                    <div className="card">
                      <figure>
                        <img src={IconDiabetes} className="hcp-ppl-endo" alt="Endocrine icon" />
                      </figure>
                      <div className="card-body">
                        <p className="color-teal">
                          <strong>Endocrine</strong>
                        </p>
                        <ul className="neon-green-bullets">
                          <li>Abnormal glucose homeostasis</li>
                          <li>Hypothyroidism</li>
                          <li>Hypocortisolism</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="row og md_mb-1 lg_mb-1">
                  <div className="column og small-12 large-7 right mt-3">
                    <p>
                      <b>Additional clinical features of PCSK1 may include:</b>
                    </p>
                    <ul className="neon-green-bullets">
                      <li>
                        <b>Endocrine</b>: Hypothyroidism, hypocortisolism
                      </li>
                      <li>
                        <b>Other</b>: Failure to thrive in infancy
                      </li>
                    </ul>
                  </div>
                </div> */}
              </Tabs.Pane>
              <Tabs.Pane
                name={
                  <>
                  LEPR deficiency<sup>1,7-9</sup>
                  </>
                }
                key="3"
              >
                <div className="row og md_mb-1 lg_mb-1 mt-2 md_mt-2">
                  <div className="column og small-12 large-3">
                    <div className="card">
                      <figure>
                        <img src={IconHyperphagia} alt="Brain icon" />
                      </figure>
                      <div className="card-body">
                        <p className="color-teal">
                          <strong>Brain</strong>
                        </p>
                        <ul className="neon-green-bullets">
                          <li>Hyperphagia and consequent obesity</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="column og small-12 large-3 pt-2">
                    <div className="card">
                      <figure>
                        <img src={IconDiabetes} className="hcp-ppl-endo" alt="Endocrine icon" />
                      </figure>
                      <div className="card-body">
                        <p className="color-teal">
                          <strong>Endocrine</strong>
                        </p>
                        <ul className="neon-green-bullets">
                          <li>Hyperinsulinemia</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="column og small-12 large-3 pt-2">
                    <div className="card">
                      <figure>
                        <img src={IconGenital} alt="Reproductive icon" />
                      </figure>
                      <div className="card-body">
                        <p className="color-teal">
                          <strong>Reproductive</strong>
                        </p>
                        <ul className="neon-green-bullets">
                          <li>Hypogonadotropic hypogonadism</li>
                          <li>Delayed puberty</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="column og small-12 large-3 pt-1">
                    <p>
                      <b>Additional clinical features of LEPR may include:</b>
                    </p>
                    <ul className="neon-green-bullets">
                      <li>
                          <b>Endocrine</b>: Diabetes
                      </li>
                      <li>Frequent infections</li>
                    </ul>
                  </div>
                </div>
              </Tabs.Pane>
            </Tabs>
          </div>
        </div>

        <div className="row">
          <div className="column small-12 text-left mt-1 md_mt-2">
            <Callout left className="light-teal left text-left">
              <p>
                These conditions can be difficult to diagnose based solely on
                clinical manifestations, but genetic testing may be able to
                help. For more information about genetic testing for POMC,
                PCSK1, and LEPR deficiency, please visit{" "}
                <a
                  href="https://www.uncoveringrareobesity.com"
                  target="_blank"
                  className="color-white color-white-important underline"
                >
                  UncoveringRareObesity.com
                </a>
              </p>
            </Callout>
          </div>
        </div>

        <div className="row mt-1 mb-1 md_mt-5">
          <div className="column small-12 text-left">
            <h1 className="h1 mb-2 lg_mb-1">Uncovering Rare Obesity<sup>®</sup> can help support a diagnosis of POMC, PCSK1, or LEPR deficiency</h1>
            <h2 className="h2 ignore-resize lg_mb-1"><strong>A no-charge,* extensive genetic testing program for MC4R pathway diseases</strong></h2>

          </div>
          <div
            className="column small-12 text-left"
            style={{ margin: `1.5rem auto 3rem` }}
          >
            <div className="row">
              {/* Left side column */}
              <div className="columns small-12 large-8">
                <div className="row" style={{ gap: `30px` }}>
                  <div className="columns small-12">
                    <div
                      className="row"
                      style={{
                        gap: `20px`,
                        minHeight: `210px`,
                        alignItems: `center`,
                      }}
                    >
                      <div className="columns small-12 large-3">
                        <img
                          src={IconLightbulb}
                          alt="Lightbulb icon"
                          style={{ display: `block`, margin: `0 auto` }}
                        />
                      </div>
                      <div className="columns small-12 large-8">
                        <h2
                          className="jost-semibold"
                          style={{
                            color: `#4B4F54`,
                            fontSize: `20px`,
                            lineHeight: `2rem`,
                          }}
                        >
                          Extensive panel offers broad insights
                        </h2>
                        <p>
                          The gene panel includes 79 genes and 1 chromosome
                          region, reflective of nearly all of the most
                          frequently tested genes associated with obesity.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="columns small-12 mt-2 lg_mt-0">
                    <div
                      className="row"
                      style={{
                        gap: `20px`,
                        minHeight: `210px`,
                        alignItems: `center`,
                      }}
                    >
                      <div className="columns small-12 large-3">
                        <img
                          src={IconClinialSupport}
                          alt="HCP icon"
                          style={{ display: `block`, margin: `0 auto` }}
                        />
                      </div>
                      <div className="columns small-12 large-8">
                        <h2
                          className="jost-semibold"
                          style={{
                            color: `#4B4F54`,
                            fontSize: `20px`,
                            lineHeight: `2rem`,
                          }}
                        >
                          Tailored support for results interpretation
                        </h2>
                        <p>
                          Access to a geneticist to help interpret results and
                          licensed genetic counselors for your patients are
                          available. Services are provided through third-party
                          partners.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="columns small-12 mt-2 lg_mt-0">
                    <div
                      className="row"
                      style={{
                        gap: `20px`,
                        minHeight: `210px`,
                        alignItems: `center`,
                      }}
                    >
                      <div className="columns small-12 large-3">
                        <img
                          src={IconLabDoor}
                          alt="Door icon"
                          style={{ display: `block`, margin: `0 auto` }}
                        />
                      </div>
                      <div className="columns small-12 large-8">
                        <h2
                          className="jost-semibold"
                          style={{
                            color: `#4B4F54`,
                            fontSize: `20px`,
                            lineHeight: `2rem`,
                          }}
                        >
                          Testing conducted by a laboratory partner
                        </h2>
                        <p>
                          DNA testing is conducted by PreventionGenetics, a
                          CLIA-accredited clinical laboratory.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Right side content */}
              <div className="columns small-12 large-4">
                <div className="row h-100">
                  <div className="columns small-12 mt-4 lg_mt-0">
                    <img
                      src={IconSwabKit}
                      alt="Swab kit icon"
                      style={{
                        display: `block`,
                        margin: `0 auto`,
                        minWidth: `274px`,
                        maxWidth: `274px`,
                      }}
                    />
                    <p
                      className="footnote text-center"
                      style={{ marginTop: `1rem` }}
                    >
                      Blood and OCD-100 buccal swab
                      <br />
                      sample collection kits are available.
                    </p>
                  </div>
                  <div class="columns small-2">&nbsp;</div>
                  <div className="columns small-10 mt-4 lg_mt-6">
                    <Callout fullWidth className="light-teal right text-left">
                      <p>
                        For more information about the genetic testing program, visit{" "}
                        <a
                          href="https://www.uncoveringrareobesity.com"
                          target="_blank"
                          className="color-white color-white-important underline"
                        >
                          Uncovering{" "}<span style={{'marginLeft': '-0.3em'}}></span>Rare{" "}<span style={{'marginLeft': '-0.3em'}}></span><span className="break nowrap">Obesity.com</span>
                        </a>
                      </p>
                    </Callout>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="text-left md_mb-3">
            <p className="footnote hanging-ref ">
              *Rhythm Pharmaceuticals covers the cost of the test and provides
              sample collection kits. Patients are responsible for office visit,
              sample collection, or other costs.
            </p>
          </div>
        </div>

        <div className="text-center mt-3 md_mt-2">
          <Button
            textLeft
            copy="Discover the different types of obesity"
            url="/hcp/ppl/types-of-obesity/"
          />
        </div>
        <div className="row mt-3 md_mt-3 lg_mt-3">
          <div className="small-12 large-12 columns">
            <p className="references" style={{ fontWeight: "400" }}>
              CLIA=Clinical Laboratory Improvement Amendments;
              GI=gastrointestinal; LEPR=leptin receptor; MC4R=melanocortin-4
              receptor; PCSK1=proprotein convertase subtilisin/kexin type 1;
              POMC=proopiomelanocortin.
            </p>
            <p className="references" style={{ fontWeight: "400" }}><b>References:</b> <b>1.</b> Styne DM et al. <em>J Clin Endocrinol Metab</em>. 2017;102(3):709-757. <b>2.</b> Malhotra S et al. <em>J Pediatr Genet</em>. 2021;10(3):194-204. doi:10.1055/s-0041-1731035. <b>3.</b> Coll AP et al. <em>J Clin Endocrinol Metab</em>. 2004;89(6):2557-2562. doi:10.1210/jc.2004-0428. <b>4.</b> Bereket A et al. <em>Obes Rev</em>. 2012;13(9):780-798. doi:10.1111/j.1467-789X.2012.01004.x. <b>5.</b> Gregoric N et al. <em>Front Endocrinol (Lausanne)</em>. 2021;12:689387.bdoi:10.3389/fendo.2021.689387. <b>6.</b> Stijnen P et al. <em>Endocr Rev</em>. 2016;37(4):347-371. doi:10.1210/er.2015-1117. <b>7.</b> Farooqi IS et al. <em>N Engl J Med</em>. 2007;356(3):237-247. doi:10.1056/NEJMoa063988. <b>8.</b> Farooqi IS et al. <em>J Endocrinol</em>. 2014;223(1):T63-T70. doi:10.1530/JOE-14-0480. <b>9.</b> Elias CF et al. <em>Cell Mol Life Sci</em>. 2013;70(5):841-862. doi:10.1007/s00018-012-1095-1.</p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Overview

import React from "react"
import parse from "html-react-parser"

const Callout = props => {

  let w = 'medium-12 large-8';
    if (props.fullWidth) {
      w = 'medium-12 large-12';
    } else if (props.qolWidth) {
      w = 'medium-12 large-10';
  }
  return (
    <div className="row">
      <div
        className="medium-2 large-4"
        style={props.right ? { display: `block` } : { display: `none` }}
      >
        &nbsp;
      </div>
      <div
        className={`small-12 ${ w }`}
      >
        <div
          className={
            "global-callout-container-2 content-block row " + props.className
          }
          style={props.style}
          id={props.id}
        >
          <div className={"global-callout-content column " + props.className}>
            {props.children}
            {/* {(() => {
              if (
                props.firstColumn !== null &&
                props.firstColumn !== undefined
              ) {
                return (
                  <div className="row">
                    <div className="column small-12 medium-3 text-center extra-left align-center align-self-middle">
                      {parse(props.firstColumn)}
                    </div>
                    <div className="column small-12 medium-9 align-middle">
                      <p
                        className="md_pl-1 md_pr-2"
                        dangerouslySetInnerHTML={{ __html: props.copy }}
                      />
                    </div>
                  </div>
                )
              } else {
                return <p dangerouslySetInnerHTML={{ __html: props.copy }} />
              }
            })()} */}
          </div>
        </div>
      </div>
      <div
        className="show-for-medium medium-2 large-4"
        style={props.left ? { display: `block` } : { display: `none` }}
      >
        &nbsp;
      </div>
    </div>
  )
}

export default Callout

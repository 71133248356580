import * as React from "react"

import Layout from "../../../components/hcp-ppl-layout"
import Seo from "../../../components/seo"
import Button from "../../../components/button"
import Callout from "../../../components/callout"
import Modal from "../../../components/modal"
import HangingRef from "../../../components/hanging-ref"

import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import SideEffect1Img from "../../../images/side-effect-hcp-ppl-1.png"
import SideEffect2Img from "../../../images/side-effect-hcp-ppl-2.png"
import SideEffect3Img from "../../../images/side-effect-hcp-ppl-3.png"
import SideEffect4Img from "../../../images/side-effect-hcp-ppl-4.png"

import SideEffect1ImgMobile from "../../../images/side-effect-hcp-ppl-1-mobile.png"
import SideEffect2ImgMobile from "../../../images/side-effect-hcp-ppl-2-mobile.png"
import SideEffect3ImgMobile from "../../../images/side-effect-hcp-ppl-3-mobile.png"
import SideEffect4ImgMobile from "../../../images/side-effect-hcp-ppl-4-mobile.png"

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
}

const SafetyProfile = () => (
  <Layout>
    <Seo
      title="Safety Profile | IMCIVREE® (setmelanotide) injection | For HCPs"
      description="View the safety and tolerability profile of IMCIVREE® (setmelanotide) in POMC, PCSK1, or LEPR deficiency. Please see full Prescribing Information for Important Safety Information."
      ogTitle="Safety Profile | IMCIVREE® (setmelanotide) injection"
      ogDesc="View the safety and tolerability profile of IMCIVREE® (setmelanotide). Please see full Prescribing Information for Important Safety Information."
    />
    <div className="content-block pb-0 md_pb-0" id="hcp-ppl-safety-profile">
      <div className="row">
        <div className="columns small-12">
          <h1 className="h1">
            IMCIVREE has a well-established safety and tolerability profile<sup>1,2</sup>
          </h1>
        </div>
        <div className="columns small-12">
          <h2 class="h2 ignore-resize md_mb-1">
            Adverse reactions occurring in ≥23% of people treated with IMCIVREE
            in open-label clinical studies of 1-year duration for POMC, PCSK1,
            or LEPR deficiency<sup>1,2</sup>
          </h2>
        </div>
      </div>

      <div className="row og">
        <div className="column og small-12 large-6 mt-1 md_mt-1 px-0  md_pr-0">
          <table
            cellSpacing="0"
            cellPadding="0"
            className="hcp-ppl-safety-profile-adverse-reaction-table"
          >
            <tr>
              <td className="color-neon-green-bg-light text-color-white table-header-cell no-border-bottom">
                Adverse reaction<sup>1</sup>
              </td>
              <td className="table-header-cell text-center color-teal-bg border-left-white no-border-bottom lg_pl-0 lg_pr-0">
                <strong>N=27 (%)</strong>
              </td>
            </tr>
            <tr>
              <td>Injection site reaction*</td>
              <td className="text-center border-left-teal color-teal-light-bg">
                <strong>96%</strong>
              </td>
            </tr>
            <tr>
              <td>
                Skin hyperpigmentations<sup>&dagger;</sup>
              </td>
              <td className="text-center border-left-teal color-teal-light-bg">
                <strong>78%</strong>
              </td>
            </tr>
            <tr>
              <td>Nausea</td>
              <td className="text-center border-left-teal color-teal-light-bg">
                <strong>56%</strong>
              </td>
            </tr>
            <tr>
              <td>Headache</td>
              <td className="text-center border-left-teal color-teal-light-bg">
                <strong>41%</strong>
              </td>
            </tr>
            <tr>
              <td>Diarrhea</td>
              <td className="text-center border-left-teal color-teal-light-bg">
                <strong>37%</strong>
              </td>
            </tr>
            <tr>
              <td>
                Abdominal pain<sup>&Dagger;</sup>
              </td>
              <td className="text-center border-left-teal color-teal-light-bg">
                <strong>33%</strong>
              </td>
            </tr>
          </table>
        </div>
        <div className="column og small-12 large-6 mt-0 lg_mt-1 px-0 md_pl-0">
          <table
            cellSpacing="0"
            cellPadding="0"
            className="hcp-ppl-safety-profile-adverse-reaction-table"
          >
            <tr className="show-for-large">
              <td className="table-header-cell color-neon-green-bg-light text-color-white border-left-white-1 no-border-bottom">
                Adverse reaction<sup>1</sup>
              </td>
              <td className="table-header-cell text-center color-teal-bg border-left-white no-border-bottom lg_pl-0 lg_pr-0">
                <strong>N=27 (%)</strong>
              </td>
            </tr>
            <tr>
              <td>Back pain</td>
              <td className="text-center border-left-teal color-teal-light-bg">
                <strong>33%</strong>
              </td>
            </tr>
            <tr>
              <td>Fatigue</td>
              <td className="text-center border-left-teal color-teal-light-bg">
                <strong>30%</strong>
              </td>
            </tr>
            <tr>
              <td>Vomiting</td>
              <td className="text-center border-left-teal color-teal-light-bg">
                <strong>30%</strong>
              </td>
            </tr>
            <tr>
              <td>
                Depression<sup>&sect;</sup>
              </td>
              <td className="text-center border-left-teal color-teal-light-bg">
                <strong>26%</strong>
              </td>
            </tr>
            <tr>
              <td>Upper respiratory tract infection</td>
              <td className="text-center border-left-teal color-teal-light-bg">
                <strong>26%</strong>
              </td>
            </tr>
            <tr>
              <td>
                Spontaneous penile erection<sup>||</sup>
              </td>
              <td className="text-center border-left-teal color-teal-light-bg">
                <strong>23%</strong>
              </td>
            </tr>
          </table>
        </div>
      </div>

      <div className="mt-1 md_mt-3 mb-2 md_mb-3">
        <div>
          <HangingRef
            isAsterisk
            mb0
            symbol="*"
            copy={
              <>
              Includes injection site erythema, pruritus, edema, pain, induration, bruising, hypersensitivity, hematoma, nodule, and discoloration.<sup>1</sup>
              </>
            }
          />
          <HangingRef
            mb0
            symbol={
              <>
                <sup>&dagger;</sup>
              </>
            }
            copy={
              <>
              Includes skin hyperpigmentation, pigmentation disorders, and skin discoloration.<sup>1</sup>
              </>
            }
          />
          <HangingRef
            mb0
            symbol={
              <>
                <sup>&Dagger;</sup>
              </>
            }
            copy={
              <>
              Includes abdominal pain and upper abdominal pain.<sup>1</sup>
              </>
            }
          />
          <HangingRef
            mb0
            symbol={
              <>
                <sup>&sect;</sup>
              </>
            }
            copy={
              <>
              Includes depressed mood.<sup>1</sup>
              </>
            }
          />
          <HangingRef
            symbol={
              <>
                <sup>||</sup>
              </>
            }
            copy={
              <>
              n=13 male patients.<sup>1</sup>
              </>
            }
          />
        </div>
        <ul className="jost-semibold neon-green-bullets">
          <li>
            Reported incidences of nausea and vomiting primarily occurred within
            the first month of treatment, then decreased over time<sup>2</sup>
          </li>
          <li>
            Reported incidences of nausea and vomiting typically resolved within
            a few days in patients with a rare genetic disease of obesity in
            IMCIVREE clinical trials<sup>2</sup>
          </li>
          <li>
            Nausea and vomiting should be managed by dose titration and standard
            care<sup>1</sup>
          </li>
          <li>
            The safety of IMCIVREE has been evaluated in &gt;700 patients over
            ~10 years of clinical trials<sup>3,4</sup>
          </li>
        </ul>
      </div>

      <div className="row align-middle align-justify">
        <div className="columns large-6 medium-8 small-12">
          <Callout fullWidth className="light-teal left">
            <p>
              An extension study evaluating long-term outcomes for patients on
              IMCIVREE is ongoing<sup>5</sup>
            </p>
          </Callout>
        </div>
        <div className="columns og small-offset-0 medium-offset-0 large-offset-0 large-shrink medium-2 small-12">
          <div className="safety-profile-warning-modal-box pt-2 pb-2">
            <Modal id="warning-precautions-hcp-ppl" className="color-black-bg" />
          </div>
        </div>
      </div>
    </div>

    <div className="content-block pt-0 md_pt-0">
      <div className="row md_mt-3 md_mb-1">
        <div className="column small-12">
          <h2 className="h1">
            Hyperpigmentation was common but rarely led to discontinuation<sup>1,6</sup>
          </h2>
        </div>
      </div>

      <div className="row the-carousel" id="hcp-ppl-safety-profile-carousel">
        <div className="column">
          <Slider {...settings}>
            <div className="carousel-items">
              <p className="text-center fw-bold mb-2 md_mb-2">
                Examples of hyperpigmentation<sup>7</sup>
              </p>
              <img
                src={SideEffect1Img}
                className="hide-for-small show-for-medium"
                alt="Examples of hyperpigmentation (before and during treatment with IMCIVREE)"
              />
              <img
                src={SideEffect1ImgMobile}
                className="show-for-small hide-for-medium"
                alt="Examples of hyperpigmentation (before and during treatment with IMCIVREE)"
              />
            </div>

            <div className="carousel-items">
              <p className="text-center fw-bold mb-2 md_mb-2">
                Examples of hyperpigmentation<sup>7</sup>
              </p>
              <img
                src={SideEffect2Img}
                className="hide-for-small show-for-medium"
                alt="Examples of hyperpigmentation (before and during treatment with IMCIVREE)"
              />
              <img
                src={SideEffect2ImgMobile}
                className="show-for-small hide-for-medium"
                alt="Examples of hyperpigmentation (before and during treatment with IMCIVREE)"
              />
            </div>
            <div className="carousel-items">
              <p className="text-center fw-bold mb-2 md_mb-2">
                Examples of hyperpigmentation<sup>7</sup>
              </p>
              <img
                src={SideEffect3Img}
                className="hide-for-small show-for-medium"
                alt="Examples of hyperpigmentation (before and during treatment with IMCIVREE)"
              />
              <img
                src={SideEffect3ImgMobile}
                className="show-for-small hide-for-medium"
                alt="Examples of hyperpigmentation (before and during treatment with IMCIVREE)"
              />
            </div>
            <div className="carousel-items">
              <p className="text-center fw-bold mb-2 md_mb-2">
                Examples of hyperpigmentation<sup>7</sup>
              </p>
              <img
                src={SideEffect4Img}
                className="hide-for-small show-for-medium"
                alt="Examples of hyperpigmentation (before and during treatment with IMCIVREE)"
              />
              <img
                src={SideEffect4ImgMobile}
                className="show-for-small hide-for-medium"
                alt="Examples of hyperpigmentation (before and during treatment with IMCIVREE)"
              />
            </div>
          </Slider>
        </div>
      </div>

      <div className="row md_mt-3">
        <div className="column small-12">
          <ul className="neon-green-bullets mt-3">
            <li className="mb-1">
              <b>
                Changes in skin pigmentation or hair color typically presented
                within the first 2 to 3 weeks of treatment with IMCIVREE<sup>8,9</sup>
              </b>
              <ul className="md_mb-2 mt-1">
                <li className="is-dash">
                  Skin darkening plateaued during the course of treatment and
                  was not dose-dependent<sup>7</sup>
                </li>
                <li className="is-dash">
                  Hyperpigmentation is variable<sup>7</sup>
                </li>
                <li className="is-dash">
                  This effect is reversible upon discontinuation of treatment
                  <sup>1</sup>
                </li>
                <li className="is-dash">
                  Perform a full body skin examination prior to initiation and
                  periodically during treatment with IMCIVREE to monitor
                  pre-existing and new skin pigmentary lesions<sup>1</sup>
                </li>
                <li className="is-dash">
                  Hyperpigmentation is not unexpected given that IMCIVREE also
                  activates the melanocortin-1 receptor, which results in
                  melanin production<sup>1</sup>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>

      {/* <div className="row md_mt-1 mt-1">
        <div className="column small-12">
          <h2 className="h1 large-10 small-12">
            Managing adverse events and injection training
          </h2>
          <p className="h2">
            Hear from physicians who have prescribed IMCIVREE
          </p>
        </div>
      </div> */}

        {/* <ManagingAdverseEventsVideo />
        <VideoTranscript>
          <ManagingAdverseEventsVideoTranscript />
        </VideoTranscript> */}

      <div className="text-center md_mt-2 mt-2 md_mb-3 mb-3">
        <Button
          copy="See dosing and administration"
          url="/hcp/ppl/dosing-administration/"
        />
      </div>
      <p className="footnote md_mt-3 mt-2 ">
        LEPR=leptin receptor; PCSK1=proprotein convertase subtilisin/kexin type
        1; POMC=proopiomelanocortin.
      </p>

      <div className="references md_mt-1 mt-1 md_mb-3 mb-2">
        <b>References:</b> <b>1.</b> IMCIVREE [prescribing information]. Boston, MA. Rhythm Pharmaceuticals, Inc. <b>2.</b> Argente J et al. The Pediatric Endocrine Society Annual Meeting. Poster 155. April 28-May 1, 2022. <b>3.</b> Data on file. Rhythm Pharmaceuticals, Inc. Boston, MA. <b>4.</b> U.S. National Library of Medicine. Identifier: NCT02431442. ClinicalTrials.gov. Accessed August 14, 2023. https://www.clinicaltrials.gov/ct2/show/study/NCT02431442. <b>5.</b> U.S. National Library of Medicine. Identifier: NCT03651765. ClinicalTrials.gov. Accessed August 14, 2023. https://classic.clinicaltrials.gov/ct2/show/NCT03651765. <b>6.</b> Clément K et al. <i>Lancet Diabetes Endocrinol</i>. 2020;8(12):960-970. <b>7.</b> Clément K et al. <i>Lancet Diabetes Endocrinol</i>. [Supplementary appendix] 2020;8(12):960-970.
      </div>
    </div>
  </Layout>
)

export default SafetyProfile

import React, {useEffect, useState} from 'react'
import Helmet from 'react-helmet'
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { StaticQuery, graphql } from "gatsby"
import imcivreeLogo from "../../../../images/imcivree-logo.svg"
import MainLogo from '../../global-components/main-logo'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faEnvelope, faUser, faFileAlt, faArrowDown } from "@fortawesome/free-solid-svg-icons";
import * as Utils from "../../../../helpers/utils"

const Header = ({ data }) => (
  <></>
  )
  export default function MyHeader(props) {
    const [navOpen, setNavOpen] = useState(false)
    const [hasChild, setHasChild] = useState(false)
    const url = typeof window !== "undefined" ? window.location.pathname : ""
    const toggleNav = e => {
      let width = document.body.clientWidth
      if (width <= 1024) {
        e.currentTarget.classList.toggle("isOpen")
        document
          .querySelector(`.main-header__mobile-menu-wrapper`)
          .classList.toggle("isOpen")
        if (navOpen) {
          setNavOpen(false)
        } else {
          setNavOpen(true)
        }
        e.preventDefault()
      } else {
        return true
      }
    }

    const setCookie = (cname, cvalue, exdays) => {
      const d = new Date();
      d.setTime(d.getTime() + (exdays*24*60*60*1000));
      let expires = "expires="+ d.toUTCString();
      document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    const getCookie = cname => {
      let name = cname + "=";
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(';');
      for(let i = 0; i <ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    }

    const initFontSize = () =>{
      // initialize font-size cookie
      if (getCookie("font-size")){
        document
          .querySelector(`html`)
          .className = getCookie("font-size");
      }
    }

    const fontResize = e => {
      if (e){
        setCookie("font-size", e, 1); //expires in 1 day
        initFontSize();
      }
    }

    const toggleActive = e => {
      e.currentTarget.classList.toggle("active")

      //remove active class from dom that's not current target
      let elems = document.querySelectorAll(".active")
      ;[].forEach.call(elems, function (el) {
        if (e.currentTarget !== el) {
          el.classList.remove("active")
        }
      })
      e.stopPropagation()
    }

    const toggleMobileDrawer = e => {
      let width = document.body.clientWidth
      e.currentTarget.parentNode.classList.add("isActive")
      //remove active class from dom that's not current target
      let elems = document.querySelectorAll(".isActive")
      ;[].forEach.call(elems, function (el) {
        if (e.currentTarget.parentNode !== el) {
          el.classList.remove("isActive")
        }
      })
      e.stopPropagation()
      //console.log(e.currentTarget.parentNode)
      if (width <= 1024) {
        let parent = e.currentTarget.parentNode
        let target = e.currentTarget

        //console.log(target)
        if (target.classList.contains("hasChild")) {
          parent.classList.toggle("isOpen")
          e.preventDefault()
        } else {
          document
            .querySelector(`.main-header__mobile-menu-wrapper`)
            .classList.toggle("isOpen")
        }
      } else {
        return true
      }
    }


    // Code to add isActive to nav link if clicked from a CTA button (to hunger-reduction.js specifically)
    const [isEfficacyChildPage, setIsEfficacyChildPage] = useState(false);
      useEffect(() => {
        const delay = setTimeout(() => {
          // Check if the body has the class .efficacy-child-page
          const hasEfficacyChildPageClass = document.body.classList.contains('efficacy-child-page');
          setIsEfficacyChildPage(hasEfficacyChildPageClass);
        }, 500); // 0.5 second delay for page load
        return () => clearTimeout(delay);
      }, []);
    

    <StaticQuery
      query={graphql`
        query SiteTitleQuery3 {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => <Header data={data} {...props} />}
    />

    const setActive = () => {
      let current = window.location.pathname
      let elems = document.querySelectorAll("header ul li > a[href]");
      [].forEach.call(elems, function (el) {
        // if the current path is like this link, make it active
        if (el.getAttribute("href") === current) {
          //console.log(current)
          //console.log(el.getAttribute('href'));
          el.parentNode.classList.add("isActive")
          el.classList.add("isActive")
          let parentclass = el.getAttribute("parentclass")
          if (parentclass) {
            setHasChild(true)
            let parentActive = document.querySelector("." + parentclass)
            parentActive.classList.add("isActive")

          }else{
            setHasChild(false)
          }

        }
      })
    }

    const isiHandler = e => {
      e.preventDefault();
      let scrollByOffset = 0, buttonTarget = document.querySelector('.main-nav-toggle');
      if (buttonTarget.classList.contains('isOpen') === true) {
        toggleNav(e);
        buttonTarget.classList.remove('isOpen')
      };
      scrollByOffset = (scrollByOffset - document.querySelector('header').offsetHeight) - 32;
      document.getElementById('isiContainer').scrollIntoView();
      window.scrollBy(0, scrollByOffset);
    };

    useEffect(() => {
      // check for UTMs
      Utils.utmCheck();
      setActive();
      // initialize font-size cookie
      initFontSize();
      const onClick = e => {
        let elems = document.querySelectorAll(".active")
        ;[].forEach.call(elems, function (el) {
          el.classList.remove("active")
        })
      }
      document.body.addEventListener("click", onClick);
      return () => {
        document.body.removeEventListener("click", onClick)
      }
    }, [])

    useEffect(() => {

      //Add body class when theres a desktop dropdown div to account for the extra padding needed in .container

      //let activeItem = document.getElementById('main-nav__menu').getElementsByClassName('isActive');
      //let checkItem = activeItem[0].getElementsByClassName('has-child');

      //let body = document.getElementsByClassName('container');

      
     },[])

  return (
    <>
      {/* Use helmet to dynamically add a body class*/}
      {navOpen &&
        <Helmet bodyAttributes={{ class: 'hcp-bbs nav-lock'}}/>
      }
      {!navOpen &&
        <Helmet bodyAttributes={{ class: 'hcp-bbs'}}/>
      }
      {hasChild &&
        <Helmet bodyAttributes={{ class: 'hcp-bbs hasChild'}}/>
      }
      {/* Use helmet to dynamically add a body class*/}
      <header className="main-header">
        <div className='row mobile-only'>
          <div className='columns'>
            <ul className='mobile-only-tertiary'>
              <li className="text-center"><a href="https://rhythm-vault-digital-publishing-production.s3.amazonaws.com/IMCIVREEPrescribingInformation.pdf" target="_blank">Prescribing<br/>Information</a></li>
              <li className="text-center"><a onClick={isiHandler}>Important Safety<br/>Information</a></li>
              <li className="text-center"><Link to="/bbs/">Visit<br/>Patient Site</Link></li>
            </ul>
          </div>
        </div>
        <div className="row row--inner main-header__mobile-tray">

          <div className="columns large-2">
            <MainLogo to="/hcp/bbs/" location="header"/>
            <button className="main-nav-toggle hide-for-large" onClick={(e) => toggleNav(e)}>
              <span></span>
              <span></span>
              <span></span>
              <span className="visually-hidden">toggle mobile menu</span>
            </button>
          </div>
          <div className='columns large-10'>
          <div className="utility-nav-wrapper">
              <div className="tertiary-nav desktop-only">
                  <ul className="nav-items">
                    <li className=""><div className="site-label">Site intended for US residents only.</div></li>
                    <li className='slanted'><a href="https://rhythm-vault-digital-publishing-production.s3.amazonaws.com/IMCIVREEPrescribingInformation.pdf" target="_blank">Prescribing Information</a></li>
                    <li className='slanted'><a onClick={isiHandler}>Important Safety Information</a></li>
                    <li className='slanted '><Link to="/bbs/">Visit Patient Site</Link></li>
                    <li className="other-indications" onClick={(e) => toggleActive(e)}>Other Indications
                      <ul className="nav-sub-items">
                      <li><Link to="/hcp/ppl/">POMC, PCSK1, and LEPR deficiency</Link></li>
                      <li><Link to="/hcp/bbs/">Bardet-Biedl syndrome</Link></li>
                    </ul>
                    </li>
                  </ul>
              </div>
              <div className="secondary-nav desktop-only">
                  <ul className="nav-items">
                      {/* <li><Link to="/hcp/bbs/sign-up/"><FontAwesomeIcon icon={faEnvelope}/> Connect with a Patient Education Manager
 <FontAwesomeIcon className="caret" icon={faAngleRight}/></Link></li> */}
                      <li><Link to="/hcp/bbs/sign-up/"><FontAwesomeIcon icon={faUser}/> Contact a Representative <FontAwesomeIcon className="caret" icon={faAngleRight}/></Link></li>
                      <li><a href="/Start_Form.pdf" target="_blank"><FontAwesomeIcon icon={faFileAlt}/> IMCIVREE Prescription Start Form <FontAwesomeIcon className="caret" icon={faArrowDown}/></a></li>
                      <li className="font-size"><span className="small" onClick={(e) => fontResize("gsmall")}>A</span> <span className='pipe'>|</span> <span className="normal" onClick={(e) => fontResize("gmedium")}>A</span> <span className='pipe'>|</span> <span className="large" onClick={(e) => fontResize("glarge")}>A</span></li>
                  </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="row row--inner main-header__mobile-menu-wrapper pb-0">
          <div className="columns main-column">
            <nav className="main-nav">
              <ul id="main-nav__menu" className="main-nav__menu">
                <li className='overview'>
                  <Link to="/hcp/bbs/diagnosis/" className="hasChild" onClick={(e) => toggleMobileDrawer(e)}><span>BBS Diagnosis</span></Link>
                  <div>
                    <div className="main-nav__mega-inner">
                      <div className="row align-middle align-justity">
                        <div className="columns">
                          <ul className="main-nav__child-menu">
                            <li><Link parentclass="overview" to="/hcp/bbs/diagnosis/">BBS diagnosis</Link></li>
                            <li><Link parentclass="overview" to="/hcp/bbs/overview/">BBS overview</Link></li>
                            <li><Link parentclass="overview" to="/hcp/bbs/types-of-obesity/">Types of obesity</Link></li>
                            <li><Link parentclass="overview" to="/hcp/bbs/hyperphagia/">Hyperphagia in BBS</Link></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <Link className="no-child" onClick={(e) => toggleMobileDrawer(e)} to="/hcp/bbs/moa/"><span>Mechanism of Action</span></Link>
                </li>
                {/* <li className='efficacy'> */}
                <li className={`efficacy ${isEfficacyChildPage ? 'isActive' : ''}`}>
                  {/*fix for url problem */}
                  <Link to="/hcp/bbs/study-design/" className={'hasChild'} onClick={(e) => toggleMobileDrawer(e)}><span>Efficacy</span></Link>
                  <div>
                    <div className="main-nav__mega-inner">
                      <div className="row align-middle align-justity">
                        <div className="columns">
                          <ul className="main-nav__child-menu">
                            <li><Link parentclass="efficacy" to="/hcp/bbs/study-design/">Study design</Link></li>
                            <li><Link parentclass="efficacy" to="/hcp/bbs/pediatric-bmi-z-score-reduction/">Pediatric BMI Z-score reduction</Link></li>
                            <li><Link parentclass="efficacy" to="/hcp/bbs/adult-weight-reduction/">Adult weight reduction</Link></li>
                            <li><Link parentclass="efficacy" className={`${isEfficacyChildPage ? 'isActive' : ''}`} to="/hcp/bbs/hunger-reduction/">Hunger reduction</Link></li>
                            <li><Link parentclass="efficacy" to="/hcp/bbs/quality-of-life/">Quality of life</Link></li>

                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>

                <li>
                  <Link className="no-child" onClick={(e) => toggleMobileDrawer(e)} to="/hcp/bbs/safety-profile/"><span>Safety Profile</span></Link>
                </li>
                <li>
                  <Link className="no-child" to="/hcp/bbs/dosing-administration/" onClick={(e) => toggleMobileDrawer(e)}><span>Dosing & Administration</span></Link>
                </li>
                <li>
                  <Link className="no-child" to="/hcp/bbs/prescribing-imcivree/" onClick={(e) => toggleMobileDrawer(e)}><span>Prescribing IMCIVREE</span></Link>
                </li>
                <li className='support'>
                  {/*fix for url problem */}
                  <Link to="/hcp/bbs/patient-support/" className={ 'hasChild'} onClick={(e) => toggleMobileDrawer(e)}><span>Patient Support & Resources</span></Link>
                  <div>
                    <div className="main-nav__mega-inner">
                      <div className="row align-middle align-justity">
                        <div className="columns">
                          <div className="row">
                            <div className="columns small-12 large-expand">
                              <ul className="main-nav__child-menu">
                                <li><Link parentclass="support" to="/hcp/bbs/patient-support/">Patient support</Link></li>
                                <li><Link parentclass="support" to="/hcp/bbs/webinars-downloadable-resources/">Webinars & downloadable resources</Link></li>
                                <li><Link parentclass="support" to="/hcp/bbs/faq/">Frequently asked questions</Link></li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
              <div className='mobile-only-secondary-utility-nav'>
                <ul className='finess'><li className='pb-0'>Other Indications
                    <ul className="nav-sub-items">
                    <li><Link to="/hcp/ppl/">POMC, PCSK1, and LEPR deficiency</Link></li>
                    <li><Link to="/hcp/bbs/">Bardet-Biedl syndrome</Link></li>
                  </ul>
                  </li>
                </ul>
                <ul className='finess'>
                    {/* <li><Link to="/hcp/bbs/sign-up/"><FontAwesomeIcon icon={faEnvelope}/> Connect with a Patient Education Manager<FontAwesomeIcon className="caret" icon={faAngleRight}/></Link></li> */}
                    <li><Link to="/hcp/bbs/sign-up/"><FontAwesomeIcon icon={faUser}/> Contact a Representative <FontAwesomeIcon className="caret" icon={faAngleRight}/></Link></li>
                    <li><a href="/Start_Form.pdf" target="_blank"><FontAwesomeIcon icon={faFileAlt}/> IMCIVREE Prescription Start Form <FontAwesomeIcon className="caret caret--sm" icon={faArrowDown}/></a></li>
                </ul>
                {/*
                <ul>
                    <li><a href="https://rhythm-vault-digital-publishing-production.s3.amazonaws.com/IMCIVREEPrescribingInformation.pdf" target="_blank">Prescribing Information</a></li>
                    <li><a onClick={isiHandler}>Important Safety Information</a></li>
                    <li><Link to="/bbs/">Patient Site</Link></li>
                </ul>
                */}
            </div>
            </nav>
          </div>
        </div>
      </header>
      </>
  )
}
Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"

import Layout from "../../components/ppl-layout"
import Seo from "../../components/seo"
import * as Utils from "../../helpers/utils"

export default function SignUp(props) {
  const [utm, setUTM] = useState("")

  useEffect(() => {
    //check for utm
    setUTM(Utils.getUTM())
  }, [])

  //show/hide diagnosed checkboxes
  const showDiagnosedFields = e => {
    //console.log(e);
    var diagnosedFields = document.querySelector("#diagnosed-fields")
    var diagnosedElement = document.querySelector("#diagnosed")

    // diagnosed checkbox header text
    let optionText1 =
      "<b>Which one of the following rare genetic diseases of obesity have you been diagnosed with?</b>"
    let optionText2 =
      "<b>Which one of the following rare genetic diseases of obesity has your loved one been diagnosed with?</b>"
    let optionText3 =
      "<b>Which of the following rare genetic diseases of obesity are you interested in learning about?</b>"

    // checkboxes to modify
    let tfa_75 = document.querySelector("#tfa_75") //BBS
    let tfa_77 = document.querySelector("#tfa_77") //PPL
    let tfa_78 = document.querySelector("#tfa_78")
    let tfa_79 = document.querySelector("#tfa_79")
    const fieldsToModify = [tfa_75, tfa_77, tfa_78, tfa_79]

    diagnosedFields.classList.add("show")
    diagnosedElement.innerHTML = eval(e)

    var optionYesNo = document.querySelectorAll('input[className="yesno"]')
    var yesnoElement = document.querySelector("#yesno")

    //test checkbox to radio toggle
    if (e == "optionText1" || e == "optionText2" || e == "optionText3") {
      for (let i = 0; i < fieldsToModify.length; i++) {
        fieldsToModify[i].setAttribute("singleSelect", "true")
        fieldsToModify[i].checked = false
        // remove below comment to activate option/checkbox toggle
        // fieldsToModify[i].setAttribute("type","radio");
        document.getElementById("selectAll").innerHTML = "Select one:"
      }
    } else {
      for (let i = 0; i < fieldsToModify.length; i++) {
        fieldsToModify[i].setAttribute("singleSelect", "false")
        fieldsToModify[i].checked = false
        // remove below comment to activate option/checkbox toggle
        // fieldsToModify[i].setAttribute("type","checkbox");
        document.getElementById("selectAll").innerHTML =
          "Select all that apply:"
      }
    }

    // change field header text
    if (e == "optionText2") {
      yesnoElement.innerHTML = yesnoElement.innerHTML.replace("you", "they")
    } else {
      yesnoElement.innerHTML = yesnoElement.innerHTML.replace("they", "you")
    }
    // change field header text
    if (e == "optionText3") {
      document.getElementById("yesnoFields").classList.add("hide")
      document.getElementById("tfa_83").checked = true
    } else {
      document.getElementById("yesnoFields").classList.remove("hide")
      document.getElementById("tfa_83").checked = false
    }
  }

  // make option field behave as group without changing name property
  // handle show/hide optional phone
  const selectOnlyThis = e => {
    console.log(e.currentTarget)
    // checkboxes to modify
    let tfa_75 = document.querySelector("#tfa_75")
    let tfa_77 = document.querySelector("#tfa_77")
    let tfa_78 = document.querySelector("#tfa_78")
    let tfa_79 = document.querySelector("#tfa_79")
    let tfa_101 = document.querySelector("#tfa_101")
    const fieldsToModify = [tfa_75, tfa_77, tfa_78, tfa_79, tfa_101]

    var phoneOptional = document.querySelector("#phone-optional")

    if (e.currentTarget.getAttribute("singleselect") === "true") {
      //if (e.type == "radio"){
      for (let i = 0; i < fieldsToModify.length; i++) {
        if (fieldsToModify[i] !== e) {
          fieldsToModify[i].checked = false
        }
      }
      e.currentTarget.checked = true
    } else {
      if (e.currentTarget.id == "tfa_101" && e.currentTarget.checked) {
        document.getElementById("tfa_9").required = true
        phoneOptional.classList.add("show")
      } else {
        e.currentTarget.checked = false
        document.getElementById("tfa_9").required = false

        phoneOptional.classList.remove("show")
      }
    }
  }

  //show/hide optional phone
  const showHidePhone = (e, negate = false) => {
    var phoneOptional = document.querySelector("#phone-optional")
    if (e.checked) {
      phoneOptional.classList.add("show")
    } else {
      phoneOptional.classList.remove("show")
      document.getElementById("tfa_9").value = ""
    }
  }

  const handleSubmit = event => {
    var optionDescribe = document.querySelectorAll('input[class="describe"]')
    var checkboxDiagnosed = document.querySelectorAll(
      'input[class="diagnosed"]'
    )
    var redirectURLField = document.querySelector("#tfa_88")
    var submitBtn = document.querySelector("#submit")
    let form = document.querySelector("#dtc-register")
    let errorElement = document.querySelector("#error-message")
    var optionYesNo = document.querySelectorAll('input[class="yesno"]')
    var diagnosedElement = document.querySelector("#diagnosed")
    let describe = Array.prototype.slice
      .call(optionDescribe)
      .some(x => x.checked)
    let diagnosed = Array.prototype.slice
      .call(checkboxDiagnosed)
      .some(x => x.checked)
    let yesno = Array.prototype.slice.call(optionYesNo).some(x => x.checked)
    var describeElement = document.querySelector("#describe")
    var yesnoElement = document.querySelector("#yesno")

    console.log(optionDescribe)
    if (!describe) {
      describeElement.classList.add("invalid")
    } else {
      describeElement.classList.remove("invalid")
    }
    if (!diagnosed) {
      diagnosedElement.classList.add("invalid")
    } else {
      diagnosedElement.classList.remove("invalid")
    }
    if (!yesno) {
      yesnoElement.classList.add("invalid")
    } else {
      yesnoElement.classList.remove("invalid")
    }
    if (!form.checkValidity() || !describe || !diagnosed || !yesno) {
      event.preventDefault()
      event.stopPropagation()
      //console.log(event);
      errorElement.classList.remove("hide-error")
      errorElement.classList.add("show-error")
      form.classList.add("submit-attempted")
      window.scrollTo(0, 0)
    } else {
      event.preventDefault()
      event.stopPropagation()
      submitBtn.setAttribute("disabled", "")
      errorElement.classList.remove("hide-error")
      if (!document.getElementById("tfa_75").checked) {
        //redirectURLField.value = redirectURLField.value.replace("thankyou-ppl", "thankyou-bbs");
      }
      let submitFormFunction = Object.getPrototypeOf(form).submit
      submitFormFunction.call(form)
    }
  }

  return (
    <>
      <Layout noNav isi>
        <Seo
          title="Connect with a Patient Education Manager | IMCIVREE® (setmelanotide) injection"
          description="Receive updates about IMCIVREE® (setmelanotide) by completing this form. Please see full Prescribing Information for Important Safety Information."
          ogTitle="Connect with a Patient Education Manager | IMCIVREE® (setmelanotide) injection"
          ogDesc="Receive updates about IMCIVREE® (setmelanotide) by completing this form. Please see full Prescribing Information for Important Safety Information."
        />
        <div className="inner-container">
          <section className="register-section">
            <div className="register-wrapper">
              <div className="row">
                <div className="hidden-mobile col-lg-2"></div>
                <div className="col-12 col-lg-8">
                  <div id="register-form-section">
                    <h1>
                      Connect with a Patient Education Manager
                      <br className="hide-for-small show-for-medium" /> at
                      Rhythm InTune
                    </h1>
                    <h4>and sign up to receive email updates about IMCIVREE</h4>
                    <p>
                      To be contacted by a Patient Education Manager (PEM) and learn
                      more about the one-on-one personalized support they
                      provide, please complete the form below. All fields are
                      required unless otherwise noted.
                    </p>
                    <p id="error-message" className="hide-error">
                      It seems one or more required fields are empty or
                      incorrect. Please check below and try again.
                    </p>
                    <div className="mt-1 md_mt-2">
                      <form
                        id="dtc-register"
                        name="dtcform"
                        action="https://www.tfaforms.com/api_v2/workflow/processor"
                        noValidate
                        method="post"
                      >
                        <fieldset>
                          <input
                            type="checkbox"
                            value="tfa_43"
                            id="tfa_43"
                            name="tfa_43"
                            aria-labelledby="tfa_43-L"
                            data-tfa-labelledby="tfa_42-L tfa_43-L"
                          />
                          <label htmlFor="tfa_43">
                            <strong className="fw-semibold">
                              I'd like to receive email updates about IMCIVREE
                              from Rhythm Pharmaceuticals.* (optional)
                            </strong>
                          </label>
                          <br />
                        </fieldset>
                        <fieldset>
                          <input
                            type="hidden"
                            id="tfa_89"
                            name="tfa_89"
                            defaultValue=""
                          />
                          <label htmlFor="tfa_3">First name</label>
                          <br />
                          <input
                            required
                            aria-required="true"
                            type="text"
                            id="tfa_3"
                            pattern="[A-Za-z-_ ]{1,32}"
                            name="tfa_3"
                            maxLength="30"
                            title="First name"
                            className="required"
                            defaultValue=""
                          />
                          <br />
                        </fieldset>
                        <fieldset>
                          <label htmlFor="tfa_5">Last name</label>
                          <br />
                          <input
                            required
                            aria-required="true"
                            type="text"
                            id="tfa_5"
                            pattern="[A-Za-z-_ ]{1,32}"
                            name="tfa_5"
                            defaultValue=""
                            maxLength="30"
                            title="Last name"
                            className="required"
                          />
                          <br />
                        </fieldset>
                        <fieldset>
                          <label htmlFor="tfa_7">Email</label>
                          <br />
                          <input
                            required
                            aria-required="true"
                            type="text"
                            id="tfa_7"
                            name="tfa_7"
                            defaultValue=""
                            pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,100}$"
                            title="Email "
                            className="validate-email required"
                          />
                          <br />
                        </fieldset>

                        <fieldset>
                          <input
                            type="checkbox"
                            onClick={e => selectOnlyThis(e)}
                            id="tfa_101"
                          />
                          <label htmlFor="tfa_64">
                            I want to be contacted by a PEM (optional)
                          </label>
                        </fieldset>
                        <fieldset id="phone-optional">
                          <label htmlFor="tfa_9">Phone<sup>&dagger;</sup></label>
                          <br />
                          <input
                            type="text"
                            id="tfa_9"
                            name="tfa_9"
                            defaultValue=""
                            maxLength="12"
                            data-condition="`#tfa_64`"
                            title="Phone (optional)"
                            validate="true"
                            placeholder="###-###-####"
                            pattern="[0-9]{3}-?[0-9]{3}-?[0-9]{4}"
                            className="validate-custom ^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$"
                          />
                          <br />
                        </fieldset>

                        <fieldset>
                          <label htmlFor="tfa_12">ZIP</label>
                          <br />
                          <input
                            required
                            aria-required="true"
                            type="text"
                            id="tfa_12"
                            name="tfa_12"
                            defaultValue=""
                            pattern="[0-9]{5}"
                            maxLength="5"
                            title="ZIP"
                            className="validate-custom /\d{5}/ required"
                          />
                        </fieldset>
                        <br />
                        <fieldset>
                          <p id="describe">
                            <b>Which best describes you?</b>
                          </p>
                          <label>I am (select one):</label>
                          <br />
                          <input
                            onClick={e => showDiagnosedFields("optionText1")}
                            type="radio"
                            className="describe"
                            value="tfa_65"
                            id="tfa_65"
                            name="tfa_27"
                            aria-required="true"
                            data-conditionals="#tfa_73,#tfa_74"
                            aria-labelledby="tfa_65-L"
                            data-tfa-labelledby="tfa_27-L tfa_65-L"
                          />
                          <label htmlFor="tfa_65">
                            A person living with BBS or POMC, PCSK1, or LEPR
                            deficiency
                          </label>
                          <br />
                          <input
                            onClick={e => showDiagnosedFields("optionText2")}
                            type="radio"
                            className="describe"
                            value="tfa_66"
                            id="tfa_66"
                            name="tfa_27"
                            aria-required="true"
                            data-conditionals="#tfa_85,#tfa_74"
                            aria-labelledby="tfa_66-L"
                            data-tfa-labelledby="tfa_27-L tfa_66-L"
                          />
                          <label htmlFor="tfa_66">
                            A family member/caregiver of a person diagnosed with
                            BBS or POMC, PCSK1, or LEPR deficiency
                          </label>
                          <br />
                          <input
                            onClick={e => showDiagnosedFields("optionText3")}
                            className="describe"
                            type="radio"
                            value="tfa_67"
                            id="tfa_67"
                            name="tfa_27"
                            aria-required="true"
                            data-conditionals="#tfa_87,#tfa_74"
                            aria-labelledby="tfa_67-L"
                            data-tfa-labelledby="tfa_27-L tfa_67-L"
                          />
                          <label htmlFor="tfa_67">
                            Neither of the above, but I'd like to learn more
                            about IMCIVREE
                          </label>
                          <br />
                        </fieldset>
                        <fieldset id="diagnosed-fields">
                          <br />
                          <p id="diagnosed">
                            <b>
                              Which of the following rare genetic diseases of
                              obesity have you been diagnosed with?
                            </b>
                          </p>
                          <label id="selectAll">Select all that apply:</label>
                          <br />

                          <input
                            onClick={e => {
                              selectOnlyThis(e)
                              selectOnlyThis(e)
                            }}
                            type="checkbox"
                            className="diagnosed"
                            value="tfa_75"
                            id="tfa_75"
                            name="tfa_75"
                            aria-labelledby="tfa_75-L"
                            data-tfa-labelledby="tfa_74-L tfa_75-L"
                          />
                          <label htmlFor="tfa_75">Bardet-Biedl syndrome</label>
                          <br />
                          <input
                            onClick={e => selectOnlyThis(e)}
                            type="checkbox"
                            className="diagnosed"
                            value="tfa_77"
                            id="tfa_77"
                            name="tfa_77"
                            aria-labelledby="tfa_77-L"
                            data-tfa-labelledby="tfa_74-L tfa_77-L"
                          />
                          <label htmlFor="tfa_77">
                            Proopiomelanocortin deficiency
                          </label>
                          <br />
                          <input
                            onClick={e => selectOnlyThis(e)}
                            type="checkbox"
                            className="diagnosed"
                            value="tfa_78"
                            id="tfa_78"
                            name="tfa_78"
                            aria-labelledby="tfa_78-L"
                            data-tfa-labelledby="tfa_74-L tfa_78-L"
                          />
                          <label htmlFor="tfa_78">
                            Proprotein convertase subtilisin/kexin type 1
                            deficiency
                          </label>
                          <br />
                          <input
                            onClick={e => selectOnlyThis(e)}
                            type="checkbox"
                            className="diagnosed"
                            value="tfa_79"
                            id="tfa_79"
                            name="tfa_79"
                            aria-labelledby="tfa_79-L"
                            data-tfa-labelledby="tfa_74-L tfa_79-L"
                          />
                          <label htmlFor="tfa_79">
                            Leptin receptor deficiency
                          </label>
                          <br />
                        </fieldset>

                        <fieldset id="yesnoFields">
                          <br />
                          <p id="yesno">
                            <b>Have you been prescribed IMCIVREE?</b>
                          </p>
                          <input
                            type="radio"
                            className="yesno"
                            value="tfa_82"
                            id="tfa_82"
                            name="tfa_81"
                            aria-required="true"
                            aria-labelledby="tfa_82-L"
                            data-tfa-labelledby="tfa_81-L tfa_82-L"
                          />
                          <label htmlFor="tfa_82">Yes</label>
                          <br />
                          <input
                            type="radio"
                            value="tfa_83"
                            className="yesno"
                            id="tfa_83"
                            name="tfa_81"
                            aria-required="true"
                            aria-labelledby="tfa_83-L"
                            data-tfa-labelledby="tfa_81-L tfa_83-L"
                          />
                          <label htmlFor="tfa_83">No</label>
                          <br />
                        </fieldset>
                        <fieldset>
                          <span htmlFor="submit" className="submit-btn">
                            <input
                              type="submit"
                              id="submit"
                              className="btn"
                              name="submit"
                              onClick={e => handleSubmit(e)}
                              value="Submit"
                            />
                          </span>
                          <input
                            type="hidden"
                            value="5012025"
                            name="tfa_dbFormId"
                            id="tfa_dbFormId"
                          />
                          <input
                            type="hidden"
                            defaultValue=""
                            name="tfa_dbResponseId"
                            id="tfa_dbResponseId"
                          />
                          <input
                            type="hidden"
                            value="06855706377ce70f365c17b94987a51a"
                            name="tfa_dbControl"
                            id="tfa_dbControl"
                          />
                          <input
                            type="hidden"
                            defaultValue=""
                            name="tfa_dbWorkflowSessionUuid"
                            id="tfa_dbWorkflowSessionUuid"
                          />
                          <input
                            type="hidden"
                            value="1645114908"
                            name="tfa_dbTimeStarted"
                            id="tfa_dbTimeStarted"
                            autoComplete="off"
                          />
                          <input
                            type="hidden"
                            value="3"
                            name="tfa_dbVersionId"
                            id="tfa_dbVersionId"
                          />
                          <input
                            type="hidden"
                            defaultValue=""
                            name="tfa_switchedoff"
                            id="tfa_switchedoff"
                          />

                          <input
                            type="hidden"
                            id="tfa_88"
                            name="tfa_88"
                            defaultValue="https://imcivree.com/ppl/thankyou"
                          />
                          <input
                            type="hidden"
                            id="tfa_90"
                            name="tfa_90"
                            defaultValue="PPL"
                          />
                          <input
                            type="hidden"
                            id="tfa_91"
                            name="tfa_91"
                            defaultValue={utm}
                          />
                        </fieldset>
                        <br />
                      </form>
                      <div className="ppl-ref-box">
                        <span className="hangingRef hanging-ref small d-block md_ml-1">
                          *By checking this box, I understand that I may stop
                          receiving email updates at any time by following the
                          unsubscribe link in the emails sent to me, or as
                          detailed in the Rhythm Pharmaceuticals Privacy Policy.
                        </span>
                        <span className="hangingRef hanging-ref small d-block md_ml-1">
                          <sup>&dagger;</sup>By providing my phone number I agree
                          to receive phone calls from Rhythm Pharmaceuticals and
                          understand that standard data rates of my carrier may
                          apply.
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="hidden-mobile col-lg-2"></div>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    </>
  )
}

SignUp.prototype = {
  siteTitle: PropTypes.string,
}

SignUp.defaultProps = {
  siteTitle: ``,
}


import React, {useEffect} from 'react';
import Button from "../../../../components/button"
import './Modal.scss';

const ExitModal = (props) => {
  
  useEffect(() => {
    let cancel = document.querySelector(".cancel")
    let enter = document.querySelector(".enter")
    let modalExit = document.querySelector("#modal-exit")

    const onClick = e => {
      e.preventDefault();
      if (e.currentTarget === cancel) {
        modalExit.classList.remove("open")
      }
      if (e.currentTarget === enter) {
        modalExit.classList.remove("open")
        window.location.href = enter.href;
      }
    }
    if (cancel, enter, modalExit){
      cancel.addEventListener("click", onClick)
      enter.addEventListener("click", onClick)
    }
  }, [])

  return (
    <div id="modal-exit" className="modal small">
        <div className="bg"/>
        <div className="content-area">
          <div className="close" />
          <div className="mt-0">
            <h1 className="h1 text-center ignore-resize">For healthcare providers</h1>
          </div>
          <div className="mt-1 md_mt-2">
            <p className="text-center ignore-resize">The information contained in this site is intended for US healthcare providers only. If you are a US healthcare provider, click “I agree” to continue. If you are not a US healthcare provider, please click “cancel” to go back.</p>
          </div>
          <div className='row mt-1 md_mt-3'>
            <div className=' small-12 medium-6 og text-center'>
              {/* <p className="h3 link text-right md_pr-2" >Cancel</p> */}
              <Button textCenter align-middle small copy={<>Cancel</>} url="#" className="cancel"/>
            </div>
            <div className=' small-12 medium-6 og text-center'>
              {/* <p className="h3 "><a className="no-line" rel="noreferrer" href="" target="_blank">I agree</a></p> */}
              <Button textCenter align-middle small copy={<>I agree</>} className="enter" url="#" />
            </div>
          </div>
        </div>
    </div>
  )
}
export default ExitModal;

import React from "react"

import Layout from "../../components/bbs-layout"
import Seo from "../../components/seo"
import Tabs from "../../components/template-partials/global-components/tabs/tabs"
import HangingRef from "../../components/hanging-ref"
import Callout from "../../components/callout"
import Button from "../../components/button"
import Quote from "../../components/quote"

import ActorPortrayal from "../../components/gatsby-images/actor-portrayal"
import GrowthChart from "../../components/gatsby-images/growth-chart"

import ActorKid from "../../images/actor-portrayal.png"
import ActorKidMobile from "../../images/actor-portrayal-mobile.png"

import percentage86 from "../../images/icons/percentage-86.svg"
import percentage100 from "../../images/icons/percentage-100.svg"
import avgWeightReduction from "../../images/avg-weight-reduction.png"
import arrow15 from "../../images/arrow-15.png"
import maleSilo from "../../images/male-silo.png"
import hungerScale from "../../images/hunger-scale.png"
import hungerChart from "../../images/hunger-chart.png"
import hungerBBSProfile01 from "../../images/hunger-bbs-profile01.png"
import hungerBBSProfile02 from "../../images/hunger-bbs-profile02.png"
import hungerBBSProfile03 from "../../images/hunger-bbs-profile03.png"

import trackerChart from "../../images/adult-bmi-tracker.png"

const WeightHungerReduction = () => {
  return (
    <Layout>
      <Seo
        title="Weight & Hunger Reduction | IMCIVREE® (setmelanotide) injection"
        description="Learn how IMCIVREE® (setmelanotide) helped reduce measures of weight and hunger in a BBS clinical trial. Please see full Prescribing Information and Important Safety Information."
        ogTitle="Clinical Trial Results | IMCIVREE® (setmelanotide) injection"
        ogDesc="Learn about the clinical trial results of IMCIVREE® (setmelanotide) in children 6 years of age and older and adults. Please see full Prescribing Information and Important Safety Information."
      />
      <div className="content-block">
        <h1 className="h1">
          How did IMCIVREE help reduce measures of weight and hunger in the BBS
          trial?
        </h1>
        <div className="row">
          <div className="columns">
            <Tabs>
              <Tabs.Pane name="Weight reduction in children" key="1">
                <div className="row">
                  <div className="column small-12 medium-9">
                    <h2 className="h2--no-margin-bottom">
                      IMCIVREE reduced BMI Z-scores in children over the course
                      of the 1-year clinical trial*
                    </h2>
                    <p className="mt-1 md_mt-1">
                      A BMI Z-score was used to measure the reduction in BMI in
                      children. BMI Z-scores are reliable measures of weight in
                      children who are still growing because they take into
                      account height, age, and gender.
                    </p>
                    <div className="mb-2 md_mb-3">
                      <div className="row align-middle">
                        <div className="column small-12 medium-3 text-center medium-text-left">
                          <img src={percentage86} />
                        </div>
                        <div className="column og small-12 medium-9 mt-1 md_mt-0">
                          <p className="text-center medium-text-left mb-0">
                            of children had a meaningful reduction in BMI
                            Z-score<sup>&dagger;</sup>
                          </p>
                        </div>
                      </div>
                    </div>
                    <p>
                      In the clinical trial, people were not required to change
                      their diet or exercise routine.
                    </p>
                    <HangingRef
                      symbol="*"
                      isAsterisk
                      copy="N=14. N is the number of people evaluated. Children were 6 to 17 years of age."
                    />
                  </div>
                  <div className="column small-12 medium-3 text-right align-self-bottom ">
                    {/* <ActorPortrayal alt="Child with obesity" /> */}

                    <img src={ActorKid} alt="Child with obesity" className="show-for-medium" />

                    <div className="position-relative hide-for-medium bbs-mobile-actor-box">
                      <img src={ActorKidMobile} alt="Child with obesity" className="position-relative z1" />
                      <small className="references position-absolute z2 fw-bold">Actor portrayal.</small>
                    </div>

                  </div>
                </div>
                <div className="row">
                  <div className="columns large-9 medium-10 small-12 large-offset-3">
                    <Callout
                      fullWidth
                      className="lime-teal right text-left"
                    >
                      <p>
                        IMCIVREE is the first and only treatment to
                        significantly reduce BMI Z-score in children with
                        obesity and a clinical diagnosis of BBS
                      </p>
                    </Callout>
                  </div>
                </div>

                <h2 className="h2--no-margin-bottom mt-2 md_mt-2">
                  In a long-term study, children who continued taking IMCIVREE
                  maintained reductions in BMI Z-scores<sup>&Dagger;</sup>
                </h2>
                <p className="mt-1 md_mt-1">
                  Some people chose to continue taking IMCIVREE in a separate
                  long-term clinical trial. After they received 2 years of
                  treatment, their results were analyzed.
                </p>
                <div className="mb-2 md_mb-1">
                  <div className="row align-middle">
                    <div className="column small-12 large-3 medium-shrink text-center medium-text-left">
                      <img src={percentage100} />
                    </div>
                    <div className="column og small12 large-9 mt-1 md_mt-0">
                      <p className="text-center medium-text-left mb-0">
                        of patients (N=12) at 2 years had a meaningful reduction
                        in BMI Z-score<sup>&dagger;</sup>
                      </p>
                    </div>
                  </div>
                </div>
                <ul className="neon-green-bullets lg_mb-1">
                  <li>
                    There was a 0.72 average reduction in BMI Z-score
                    <sup>&dagger;</sup>
                  </li>
                </ul>

                <div
                  id="effect-of-imcivree-callout-2"
                  className="row mt-1 mb-2 md_mb-2"
                >
                  <div className="columns large-9 medium-10 small-12">
                    <Callout
                      fullWidth
                      className="lime-teal left text-left"
                      style={{ left: `0` }}
                    >
                      <p>
                        Children who continued IMCIVREE for 2 years maintained
                        meaningful reductions in BMI Z-score
                      </p>
                    </Callout>
                  </div>
                </div>

                <div>
                  <HangingRef
                    mb0
                    symbol={
                      <>
                        <sup>&dagger;</sup>
                      </>
                    }
                    copy="A clinically meaningful reduction is generally defined as a reduction of greater than or equal to 0.2 in BMI Z-score."
                  />
                  <HangingRef
                    mb0
                    symbol={
                      <>
                        <sup>&Dagger;</sup>
                      </>
                    }
                    copy="Change in BMI Z-score could not be calculated for one 20-year-old person who was 17 years old at the beginning of the original trial."
                  />
                  <p className="md_mb-2"></p>
                </div>

                <h2 className="h2--no-margin-bottom">
                  IMCIVREE reduced the severity of obesity in children living
                  with BBS
                </h2>
                <p className="mt-1 md_mt-1">
                  You may be more familiar with viewing a child's growth as a
                  percentile on a chart from the doctor. These same charts can
                  be used for BMI. This growth chart represents what a
                  hypothetical child with BBS who started IMCIVREE at age 12
                  <sup>§</sup> may experience in BMI reduction after 1 and 2
                  years, based on results from the clinical trials.
                </p>
                <ul className="neon-green-bullets">
                  <li>
                    At the start of the 1-year clinical trial, average BMI was
                    145% of the 95th percentile
                  </li>
                  <li>
                    At the end of the 1-year clinical trial, average BMI was
                    127% of the 95th percentile
                    <ul>
                      <li className="is-dash">17-percentage-point reduction</li>
                    </ul>
                  </li>
                </ul>

                <div className="row lg_mt-2 mt-2">
                  <div className="column small-12 medium-8 medium-offset-2">
                    <GrowthChart alt="BBS Clinical Trial Data for children" />
                  </div>
                  {/* <div className="column small-12 medium-5">
                <img src={pedCallout02} className="mt-2 lg_mt-2" />
              </div> */}
                  <div className="column small-12 ">
                    <p className="fw-normal hangingRef mt-1 md_mt-1">
                      Figure modeled after Gulati AK, Kaplan DW, Daniels SR.
                      Clinical tracking of severely obese children: a new growth
                      chart. <em>Pediatrics.</em> 2012;130(6):1136-1140.
                    </p>
                    <HangingRef
                      symbol={
                        <>
                          <sup>§</sup>
                        </>
                      }
                      copy="Growth chart above is based on females 2 to 20 years of age and is for illustrative purposes only."
                    />
                  </div>
                </div>
              </Tabs.Pane>

              <Tabs.Pane name="Weight reduction in adults" key="2">
                <h2 className="h2--no-margin-bottom">
                  IMCIVREE showed early, significant, and continued weight
                  reduction
                </h2>
                <h3 className="h3 mt-1 md_mt-1">
                  Average reductions in weight over the course of the 1-year
                  clinical trial*<sup>&dagger;</sup>
                </h3>
                <div className="text-center">
                  <img
                    src={avgWeightReduction}
                    alt="Average reduction in weight in adults over the course of clinical trial"
                    className="reduced-size"
                  />
                </div>
                <p className="mt-1 md_mt-1">
                  In the clinical trial, people were not required to change
                  their diet or exercise routine.
                </p>
                <HangingRef
                  mb0
                  symbol="*"
                  isAsterisk
                  copy="Adults were 18 years of age or older."
                />
                <HangingRef
                  symbol={
                    <>
                      <sup>&dagger;</sup>
                    </>
                  }
                  copy="14 weeks: n=10; 1 year: n=12."
                />

                <div className="row">
                  <div className="column small-12 medium-10">
                    <h2 className="h2--no-margin-bottom">
                      In a long-term study, adults who continued taking IMCIVREE
                      for a total of 2 years lost even more weight
                    </h2>
                    <h3 className="h3 mt-1 md_mt-1">
                      Average reduction in weight at 2 years in the long-term
                      study<sup>&Dagger;</sup>
                    </h3>
                    <div className="text-center">
                      <img
                        src={arrow15}
                        alt="15% average reduction at 2 years"
                        className="reduced-size2"
                      />
                    </div>
                    <p className="mt-1 md_mt-1">
                      At the end of a clinical trial for IMCIVREE, 19 people
                      continued in a long-term study. 6 of these people were
                      adults. People are being assessed every 3 months until the
                      end of the study (up to 5 years or study withdrawal).
                    </p>
                    <HangingRef
                      symbol={
                        <>
                          <sup>&Dagger;</sup>
                        </>
                      }
                      copy="n=6."
                    />
                  </div>
                  <div className="column small-12 medium-2">
                    <img src={maleSilo} className="hide-for-small-only" />
                  </div>
                </div>

                <div className="row lg_mt-3 mt-2">
                  <div className="column small-12 medium-12">
                    <h2 className="h2--no-margin-bottom">
                      IMCIVREE reduced the severity of obesity in adults living
                      with BBS
                    </h2>
                    <p className="mt-1 md_mt-1">
                      This growth chart represents what a hypothetical adult
                      with BBS<sup>&sect;</sup> who started IMCIVREE at 20 years
                      of age may experience in BMI reduction after 1 and 2
                      years, based on results from the clinical trials
                      <sup>||</sup>
                    </p>
                  </div>
                  <div className="column small-12 text-center">
                    <img src={trackerChart} alt="Clinical trial results showing adult BMI reduction after 1 and 2 years" />
                  </div>
                  <div className="column small-12 pt-1 md_pt-1">
                    <HangingRef
                      mb0
                      symbol={<></>}
                      copy="Chart is for illustrative purposes only."
                    />
                    <HangingRef
                      mb0
                      symbol={
                        <>
                          <sup>§</sup>
                        </>
                      }
                      copy="Not an actual patient."
                    />
                    <HangingRef
                      mb0
                      symbol={
                        <>
                          <sup>||</sup>
                        </>
                      }
                      copy="Patients with data after 1 year of treatment."
                    />
                  </div>
                </div>

                <div
                  className="large-offset-2 lg_mt-3 mt-2 column"
                  style={{ maxWidth: `900px` }}
                >
                  <Quote
                    Quote
                    copy={
                      <>
                        IMCIVREE has mainly helped me lose and control my
                        weight. I know I couldn't have done it without this
                        medication.
                      </>
                    }
                    signoff={
                      <>
                        — Adult patient enrolled in the IMCIVREE clinical trial
                      </>
                    }
                  ></Quote>
                </div>

                <div className="row">
                  <div className="columns large-8 medium-10 small-12 mt-2 md_mt-2">
                    <Callout
                      fullWidth
                      className="lime-teal left text-left"
                      style={{ left: `0` }}
                    >
                      <p>
                        IMCIVREE reduced weight early and continuously over the
                        course of 2 years of treatment
                      </p>
                    </Callout>
                  </div>
                </div>
              </Tabs.Pane>

              <Tabs.Pane name="Hunger reduction" key="3">
                <h2 className="h2--no-margin-bottom">
                  IMCIVREE provided reduction in hunger scores early and
                  continuously throughout treatment
                </h2>
                <p className="mt-1 md_mt-1">
                  The effect of IMCIVREE on reducing hunger was studied in
                  people 12 years and older living with BBS who could
                  self-report their hunger.
                </p>
                <ul className="neon-green-bullets">
                  <li>
                    They completed a questionnaire every day for 1 year to
                    determine changes in their hunger
                  </li>
                  <li>
                    People scored their hunger on a daily basis using a scale
                    from 0 to 10
                  </li>
                </ul>
                <p className="mb-1"></p>
                <p className="text-center hide-for-medium">
                  &larr; Swipe left or right to view &rarr;
                </p>
                <div className="text-center swipe-container">
                  <img
                    src={hungerScale}
                    alt="Hunger Scale"
                    className="lg_mt-2 mb-2 md_mb-3"
                    style={{ minWidth: `800px`, maxWidth: `800px` }}
                  />
                </div>

                <h2 className="h2">
                  IMCIVREE reduced the most severe feelings of hunger
                </h2>
                <p className="text-center hide-for-medium">
                  &larr; Swipe left or right to view &rarr;
                </p>
                <div className="text-center swipe-container">
                  <img
                    src={hungerChart}
                    alt="IMCIVREE average hunger score"
                    className="mb-2 md_mb-3"
                    style={{ minWidth: `800px`, maxWidth: `880px` }}
                  />
                </div>
                <div className="row">
                  <div className="columns large-8 medium-10 small-12 mt-2 md_mt-2">
                    <Callout
                      fullWidth
                      className="lime-teal left text-left"
                      style={{ left: `0` }}
                    >
                      <p>
                        A majority of people experienced a reduction in hunger
                        score within 2 weeks of taking IMCIVREE
                      </p>
                    </Callout>
                  </div>
                </div>

                <div className="row mt-2 md_mt-4">
                  <div className="small-12 medium-12 large-2 column">
                    <figure className="hunger-bbs-profile text-center">
                      <img
                        src={hungerBBSProfile01}
                        alt="Person living with BBS"
                        className="mb-2 md_mb-3"
                        style={{ minWidth: `300px`, maxWidth: `300px` }}
                      />
                    </figure>
                  </div>
                  <div className="small-12 medium-12 large-8 large-offset-2 column mb-4">
                    <Quote
                      Quote
                      copy={
                        <>
                          Before IMCIVREE, I didn’t realize how much time I
                          spent focusing on food, and how much that was
                          affecting my day-to-day and the other things I could
                          be accomplishing.
                        </>
                      }
                      signoff={<>— Kathryn, a person living with BBS</>}
                    ></Quote>
                  </div>

                  <div className="small-12 medium-12 large-8 column mb-2 md_mb-4 small-order-2 medium-order-1">
                    <Quote
                      Quote
                      copy={
                        <>
                          The change in Reed's hunger has cascaded into many
                          positive life changes for all of us. There's less
                          agitation and anxiety over hunger or family meals.
                          This is simple normalcy for many families, but for us,
                          they're moments I'll never take for granted.
                        </>
                      }
                      signoff={<>— Kat, caregiver of a child living with BBS</>}
                    ></Quote>
                  </div>
                  <div className="small-12 medium-12 large-4 column mb-2 small-order-1 medium-order-2">
                    <figure className="hunger-bbs-profile text-center">
                      <img
                        src={hungerBBSProfile03}
                        alt="Caregiver of a child living with BBS"
                        className="mt-2 mb-1 md_mb-3"
                        style={{ minWidth: `300px`, maxWidth: `300px` }}
                      />
                    </figure>
                  </div>
                  
                  <div className="small-12 medium-12 large-2 column">
                    <figure className="hunger-bbs-profile text-center">
                      <img
                        src={hungerBBSProfile02}
                        alt="Caregiver of a child living with BBS"
                        className="mb-2 md_mb-3"
                        style={{ minWidth: `300px`, maxWidth: `300px` }}
                      />
                    </figure>
                  </div>
                  <div className="small-12 medium-12 large-8 large-offset-2 column">
                    <Quote
                      Quote
                      copy={
                        <>
                          He is no longer digging through the fridge or garbage,
                          so we do not lock them anymore. He isn’t asking for
                          food constantly between meals and snacks, and I
                          sometimes find myself realizing it’s been a few hours
                          and asking him if he’s ready for a snack.
                        </>
                      }
                      signoff={<>— Rachel, caregiver of a child living with BBS</>}
                    ></Quote>
                  </div>


                </div>
              </Tabs.Pane>
            </Tabs>
          </div>
        </div>

        <div className="text-center mt-3 md_mt-2">
          <Button
            copy={<>Learn about quality of life improvements with IMCIVREE</>}
            url="/bbs/quality-of-life-results/"
          />
        </div>
      </div>
    </Layout>
  )
}

export default WeightHungerReduction

import * as React from "react"

import Layout from "../../../components/hcp-bbs-layout"
import Seo from "../../../components/seo"
import Button from "../../../components/button"
import Callout from "../../../components/callout"

import HcpPplPrescribingDownload from "../../../images/prescribing-download.svg"
import HcpPplPrescribingFollow from "../../../images/prescribing-follow.svg"
import HcpPplPrescribingSubmit from "../../../images/prescribing-submit.svg"
import IconNumberOne from "../../../images/icon-number-1.svg"
import IconNumberTwo from "../../../images/icon-number-2.svg"
import IconNumberThree from "../../../images/icon-number-3.svg"

import IcdIcon from "../../../images/icons/icd-icon.png"

const PrescribingImcivree = () => (
  <Layout>
    <Seo
      title="Prescribing IMCIVREE | IMCIVREE® (setmelanotide) injection | For HCPs"
      description="View steps to get your patients started on treatment with IMCIVREE® (setmelanotide). Please see full Prescribing Information for Important Safety Information."
      ogTitle="Prescribing IMCIVREE | IMCIVREE® (setmelanotide) injection"
      ogDesc="View steps to get your patients started on treatment with IMCIVREE® (setmelanotide). Please see full Prescribing Information for Important Safety Information."
    />
    <div className="content-block">
      <h1 className="h1">Getting your patient started on IMCIVREE</h1>
      <h2 className="h2">3 simple steps to initiate treatment</h2>

      <div className="row og">
        <div className="columns og small-12 medium-1 medium-offset-0 text-center align-self-top">
          <img src={HcpPplPrescribingDownload} className="mb-2" alt="Download IMCIVREE start form" />
        </div>
        <div className="columns og small-3 medium-1 text-center align-self-top">
          <img src={IconNumberOne} alt="1" />
        </div>
        <div className="columns og small-8 medium-9 align-self-middle pt-1 lg_pt-1">
          <strong>Download the <a href="/Start_Form.pdf" target="_blank" className="textUnderlined fw-black">IMCIVREE Prescription Start Form</a> to start prescribing</strong><br/>
          <ul className="neon-green-bullets">
            <li>The preferred method of starting IMCIVREE is via our Prescription Start Form</li>
            <li>This form serves as your patient’s prescription and provides an opportunity for your patient to enroll in Rhythm InTune, a support program from Rhythm Pharmaceuticals</li>
            <li>If you prefer to e-subscribe, please look to <strong>PANTHERx Rare Pharmacy</strong></li>
          </ul>
        </div>
      </div>

      <div className="row og lg_mt-2 mt-2">
        <div className="columns og small-12 medium-1 medium-offset-0 text-center align-self-middle">
          <img src={HcpPplPrescribingFollow} className="mb-2" alt="Instructions to Complete Form" />
        </div>
        <div className="columns og small-3 medium-1 text-center align-self-middle">
          <img src={IconNumberTwo} alt="2" />
        </div>
        <div className="columns og small-8 medium-8 align-self-middle">
          Follow the instructions to complete the form
        </div>
      </div>

      <div className="row og lg_mt-2 mt-2 mb-1">
        <div className="columns og small-12 medium-1 medium-offset-0 text-center align-self-middle">
          <img src={HcpPplPrescribingSubmit} className="mb-2" alt="Submit via Fax" />
        </div>
        <div className="columns og small-3 medium-1 text-center">
          <img src={IconNumberThree} alt="3" />
        </div>
        <div className="columns og small-8 medium-8 align-self-middle">
          Submit all pages of the completed form via fax to <a className="textUnderlined word-wrap fw-black" href="tel:+1-877-805-0130">1-877-805-0130</a> or email <a href="mailto:patientsupport@rhythmtx.com" className="textUnderlined word-wrap fw-black">patientsupport@rhythmtx.com</a>
        </div>
      </div>
    </div>

    <div className="row">
      <div className="columns large-6 medium-7 small-12 mb-2">
        <Callout fullWidth className="light-teal left text-left">
          <p>IMCIVREE is only available through PANTHERx Rare Pharmacy</p>
        </Callout>
      </div>
      <div className="columns large-1"></div>
      <div className="columns large-5 medium-4 small-12">
        <div className="row medium-collapse large-uncollapse">
          <div className="columns og small-3">
            <img src={IcdIcon} style={{paddingRight: '.5rem', width: '124px'}} alt="ICD-10 code" />
          </div>
          <div className="columns og small-9 d-flex align-self-middle">
            <p
              style={{
                fontWeight: "bold",
                color: "#4B4F54",
                margin: "0",
                paddingRight: '1rem',
                lineHeight: "1.2rem",
              }}
            >
              Announcing the ICD-10 code for BBS&mdash;<span className="color-teal fw-bold">Q87.83</span>&mdash;effective as of October 1, 2023
            </p>
          </div>
        </div>
      </div>
    </div>
    <div className="content-block md_pb-1">
      <div>
        <p>
          Financial support may be available to eligible patients for whom
          IMCIVREE treatment is indicated. For questions on IMCIVREE or how to
          start a patient, call <span className="nowrap">Rhythm InTune</span> at <a href="tel:18552060815" className="color-teal fw-bold">1-855-206-0815</a> Monday­–Friday, 8 <span style={{ fontSize: "80%" }}>AM</span> to 8 <span style={{ fontSize: "80%" }}>PM</span> ET.
        </p>
      </div>

      <div className="text-center mb-2 md_mt-2 mt-2">
        <Button
          copy="Learn about a personalized support program for your patients"
          url="/hcp/bbs/patient-support/"
        />
      </div>
      <p className="footnote mt-3 md_mt-3">ICD=International Classification of Diseases.</p>
    </div>
  </Layout>
)

export default PrescribingImcivree

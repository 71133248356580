import * as React from "react"
import Layout from "../../../components/hcp-bbs-layout"
import Seo from "../../../components/seo"
import Callout from "../../../components/callout"
import HangingRef from "../../../components/hanging-ref"
import Button from "../../../components/button"

import ImpactImcivreeVideo from "../../../components/impact-imcivree-video"
import VideoTranscript from "../../../components/video-transcript"
import ImpactImcivreeVideoTranscript from "../../../components/template-partials/hcp/bbs/the-impact-of-imcivree-transcript"

import qual100 from "../../../images/qual-life-100.png"
import qualAll from "../../../images/qual-life-all.png"
   
const Qoa = () => (
  <Layout noNav>
    <Seo
      title="Quality of Life | IMCIVREE® (setmelanotide) injection | For HCPs"
      description="Learn about quality-of-life improvements in patients treated with IMCIVREE® (setmelanotide). Please see full Prescribing Information for Important Safety Information."
      ogTitle="Quality of Life | IMCIVREE® (setmelanotide) injection | For HCPs"
      ogDesc="Learn about quality-of-life improvements in patients treated with IMCIVREE® (setmelanotide). Please see full Prescribing Information for Important Safety Information."
    />
    <div className="content-block" style={{ paddingBottom: '2rem' }}>
      <h2 className="h2--no-margin-bottom lg_mt-1">
        Quality of life in patients treated with IMCIVREE
      </h2>
      <div className="row">
        <div className="columns og large-6 medium-12 small-12 align-self-top qol-column-wrapper">
          <p className="color-teal ignore-resize mt-2 md_mt-2 md_mb-2">
            <span>
              Self-reported HRQoL study with patients in the BBS phase 3
              clinical trial<sup>1</sup>*<sup>&dagger;</sup>
            </span>
          </p>

          <img src={qual100} style={{ marginTop: '10px'}} alt="100%" />

          <div className="row">
            <div className="columns small-12 pr-0 md_pr-2">
              <ul className="neon-green-bullets" style={{ marginTop: '20px' }}>
                <li>
                  Of the pediatric patients who reported a reduced quality of
                  life at baseline (n=4)<sup>1</sup>
                  <ul>
                    <li className="is-dash">
                      100% experienced <strong>clinically meaningful</strong> improvements across multiple HRQoL measures<sup>1</sup>
                    </li>
                  </ul>
                </li>
                <li>
                  Of the adult patients who reported reduced quality of life at
                  baseline (n=8)<sup>1</sup>
                  <ul>
                    <li className="is-dash">
                      62.5% (5 of 8 patients) experienced{" "}
                      <strong>clinically meaningful</strong> improvements<sup>1</sup>
                    </li>
                  </ul>
                </li>
                <li>
                  Of the adult patients who did not report reduced quality of
                  life at baseline (n=3)<sup>1</sup>
                  <ul>
                    <li className="is-dash">
                      100% either maintained or experienced meaningful
                      improvements in their quality of life<sup>1</sup>
                    </li>
                  </ul>
                </li>
              </ul>
              <p className="hanging-ref hangingRef mt-1 md_mt-1" style={{fontSize: `15px !important`, lineHeight: `20px` }}><sup>&dagger;</sup>Limitations include the small sample sizes across some of the assessments, which may in part be due to the rarity of the disease, and the subjective nature of hunger scoring.<sup>1</sup></p> 
            </div>
          </div>
        </div>

        <div className="columns og large-6 medium-12 small-12 align-self-top qol-column-wrapper">
          <p className="color-teal ignore-resize mt-2 md_mt-2">
            <span>
              Qualitative interviews conducted using semi-structured
              interview guides with participants from the phase 2 or phase 3
              trials<sup>2&Dagger;</sup>
            </span>
          </p>

          <img src={qualAll} className="position-relative mb-1 md_mb-1" alt="All 19 participants reported improvement" />

          <div className="row">
            <div className="columns small-12">
              <ul className="neon-green-bullets md_ml-2 mb-1" style={{ marginTop: '12px' }}>
                <li>A large reduction in food consumption<sup>2</sup></li>
                <li>A decrease in abnormal food-seeking behaviors<sup>2</sup></li>
                <li>An increased ability to focus<sup>2</sup></li>
                <li>
                  Improvements in either physical and/or emotional well-being<sup>2</sup>
                </li>
              </ul>
              <div className="md_ml-1">
                <p className="hanging-ref hangingRef" style={{fontSize: `15px !important`, lineHeight: `20px` }}><sup>&Dagger;</sup>Limitations include participants (patients and mothers of patients) who chose to continue treatment, which may reflect bias toward IMCIVREE.<sup>2</sup></p> 
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Callout left className="light-teal left text-left hcp-bbs-qol">
      <p style={{ paddingLeft: '20px'}}>
        These insights highlight the need to address hyperphagia and subsequent
        impaired quality of life for people with BBS and their caregivers<sup>2</sup>
      </p>
    </Callout>

  <div className="content-block">
    <div className="row">
        <div className="columns small-12">
          <HangingRef
            mb0
            symbol="*"
            isAsterisk
            copy={<>Using age-specific PedsQL or IWQOL-Lite assessments.<sup>1</sup></>}
          />
        </div>
      </div>
      <div className="row og">
        <div className="columns og text-center">
          <h3 className="h1 md_mt-2 mt-3 text-left">
          The impact of IMCIVREE
          </h3>
          <p className="h2 text-left">
            Hear from families and clinicians about how IMCIVREE is bringing hope for people living with BBS
          </p>

          <ImpactImcivreeVideo/>
          <VideoTranscript>
            <ImpactImcivreeVideoTranscript />
          </VideoTranscript>

        </div>
      </div>
      <div className="text-center mt-3 md_mt-3">
        <Button
          copy="See safety and tolerability"
          url="/hcp/bbs/safety-profile/"
        />
        <p
          className="text-left small md_mt-2 mt-2"
          style={{ fontWeight: "normal" }}
        >
          HRQoL=health-related quality of life; IWQOL-Lite=Impact of Weight on
          Quality of Life-Lite; PedsQL=Pediatric Quality of Life Inventory.
        </p>
      </div>
      <div className="references">
        <strong>References: 1.</strong> Forsythe E et al. <em>Orphanet J Rare Dis.</em> 2023;18(1):12. doi:10.1186/s13023-022-02602-4. <strong>2.</strong> Ervin C et al. <em>Adv Ther.</em> 2023;40(5):2394-2411. doi:10.1007/s12325-023-02443-y.
      </div>
    </div>
  </Layout>
)

export default Qoa

import * as React from "react"

import Layout from "../../../components/hcp-ppl-layout"
import Seo from "../../../components/seo"

import Hero from "../../../images/hcp/ppl/hcp-ppl-hero-image.png"
import "../../../components/scss-pages/hcp/ppl/_index.scss"
import Button from "../../../components/button"
import Callout from "../../../components/callout"
import Helmet from "react-helmet"

import IconData from "../../../images/hcp/ppl/icon-data.png"
import IconDiamond from "../../../images/hcp/ppl/icon-diamond.png"
import IconRaR from "../../../images/hcp/ppl/icon-email.svg"

const IndexPage = () => (
  <Layout>
    <Seo
      title="POMC, PCSK1, LEPR Deficiency | IMCIVREE® (setmelanotide) injection | For HCPs"
      description="IMCIVREE is the first and only PRECISION MEDICINE to target impairment in the hypothalamic MC4R pathway, a root cause of hyperphagia and obesity in POMC, PCSK1, or LEPR deficiency. Sign up for updates to learn more. Please see full Prescribing Information and Important Safety Information."
      ogTitle="IMCIVREE® (setmelanotide) injection"
      ogDesc="Learn about IMCIVREE® (setmelanotide) injection and the diseases it treats. Sign up for updates to learn more. Please see full Prescribing Information for Important Safety Information."
    />
    <Helmet
      bodyAttributes={{ id: "hcp-ppl-gateway", class: "hcp-ppl-home hcp-ppl" }}
    />
    <div id="hcp-ppl-home">
      <div className="hero row" style={{ backgroundImage: `url(${Hero})` }}>
        <div className="row og hero-eyebrow__container">
          <div className="hero-eyebrow lg_mt-3 mt-1">
            <div className="eyebrow__phrase lg_mt-1">
              Help your patients with POMC,
              <br className="show-for-xlarge" /> PCSK1, or LEPR deficiency
            </div>
          </div>
        </div>
        {/* <div className="row small-11 large-8 xlarge-6 columns og mt-1">
          <strong className="eyebrow">
            Help your patients with POMC,
            <br className="show-for-xlarge" /> PSCK1, or LEPR deficiency
          </strong>
        </div> */}

        <div className="row hero-text mt-3">
          <div className="small-1 large-5 columns og mt-7">
            <h1>
              <span className="green">Discover a World</span>
              <span className="white">Beyond Obesity</span>
            </h1>
          </div>
        </div>

        <div className="row reverse w-100 lg_mb-1 mt-2">
          <div className="small-12 large-12 columns text-right">
            <p
              className="ref mt-0 md_mt-0 mb-1 pr-2 md_pr-2"
              style={{ textShadow: `0px 2px 2px #000, 0px 1px 1px #000` }}
            >
              <strong>Actor portrayals</strong>.
            </p>
          </div>
        </div>

        <Callout left className="lime-transparent left text-left">
          <p>
            <strong>IMCIVREE</strong> is the <b>first and only</b>{" "}
            <strong className="color-neon-green">PRECISION MEDICINE</strong> to
            target impairment in the hypothalamic MC4R pathway, a root cause of
            hyperphagia and obesity in POMC, PCSK1, or LEPR deficiency
            <sup>1,2</sup>
          </p>
          <div className="columns" style={{ maringTop: "8px" }}>
            <Button
              type="global-link"
              color="green"
              copy={
                <>
                  <span className="jost-semibold">
                    Explore how <br className="hide-for-medium" />
                    IMCIVREE works
                  </span>
                </>
              }
              url="/hcp/ppl/moa/"
            />
          </div>
        </Callout>

        <div className="hero-content">
          <div className="row">
            <div className="small-12 large-4 columns hcp-ppl-home-btn-01">
              <Button
                icon={IconData}
                iconAlt="Efficacy Data"
                teal="true"
                copy={
                  <>
                    <span className="jost-semibold">
                      See the efficacy of IMCIVREE
                    </span>
                  </>
                }
                url="/hcp/ppl/study-design/"
              />
            </div>
            <div className="small-12 large-4 columns hcp-ppl-home-btn-02">
              <Button
                icon={IconDiamond}
                iconAlt="Download Icon"
                teal="true"
                type="download"
                copy={
                  <>
                    <span className="jost-semibold">
                      Download a Prescription Start Form to prescribe IMCIVREE
                    </span>
                  </>
                }
                file="/Start_Form.pdf"
              />
            </div>
            <div className="small-12 large-4 columns hcp-ppl-home-btn-03">
              <Button
                icon={IconRaR}
                iconAlt="Contact a Rhythm Representative"
                teal="true"
                copy={
                  <>
                    <span className="jost-semibold">
                      Contact a Rhythm Representative
                    </span>
                  </>
                }
                url="/hcp/ppl/sign-up/"
              />
            </div>
          </div>
          <div className="row">
            <div className="small-12 large-12 columns">
              <p className="ref">
                LEPR=leptin receptor; MC4R=melanocortin-4 receptor;
                PCSK1=proprotein convertase subtilisin/kexin type 1;
                POMC=proopiomelanocortin.
              </p>
              <p className="ref"><strong>References: 1.</strong> IMCIVREE [prescribing
              information]. Boston, MA. Rhythm Pharmaceuticals, Inc.
              <strong> 2.</strong> Eneli I et al. <em>Appl Clin Genet</em>.
              2019;12:87-93.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage

import React from "react"

import Layout from "../../../components/hcp-bbs-layout"
import Seo from "../../../components/seo"
import Accordion from "../../../components/template-partials/global-components/accordion/accordion"

const Faq = () => {
  return (
    <Layout>
      <Seo
        title="Frequently Asked Questions | IMCIVREE® (setmelanotide) injection | For HCPs"
        description="Get answers to frequently asked questions about IMCIVREE® (setmelanotide) Please see full Prescribing Information for Important Safety Information."
        ogTitle="Frequently Asked Questions | IMCIVREE® (setmelanotide) injection"
        ogDesc="Get answers to frequently asked questions about treatment with IMCIVREE® (setmelanotide) Please see full Prescribing Information for Important Safety Information."
      />
      <div id="faq-accordian-container" className="content-block">
        <h1 className="h1 lg_mt-1">Frequently asked questions about IMCIVREE</h1>

        <Accordion className="mb-2 md_mb-3">
          <Accordion.Item isOpen={true}>
            <Accordion.Drawer>Indications and usage</Accordion.Drawer>
            <Accordion.Expanded>
              <div className="columns">
                <h5 className="h5">What is IMCIVREE indicated for?</h5>
                <ul className="neon-green-bullets mb-1 md_mb-1">
                  <li>
                    IMCIVREE is indicated for chronic weight management in adult
                    and pediatric patients 6 years of age and older with
                    monogenic or syndromic obesity due to Bardet-Biedl syndrome
                    (BBS)<sup>1</sup>
                  </li>
                  <li>
                    IMCIVREE is <u>not</u> indicated for the treatment of
                    patients with the following conditions as IMCIVREE would not
                    be expected to be effective:
                    <ul className="sublist">
                      <li>
                        Other types of obesity not related to BBS or other
                        FDA-approved indications for IMCIVREE, including obesity
                        associated with other genetic syndromes and general
                        (polygenic) obesity<sup>1</sup>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </Accordion.Expanded>
          </Accordion.Item>
          <Accordion.Item isOpen={false}>
            <Accordion.Drawer>Appropriate patients</Accordion.Drawer>
            <Accordion.Expanded>
              <div className="columns">
                <h5 className="h5">
                  Do I need to confirm a diagnosis of BBS with genetic testing
                  before treating with IMCIVREE?
                </h5>
                <ul className="neon-green-bullets">
                  <li>Per the Prescribing Information, patients selected for treatment with IMCIVREE should have a clinical diagnosis of BBS but do not require confirmation of a BBS diagnosis through genetic testing</li>
                  <li>
                    BBS is diagnosed clinically but factors like clinical
                    manifestations, genetics, family history, and family
                    findings should all be considered
                  </li>
                  <li>
                    Consider the complete patient presentation when making a
                    diagnosis
                  </li>
                  <li>
                    Genetic testing can provide additional diagnostic
                    information to help inform your diagnosis. For more
                    information visit <a href="//uncoveringrareobesity.com" className="fw-bold" target="_blank">
                      uncoveringrareobesity.com
                    </a>
                  </li>
                  <li>
                    Interpretation of genetic testing results can be limited by
                    the information currently available on the genetics of this
                    disease. Information around the genetics of BBS continues to
                    evolve
                  </li>
                </ul>
              </div>
            </Accordion.Expanded>
          </Accordion.Item>
          <Accordion.Item isOpen={false}>
            <Accordion.Drawer>Dosing and administration</Accordion.Drawer>
            <Accordion.Expanded>
              <div className="columns">
                <h5 className="h5">What is the recommended dose?</h5>
                <p>
                  In adult and pediatric patients 12 years of age and older, the
                  recommended starting dose of IMCIVREE is 2 mg (0.2 mL)
                  injected subcutaneously once daily for 2 weeks, and the
                  recommended target dosage is 3 mg (0.3 mL) injected
                  subcutaneously once daily. Monitor patients for
                  gastrointestinal (GI) adverse reactions.
                </p>
                <p className="mb-0">
                  <strong>If the starting dosage is:</strong>
                </p>
                <ul className="neon-green-bullets mb-1 md_mb-1">
                  <li>
                    Not tolerated, reduce the dosage to 1 mg (0.1 mL) once
                    daily. If the 1-mg once-daily dosage is tolerated for at
                    least 1 week, increase the dosage to 2 mg (0.2 mL) once
                    daily<sup>1</sup>
                  </li>
                  <li>
                    Tolerated for 2 weeks, increase the dosage to 3 mg (0.3 mL)
                    once daily. If the 3-mg once-daily dosage is not tolerated,
                    decrease the dosage to 2 mg (0.2 mL) once daily<sup>1</sup>
                  </li>
                </ul>
                <p>
                  <strong>
                    In pediatric patients aged 6 to less than 12 years,
                  </strong>
                  the recommended starting dose is 1 mg (0.1 mL) injected
                  subcutaneously once daily for 2 weeks, and the recommended
                  target dosage is 3 mg (0.3 mL) injected subcutaneously once
                  daily. Monitor patients for GI adverse reactions.<sup>1</sup>
                </p>
                <p className="mb-0">
                  <strong>If the starting dosage is:</strong>
                </p>
                <ul className="neon-green-bullets">
                  <li>
                    Not tolerated, reduce the dosage to 0.5 mg (0.05 mL) once
                    daily. If the 0.5-mg once-daily dosage is tolerated for at
                    least 1 week, increase the dosage to 1 mg (0.1 mL) once
                    daily<sup>1</sup>
                  </li>
                  <li>
                    Tolerated for 2 weeks, increase the dosage to 2 mg (0.2 mL)
                    once daily. If the 2-mg daily dosage is:
                    <ul className="sublist">
                      <li>
                        Not tolerated, reduce the dosage to 1 mg (0.1 mL) once
                        daily<sup>1</sup>
                      </li>
                      <li>
                        Tolerated, increase the dosage to 3 mg (0.3 mL) once
                        daily<sup>1</sup>
                      </li>
                    </ul>
                  </li>
                </ul>
                <h5 className="h5 mt-1 md_mt-1">
                  What is the recommended dose for patients with renal
                  impairment?
                </h5>
                <ul className="neon-green-bullets">
                  <li>
                    IMCIVREE is not recommended for use in patients with end
                    stage renal disease (estimated glomerular filtration rate
                    [eGFR] less than 15 mL/min/1.73 m<sup>2</sup>) or in
                    pediatric patients 6 to &lt;12 years of age with severe
                    renal impairment<sup>1</sup>
                  </li>
                  <li>
                    For adults and pediatric patients 12 years of age and older
                    with severe renal impairment (eGFR of 15 to 29 mL/min/1.73 m
                    <sup>2</sup>), the recommended starting dosage is 0.5 mg
                    (0.05 mL) injected subcutaneously once daily for 2 weeks,
                    and the recommended target dosage is 1.5 mg (0.15 mL)
                    injected subcutaneously once daily. Monitor patients for GI
                    adverse reactions<sup>1</sup>
                  </li>
                  <li>
                    <strong>
                      If the recommended starting dose is tolerated for 2 weeks
                    </strong>
                    , increase the dosage to 1 mg (0.1 mL) once daily. If the
                    1-mg daily dosage is tolerated for at least 1 week, increase
                    the dosage to 1.5 mg (0.15 mL) once daily<sup>1</sup>
                  </li>
                  <li>
                    <strong>
                      If the recommended starting dose is not tolerated
                    </strong>
                    , discontinue IMCIVREE<sup>1</sup>
                  </li>
                </ul>
                <h5 className="h5 mt-1 md_mt-1">
                  The use of IMCIVREE in pediatric patients 6 to less than 12
                  years of age with severe renal impairment is not recommended.
                  <sup>1</sup>
                </h5>
              </div>
            </Accordion.Expanded>
          </Accordion.Item>
          <Accordion.Item isOpen={false}>
            <Accordion.Drawer>Safety</Accordion.Drawer>
            <Accordion.Expanded>
              <div className="columns">
                <h5 className="h5 mt-1 md_mt-1">
                  Why can IMCIVREE cause hyperpigmentation?
                </h5>
                <ul className="neon-green-bullets">
                  <li>
                    IMCIVREE is an MC4-receptor agonist with 20-fold less
                    activity at the melanocortin-3 (MC3) and melanocortin-1
                    (MC1) receptors. The MC1 receptor is expressed on
                    melanocytes, and activation of this receptor leads to
                    accumulation of melanin and increased skin pigmentation
                    independent of ultraviolet light<sup>1</sup>
                  </li>
                </ul>
                <h5 className="h5 mt-1 md_mt-1">
                  Is skin darkening reversible?
                </h5>
                <ul className="neon-green-bullets">
                  <li>
                    Changes in skin pigmentation or hair color typically
                    presented 2-3 weeks after initiation of IMCIVREE, with most
                    events occurring within the first month of treatment. The
                    effect was reversible upon discontinuation of treatment
                    <sup>1,3</sup>
                  </li>
                </ul>
                <h5 className="h5 mt-1 md_mt-1">
                  Why does my patient need a skin examination before starting
                  IMCIVREE?
                </h5>
                <ul className="neon-green-bullets">
                  <li>
                    Because IMCIVREE can cause hyperpigmentation, a full-body
                    skin examination should be performed prior to initiation and
                    periodically during treatment to monitor pre-existing and
                    new skin pigmentary lesions<sup>1</sup>
                  </li>
                </ul>
                                
                <h5 className="h5 mt-1 md_mt-1">
                  Can IMCIVREE cause skin cancer?
                </h5>
                <ul className="neon-green-bullets">
                  <li>
                    There were no reports of melanoma related to the observed
                    hyperpigmentation in clinical trials of IMCIVREE<sup>3</sup>
                  </li>
                </ul>
                
                <h5 className="h5 mt-1 md_mt-1">
                  Can IMCIVREE cause sexual adverse reactions?
                </h5>
                <ul className="neon-green-bullets">
                  <li>
                    Sexual adverse reactions may occur in patients treated with
                    IMCIVREE. Spontaneous penile erections in males (24%) and
                    sexual adverse reactions in females (7% in IMCIVREE-treated
                    patients and 0% in placebo-treated patients from an
                    unapproved population) occurred in clinical studies with
                    IMCIVREE. Inform patients that these events may occur and
                    instruct patients who have an erection lasting longer than 4
                    hours to seek emergency medical attention<sup>1</sup>
                  </li>
                </ul>
                {/*
                <h5 className="h5">
                  What were the most common adverse reactions/events reported in
                  IMCIVREE in the BBS trial?
                </h5>
                <ul className="neon-green-bullets">
                  <li>
                    The most common adverse reactions in IMCIVREE-treated
                    patients were hyperpigmentation disorders, injection site
                    reactions, and nausea<sup>1</sup>
                  </li>
                  <li>
                    Adverse events (AEs) were generally mild and transient
                    <sup>3</sup>
                  </li>
                  <li>
                    No serious AEs related to IMCIVREE were reported in the BBS
                    trial<sup>3</sup>
                  </li>
                </ul>
                <h5 className="h5 mt-1 md_mt-1">
                  Were any serious treatment-related adverse reactions observed
                  in clinical studies?
                </h5>
                <ul className="neon-green-bullets">
                  <li>
                    No serious AEs related to IMCIVREE were reported in the BBS
                    trial<sup>3</sup>
                  </li>
                  <li>
                    IMCIVREE is not approved for use in neonates or infants.
                    Serious and fatal adverse reactions including “gasping
                    syndrome” can occur in neonates and low birth weight infants
                    treated with benzyl alcohol-preserved drugs<sup>1</sup>
                  </li>
                </ul>

                <h5 className="h5 mt-1 md_mt-1">
                  Did any patients discontinue treatment in the clinical trials
                  due to ISRs?
                </h5>
                <ul className="neon-green-bullets">
                  <li>
                    No patients taking IMCIVREE discontinued treatment in the
                    clinical trials due to ISRs<sup>3</sup>
                  </li>
                </ul>
                <h5 className="h5 mt-1 md_mt-1">
                  Will the nausea and vomiting caused by IMCIVREE go away/lessen
                  over time?
                </h5>
                <ul className="neon-green-bullets">
                  <li>
                    Nausea or vomiting occurred in approximately 26% and 19% of
                    patients, respectively, treated with IMCIVREE in the BBS
                    clinical trial<sup>1</sup>
                  </li>
                  <li>
                    Reported incidences of nausea and vomiting primarily
                    occurred within the first month of treatment, then sharply
                    declined after 4 weeks<sup>3</sup>
                  </li>
                  <li>
                    Reported incidences of nausea and vomiting typically
                    resolved within a few days in patients with a rare genetic
                    disease of obesity in IMCIVREE clinical trials<sup>4</sup>
                  </li>
                  <li>
                    To report SUSPECTED ADVERSE REACTIONS, contact Rhythm
                    Pharmaceuticals at 1-833-789-6337 or FDA at
                    <a
                      style={{ fontWeight: "600", textDecoration: "underline" }}
                      href="tel://1-800-FDA-1088"
                    >
                      1-800-FDA-1088
                    </a>
                    or
                    <a
                      href="https://www.fda.gov/medwatch/"
                      target="_blank"
                      style={{ fontWeight: "600", textDecoration: "underline" }}
                    >
                      www.fda.gov/medwatch
                    </a>
                  </li>
                </ul>
                */}
              </div>
            </Accordion.Expanded>
          </Accordion.Item>
          <Accordion.Item isOpen={false}>
            <Accordion.Drawer>Mechanism of action</Accordion.Drawer>
            <Accordion.Expanded>
              <div className="columns">
                <h5 className="h5">
                  How does IMCIVREE work (mechanism of action)?
                </h5>
                <ul className="neon-green-bullets">
                  <li>IMCIVREE is the first and only precision medicine to target impairment in the hypothalamic MC4R pathway, a root cause of hyperphagia and obesity in BBS<sup>1,5</sup></li>
                  <li>IMCIVREE, an MC4R agonist, acts in place of alpha-melanocyte-stimulating hormone to activate the MC4 receptor to reestablish MC4R pathway activity<sup>1,6,7</sup></li>
                  <li>Activation of the MC4R pathway can help to increase satiety signals and energy expenditure, therefore reducing hunger, and consequently, food intake and weight<sup>1</sup></li>
                </ul>
              </div>
            </Accordion.Expanded>
          </Accordion.Item>
          <Accordion.Item isOpen={false}>
            <Accordion.Drawer>Efficacy</Accordion.Drawer>
            <Accordion.Expanded>
              <div className="columns">
                <h5 className="h5">
                  How was IMCIVREE studied in patients with BBS?
                </h5>
                <p className="mb-0">
                  <strong>Study design</strong>
                </p>
                <ul className="neon-green-bullets">
                  <li>
                    The efficacy and safety of IMCIVREE for the reduction of
                    weight and hunger in patients with BBS were studied in a
                    1-year phase 3 clinical trial with a 14-week randomized,
                    double-blind, placebo-controlled treatment period
                    <sup>1,8</sup>
                  </li>
                  <li>
                    The study enrolled patients aged 6 years and older with
                    obesity and a clinical diagnosis of BBS<sup>1</sup>
                  </li>
                </ul>
                <h5 className="h5 mt-1 md_mt-1">
                  How was the hunger score assessed?
                </h5>
                <ul className="neon-green-bullets">
                  <li>
                    Patients &ge;12 years of age who were able to self-report
                    their hunger (n=14) recorded their daily maximal hunger in a
                    diary, which was then assessed by the Daily Hunger
                    Questionnaire Item 2. Hunger was scored on an 11-point scale
                    from 0 (“not hungry at all”) to 10 (“hungriest possible”)
                    <sup>1</sup>
                  </li>
                </ul>
                <h5 className="h5 mt-1 md_mt-1">
                  What were the efficacy results in the clinical study of
                  IMCIVREE in patients with BBS?
                </h5>
                <p className="mb-0">
                  <strong>Weight reduction:</strong>
                </p>
                <ul className="neon-green-bullets">
                  <li>
                    ~8% mean reduction in BMI in patients after 1 year aged ≥6 to &lt;18<sup>1</sup>
                    <ul className="sublist">
                      <li>
                        Patients were not required to change their diet or
                        exercise routine<sup>3</sup>
                      </li>
                    </ul>
                  </li>
                  <li>
                    Clinically significant -0.8 mean change in BMI Z-score in
                    patients &lt;18 years of age after 1 year<sup>3</sup>
                    <ul className="sublist">
                      <li>
                        100% of patients &lt;12 years of age achieved a
                        clinically significant ≥0.2 reduction in BMI Z-score
                        (n=3)<sup>3,9</sup>
                      </li>
                    </ul>
                  </li>
                  <li>
                    Clinically significant ~10% mean weight reduction in
                    patients ≥18 years of age after 1 year<sup>3</sup>
                  </li>
                </ul>
                <p className="mb-0">
                  <strong>Hunger reduction:</strong>
                </p>
                <ul className="neon-green-bullets">
                  <li>
                    Statistically significant reduction in maximal hunger score
                    <sup>1</sup>
                  </li>
                </ul>
                <h5 className="h5 mt-1 md_mt-1">What is a BMI Z-score?</h5>
                <ul className="neon-green-bullets">
                  <li>
                    A BMI Z-score, also called a BMI standard deviation score,
                    is a measure of relative weight adjusted for the child’s age
                    and sex<sup>10</sup>
                  </li>
                </ul>
              </div>
            </Accordion.Expanded>
          </Accordion.Item>
          <Accordion.Item isOpen={false}>
            <Accordion.Drawer>Accessing IMCIVREE</Accordion.Drawer>
            <Accordion.Expanded>
              <div className="columns">
                <ul className="neon-green-bullets">
                  <li>Rhythm InTune is committed to assisting all eligible patients in obtaining IMCIVREE coverage, regardless of insurance plan type</li>
                  <li>The majority of patients, regardless of coverage type, have obtained approval for IMCIVREE</li>
                  <li>Many patients have obtained coverage approval at the prior authorization level</li>
                  <li>The majority of commercial plans have coverage policies in place for IMCIVREE</li>
                  <li>The majority of Medicaid covered lives have a coverage policy in place for a positive decision on IMCIVREE coverage</li>
                  <li>Financial assistance programs are available for eligible, underinsured, or uninsured patients</li>
                </ul>
                <br/>
                <h5 className="h5">Is IMCIVREE commercially available?</h5>
                <ul className="neon-green-bullets">
                  <li>
                    IMCIVREE is only available through our specialty pharmacy
                    partner, PANTHERx. To prescribe IMCIVREE, a completed Prescription Start
                    Form can be faxed to <a href="tel:18778050130" className="fw-bold">1-877-805-0130</a> or emailed to <a href="mailto:patientsupport@rhythmtx.com" className="fw-bold">patientsupport@rhythmtx.com</a>.<br/> Note: The preferred method of starting IMCIVREE is via our Prescription Start Form. If you would prefer to e-prescribe, <strong>please be sure to select PANTHERx Rare Pharmacy</strong>.
                  </li>
                </ul>
                <h5 className="h5 mt-1 md_mt-1">
                  How do I prescribe IMCIVREE for my patients?
                </h5>
                <ul className="neon-green-bullets">
                  <li>
                    To prescribe IMCIVREE for a patient with BBS, a completed
                    Start Form can be faxed to <a href="tel:18778050130" className="fw-bold">1-877-805-0130</a> or emailed to&nbsp; 
                    <a href="mailto:patientsupport@rhythmtx.com" className="fw-bold">patientsupport@rhythmtx.com</a>
                  </li>
                </ul>
                <h5 className="h5 mt-1 md_mt-1">
                  Is there a copay program for IMCIVREE?
                </h5>
                <ul className="neon-green-bullets">
                  <li>
                    Yes, there are financial assistance programs available for eligible patients. 
                    The patient support program, Rhythm InTune, can answer any
                    questions your patients may have about their insurance
                    coverage for IMCIVREE. They can also facilitate the prior
                    authorization process and register eligible, commercially
                    insured patients with a qualifying diagnosis for a copay
                    support program. Rhythm InTune can be reached at <a href="tel:18778050130" className="fw-bold">1-877-805-0130</a>, Monday&ndash;Friday, 8 <small>AM</small> to 8 <small>PM</small> ET
                  </li>
                </ul>
              </div>
            </Accordion.Expanded>
          </Accordion.Item>
          <Accordion.Item isOpen={false}>
            <Accordion.Drawer>Patient support</Accordion.Drawer>
            <Accordion.Expanded>
              <div className="columns">
                <h5 className="h5">
                  
                  Is there a patient support program for IMCIVREE?
                </h5>
                <ul className="neon-green-bullets">
                  <li>
                    The Rhythm InTune support program is designed for caregivers
                    and people living with BBS
                  </li>
                  <li>
                    A Rhythm InTune Patient Education Manager is a single point
                    of contact who can help patients and caregivers:
                    <ul className="sublist">
                      <li>
                        Access educational resources, such as virtual education
                        programs about BBS or treatment with IMCIVREE
                      </li>
                      <li>
                        Connect to a community where they can learn from the
                        experiences of others
                      </li>
                      <li>
                        Access treatment by helping with understanding drug
                        coverage, prior authorizations, appeals support, and,
                        for eligible patients, copay support and financial
                        assistance
                      </li>
                      <li>
                        Get started on treatment by coordinating IMCIVREE
                        deliveries and injection support with the specialty
                        pharmacy
                      </li>
                    </ul>
                  </li>
                  <li>
                    Enrolling in Rhythm InTune is voluntary and allows your
                    patient access to all the resources and support Rhythm
                    Pharmaceuticals has to offer
                  </li>
                  <li>
                    To enroll a patient in Rhythm InTune, the patient must
                    submit a completed and signed consent form via fax to <a href="tel:+18778050130" className="fw-bold">1-877-805-0130</a> or emailed to <a href="mailto:patientsupport@rhythmtx.com" className="fw-bold">patientsupport@rhythmtx.com</a>
                  </li>
                </ul>
              </div>
            </Accordion.Expanded>
          </Accordion.Item>
        </Accordion>

        <p className="references footnote">
          <b>References:</b> <b>1.</b> IMCIVREE [prescribing information]. Boston, MA. Rhythm Pharmaceuticals, Inc. <b>2.</b> Forsythe E et al. <i>Front Pediatr</i>. 2018. doi:10.3389/fped.2018.00023. <b>3.</b> Data on file. Rhythm Pharmaceuticals, Inc. Boston, MA. <b>4.</b> Argente J et al. The Pediatric Endocrine Society Annual Meeting. Poster 155. April 28-May 1, 2022. <b>5.</b> Eneli I et al. <i>Appl Clin Genet</i>. 2019;12:87-93. <b>6.</b> Trapp CM et al. <i>Curr Opin Endocrinol Diabetes Obes</i>. 2023;30(2):136-140. <b>7.</b> Haws R et al. <i>Diabetes Obes Metab</i>. 2020;22(11):2133-2140. doi:10.1111/dom.14133. <b>8.</b> Haws RM et al. <i>Contemp Clin Trials Commun</i>. 2021;22:100780. <b>9.</b> Grossman DC et al; US Preventive Services Task Force. <i>JAMA</i>. 2017;317(23):2417-2426. <b>10.</b> Vanderwall C et al. <i>BMC Pediatrics</i>. 2018;18(1):187.
        </p>
      </div>
    </Layout>
  )
}

export default Faq

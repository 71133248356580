/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
//import { useStaticQuery, graphql } from "gatsby"

import Header from "./template-partials/hcp/bbs/new-header"
import ScrollToISIDiv from "./scrolltoisi"
import "./layout.scss"

import IsiPatientBbs from "./template-partials/global-components/isi/isi-patient-bbs.js"
import IsiPatientPpl from "./template-partials/global-components/isi/isi-patient-ppl.js"
import IsiHcpBbs from "./template-partials/global-components/isi/isi-hcp-bbs.js"
import IsiHcpPpl from "./template-partials/global-components/isi/isi-hcp-ppl.js"
import GlobalFooter from "./template-partials/global-components/global-footer.js"
import CookieBanner from "./template-partials/global-components/cookie-banner"

import { Waypoint } from 'react-waypoint'
import GreenTab from "../images/green-tab.svg"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";

import HcpBBsSd from "./template-partials/hcp/bbs/hcp-bbs-sd";

const Layout = ({ children, pageId, noNav  }) => {

  const [screenShot, setScreenShot] = useState(false);

  const handleWaypointEnter = () => {
    document.getElementById('isi-docked-container').style.display = 'none';
    if(document.getElementById('cookie-banner')){
      document.getElementById('cookie-banner').classList.add('drop');
    }
    document.querySelector('.main-nav').style.setProperty('--isi-height', '0');
  }
  const handleWaypointLeave = () => {
    document.getElementById('isi-docked-container').style.display = 'block';
    if(document.getElementById('cookie-banner')){
      document.getElementById('cookie-banner').classList.remove('drop');
    }
    document.querySelector('.main-nav').style.setProperty('--isi-height', '200px');
  }
  const openCloseIsi = (e) => {
    let isiDock = document.getElementById('isi-docked-container');
    let bodyTag = document.querySelector('body');

    if (!isiDock.classList.contains('isi-expanded')) {
      isiDock.classList.add('isi-expanded');
      bodyTag.classList.add('isi-body-expanded');
      document.getElementById('isi-button-toggle-copy').innerHTML = 'COLLAPSE ';
      document.getElementById('isi-font-arrow-up').style.display = 'none';
      document.getElementById('isi-font-arrow-down').style.display = 'inline';
      if(document.getElementById('cookie-banner')){
        document.getElementById('cookie-banner').classList.add('drop');
      }
    } else {
      isiDock.classList.remove('isi-expanded');
      bodyTag.classList.remove('isi-body-expanded');
      document.getElementById('isi-button-toggle-copy').innerHTML = 'EXPAND ';
      document.getElementById('isi-font-arrow-up').style.display = 'inline';
      document.getElementById('isi-font-arrow-down').style.display = 'none';
      if(document.getElementById('cookie-banner')){
        document.getElementById('cookie-banner').classList.remove('drop');
      }
    };
  }

  const checkScroll =() =>{
    let scrollTop = window.pageYOffset || (document.documentElement || document.body.parentNode || document.body).scrollTop;
    return scrollTop;
  }

  useEffect(()=>{
    let mh = document.querySelector('.main-header');
    window.onscroll = function() {
      if(checkScroll() > 100){
        mh.classList.add('main-header--scrolled');
      }else{
        if(mh.classList.contains('main-header--scrolled')){
          mh.classList.remove('main-header--scrolled');
        }
      }
    };

    let urlParams = new URLSearchParams(window.location.search);
    let screenshotParam = urlParams.get('screenshot');

    if (screenshotParam && screenshotParam.toLowerCase() === 'true') {
      // Set the variable to true
      setScreenShot(true);
    } else {
      // Set the variable to false
      return false;
    }

  });

  return (
    <>
      <Header noNav={noNav} />
      <ScrollToISIDiv />
      <div className={noNav ? "container container--no-nav" : "container"}>
        <div className="row">
        <div className="columns">

          <main>{children}</main>

          <Waypoint onEnter={handleWaypointEnter} onLeave={handleWaypointLeave}>
            <div id="isiContainer" className="lg_mt-2 mt-2">
              <div className="row">
                <div className="columns og small-12 color-teal lg_mb-2 mb-2"><strong className="h3">Important Safety Information</strong></div>
                <div className="columns og small-12">
                  <IsiHcpBbs/>
                </div>
              </div>
            </div>
          </Waypoint>
        </div>
        </div>

        <div id="isi-docked-container" className={(screenShot ? `sshide` : ``)}>
          {!screenShot &&
            <>
              <div id="isi-expand-collapse-bar" className="color-gray-bg">
                <div className="row row align-middle og">
                  <div className="columns og color-white"><strong>Important Safety Information</strong></div>
                  <div className="columns og shrink text-right"><button id="isi-button-toggle" onClick={openCloseIsi} style={{ fontWeight: 'bold' }}><span id="isi-button-toggle-copy" className="color-white">EXPAND </span><span className="color-neon-green"><span id="isi-font-arrow-up"><FontAwesomeIcon icon={faAngleUp}/></span><span id="isi-font-arrow-down"><FontAwesomeIcon icon={faAngleDown}/></span></span></button></div>
                </div>
              </div>
              <div className="row og lg_mt-1 mt-1">
                <div className="columns og">
                 <IsiHcpBbs/>
                </div>
              </div>
            </>
          }
        </div>

      </div>
      <GlobalFooter type="hcp-bbs" code={"US-SET-2200171 03/2024"}/>
      {!screenShot &&
        <CookieBanner />
      }
      <HcpBBsSd/>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
